import { Packagetype } from '../Models/jena/request/ListPackageTypesResponse';

export default function getParcelDefaultDimensions(packageType: Packagetype) {
  return {
    weight: packageType.defaultweight,
    width: packageType.defaultwidth,
    length: packageType.defaultlength,
    height: packageType.defaultheight,
  };
}
