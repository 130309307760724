import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import { Suggestion, Suggestions } from 'use-places-autocomplete';

export const AddressAutocompleteEvents = {
  onInput: (input: string) => createDataEvent('INPUT', input),
  setValue: (value: string) => createDataEvent('SET_VALUE', value),
  // use-places-autocomplete suggestions are mapped to an unexported type, ignore :(
  // @ts-ignore
  receivedSuggestions: (suggestions: Suggestions) =>
    createDataEvent('SUGGESTIONS', suggestions),
  onSelect: (suggestion?: Suggestion) => createDataEvent('SELECT', suggestion),
  moveUp: () => createEvent('MOVE_UP'),
  moveDown: () => createEvent('MOVE_DOWN'),
  ready: () => createEvent('READY'),
  close: () => createEvent('CLOSE'),
  clear: () => createEvent('CLEAR'),
};

export type TAddressAutocompleteEvents = EventActionType<
  typeof AddressAutocompleteEvents
>;
