import { useMachine } from '@xstate/react';
// import { inspect } from '@xstate/inspect';
import sendPackageMachine, {
  defaultContext,
  SendPackageContext,
} from '../Machines/sendPackageMachine';
import { addMinutes, format } from 'date-fns/fp';
import { InsuranceInfo } from '../Pages/PackageInformation/packageInformationMachine';
import { DeliveryEvents } from '../Pages/Delivery/DeliveryEvents';
import useJenaApi from './useJenaApi';
import { useUser } from './useUser';
import { useEffect } from 'react';
import { SendPackageEvents } from '../events/SendPackageEvents';
import { useCountries } from './useCountries';
import useNetsApi from './useNetsApi';
import DeliveryOption from '../Models/DeliveryOption';
import { PartnerInformation } from '../Models/PartnerInformation';
import {
  contextToSaveCustoms,
  contextToSaveUploadFiles,
} from '../mappers/jenaMappers';

// if (process.env.REACT_APP_INSPECT! && false) {
//   inspect({
//     url: 'https://statecharts.io/inspect',
//     iframe: false,
//   });
// }

export const useSendPackageMachine = () => {
  const api = useJenaApi();
  const netsApi = useNetsApi();
  const { countries } = useCountries();
  const { user, isLoggedIn, packageTypes } = useUser();
  const defaultDate = addMinutes(15)(/*clock?.now() ||*/ new Date());
  // const translations = useTranslation();

  const machine = useMachine(sendPackageMachine, {
    context: {
      ...defaultContext,
      userLoggedIn: isLoggedIn,
      userId: user?.id,
      paymentType: isLoggedIn ? 'invoice' : 'card',
      date: format('yyyy-MM-dd')(defaultDate),
      time: format('HH:mm')(defaultDate),
    },
    services: {
      getShop: api.getShop,
      getInsurance: async ({
        partnerInfo,
        deliveryOption,
      }: {
        partnerInfo: PartnerInformation;
        deliveryOption?: DeliveryOption;
      }) =>
        api
          .getInsurance({
            partnercompid: partnerInfo.partnerCompId,
            consignmentid: deliveryOption?.ConsignmentId,
          })
          .then(
            (response) =>
              ({
                minPrice: parseFloat(response.mininsuranceprice),
                //minValue: parseFloat(response.mininsuranceprice),
                maxValue: parseFloat(response.maxinsurancevalue),
                rate: parseFloat(response.insurance_rate),
                currency: response.insurancecurrencyname,
                vatable: response.vatable.toString() === '1',
                vatRate: parseFloat(response.preliminaryvatrate || '0') + 1,
              } as InsuranceInfo)
          ),
      createAwb: async (ctx: SendPackageContext) => api.createAwb(ctx),
      getCityInfo: async (ctx: SendPackageContext) => {
        const countryid = countries.find(
          (c) => c.isocode === ctx.billingCountry
        )?.countryid;
        if (!countryid) return [];
        return api.getCityInfo(Number(countryid), ctx.billingPostalCode);
      },
      createOrder: async (ctx: SendPackageContext) =>
        api.createOrder(ctx, user),
      loadAlternatives: (ctx: SendPackageContext) =>
        api.getTransportAlternatives(ctx, user),
      getConsignmentInfo: (ctx: SendPackageContext) =>
        api
          .getConsignmentInfo(ctx.deliveryOption?.ConsignmentId || '')
          .then((response) =>
            response.consignmentinfo.find((i) => typeof i !== 'string')
          ),
      getToken: netsApi.getToken,
      startEmbedded: (ctx: SendPackageContext) =>
        netsApi.startEmbedded(ctx.netsToken!, {
          consignmentid: ctx.deliveryOption?.ConsignmentId!,
          consumer: {
            email: ctx.billingEmail,
            phoneNbr: ctx.billingPhone,
          },
          orderItem: {
            priceExlVat: ctx.price?.priceexvat?.toString() || '', // ctx.deliveryOption?.PriceExcludingVAT.toString() || '',
            priceIncVat: ctx.price?.priceincvat?.toString() || '', // ctx.deliveryOption?.PriceIncludingVAT.toString() || '',
          },
          ref: ctx.paymentReference!,
          url: window.location.href,
        }),
      generateSupportTicket: (ctx: SendPackageContext) => {
        if (!ctx.shipper) throw new Error('Missing shipper');
        if (!ctx.consignee) throw new Error('Missing consignee');
        return api.generateSupportTicket({
          shp_country: ctx.shipper.countryId,
          shp_city: ctx.shipper.city,
          shp_companyname: ctx.shipper.addressName || '',
          shp_firstname: ctx.shipper.firstName || '',
          shp_lastname: ctx.shipper.lastName || '',
          shp_phone: ctx.shipper.phone || '',
          shp_infotocourier: '',
          shp_street: ctx.shipper.street || '',
          shp_postcode: ctx.shipper.postCode || '',
          shp_streetNbr: ctx.shipper.streetNumber,
          cne_country: ctx.consignee.countryId,
          cne_city: ctx.consignee.city,
          cne_companyname: ctx.consignee.addressName || '',
          cne_firstname: ctx.consignee.firstName || '',
          cne_lastname: ctx.consignee.lastName || '',
          cne_phone: ctx.consignee.phone || '',
          cne_infotocourier: '',
          cne_street: ctx.consignee.street || '',
          cne_postcode: ctx.consignee.postCode || '',
          cne_streetNbr: ctx.consignee.streetNumber || '',
          timeType: '',
          pickupdate: ctx.date ?? ctx.dateStart ?? '',
          putime: ctx.time ?? ctx.timeStartClose ?? '',
          pkgs: ctx.packages.length.toString(),
          weight: ctx.packages
            .reduce((prev, cur) => (prev += cur.weight || 0), 0)
            .toString(),
          supportticketid: '', // ????
          customernbr: user?.customerNumber || '',
          requestid: ctx.requestId || '',
        });
      },
      verifyCampaignCode: (ctx: SendPackageContext) => {
        if (!ctx.campaignCode) throw new Error('Missing campaign code');
        return api.verifyCampaignCode(ctx, user);
      },
      saveCustoms: async (ctx: SendPackageContext) =>
        api.saveCustoms(contextToSaveCustoms(ctx, user, countries)),
      listPackageTypes: () => api.listPackageTypes(),
      saveUploadFile: async (ctx: SendPackageContext) => {
        const reqs = await contextToSaveUploadFiles(ctx);
        const rets = await Promise.all(reqs.map((x) => api.saveUploadFile(x)));
        return rets
          .flat()
          .slice(-reqs.length)
          .map((x) => ({
            ...x,
            data: reqs.find((r) => r.filename === x.orgfilename)?.filecontent,
          }));
      },
      deleteUploadFiles: async (ctx: SendPackageContext, e, a) =>
        api.deleteFiles({
          listfiles: [ctx.removeFileId!],
          requestid: ctx.requestId!,
        }),
    },

    actions: {
      reset: () => window.location.reload(),
      addressVerified: (ctx: SendPackageContext) =>
        api
          .getCustomerTransportInfo(ctx.shipper, ctx.consignee)
          .then((response) => {
            machine[1](DeliveryEvents.setPartnerInfo(response));
          }),
      saveAcceptance: () => {
        if (user) {
          localStorage.setItem(`acceptPrivacyPolicy_${user?.id!}`, 'true');
          localStorage.setItem(`acceptedTerms_${user?.id!}`, 'true');
          localStorage.setItem(`acceptedAirlineTerms_${user?.id!}`, 'true');
        }
      },
      showToast: () => {
        // ctx, event, meta
        // const keys = meta.action.meta.translationKeys;
        // const type = meta.action.meta.type;
        // const toastId = meta.action.meta.toastId;
        // const message = [...keys].reduce((trans, key) => {
        //   if (typeof trans === 'string') return trans;
        //   if (key in trans) return trans[key];
        //   return trans;
        // }, translations);
        // Hide toasts for now
        // TODO: Remove this entire feature if we're not going to use it!
        // if (toastId) {
        //   toast.isActive(toastId)
        //     ? toast.update(toastId, { type, render: message })
        //     : toast(message, { toastId, type: type || 'info' });
        // } else {
        //   toast(message, { toastId, type: type || 'info' });
        // }
      },
      scrollToTop: () => {
        window.scrollTo(0, 0);
      },
    },
    devTools: true,
  });
  // keep login status in sync so that we may use it within guards
  // Might want the entire user?
  useEffect(() => {
    machine[1](
      SendPackageEvents.setUserLoggedIn({
        isLoggedIn,
        email: user?.email,
        phone: user?.phone,
        id: user?.id,
      })
    );
    if (user?.packageTypes && user.packageTypes.length)
      machine[1](SendPackageEvents.setPackageTypes(user.packageTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (packageTypes && packageTypes.length > 0)
      machine[1](SendPackageEvents.setPackageTypes(packageTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageTypes]);

  return machine;
};
