import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { ListCountry } from '../Models/jena/response/GetCountriesResponse';

interface GroupedCountries {
  [key: string]: ListCountry[];
}

const groupBy = (arr: Array<ListCountry>, key: string): GroupedCountries => {
  const initialValue = {};
  if (!arr) return initialValue;
  return arr.reduce((acc: any, cval: any) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

export const useCountries = () => {
  const { countries } = useContext(UserContext);
  const groupedCountries = groupBy(countries, 'region');
  return { countries, groupedCountries };
};
