import IAddress from '../Models/Address';

const format = (val: string, prefix?: string, suffix?: string) =>
  val ? `${prefix || ''}${val}${suffix || ''}` : '';

export const formatAddress = (address?: IAddress) =>
  address?.formattedAddress ??
  (address?.country
    ? `${format(address?.street)}${format(address?.streetNumber, ' ')}, ${
        address.postCode ? format(address?.postCode, ' ') : ''
      } ${format(address?.city, '', ' ')}${format(address?.country)}`
    : undefined);
