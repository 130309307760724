/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Logo } from './../svg';
import SwitchLanguage from './SwitchLanguage';
import { LoginLogout } from './LoginLogout';
import ContainerXl from './Base/ContainerXl';

const Header = () => {
  return (
    <div tw="bg-white">
      <ContainerXl>
        <header tw={'pt-8 pb-6'}>
          <div css={tw`flex flex-row items-center px-2`}>
            <Logo tw={'h-6 md:h-10 xl:h-12'} />

            <div tw="ml-auto">
              <ul tw="flex space-x-3">
                <LoginLogout />
                <li>
                  <SwitchLanguage />
                </li>
              </ul>
            </div>
          </div>
        </header>
      </ContainerXl>
    </div>
  );
};

export default Header;
