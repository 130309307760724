/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import ChangePassword from '../../Components/ResetPassword/ChangePassword';

const ChangePasswordPage = () => {
  return (
    <Fragment>
      <ChangePassword />
    </Fragment>
  );
};

export default ChangePasswordPage;
