const fileToDataUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) resolve(reader.result as string);
      else reject();
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const fileToBase64 = (file: File) => fileToDataUrl(file);

export default fileToBase64;
