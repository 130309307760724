// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from 'react';
/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
interface ContainerProps {
  children: ReactNode;
}

// This component styles matches jetpak.com´s container-xl class
const containerStyles = [
  tw`w-full px-4 mx-auto`,
  css`
    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1400px) {
      max-width: 1280px;
    }
  `,
];
const rowStyles = [tw`flex flex-wrap -mx-4`];
const colStyles = [tw`relative w-full pr-4 pl-4`];

const ContainerXl = ({ children }: ContainerProps) => (
  <div css={containerStyles}>
    <div css={rowStyles}>
      <div css={colStyles}>{children}</div>
    </div>
  </div>
);

export default ContainerXl;
