/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { StateValue } from 'xstate';
import { IconUpload } from '../svg';
import Loader, { LoaderLabel } from './Base/Loader';
import { useState } from 'react';
import { SaveUploadFile } from '../Models/jena/response/SaveUploadFileResponse';
import useTranslation from '../hooks/useTranslation';
import { boxShadow } from './Base/BoxShadow';
import ConfirmDeleteButton from './Base/ConfirmDeleteButton';

interface Props {
  matches: (key: StateValue) => boolean;
  onFileChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (id: string) => void;
  files?: SaveUploadFile[];
  error?: string;
  title: string;
}

const UploadSection = ({
  matches,
  onFileChanged,
  onRemoveFile,
  files,
  error,
  title,
}: Props) => {
  const [uploadFocus, setUploadFocus] = useState(false);
  const translation = useTranslation();
  return (
    <section css={[tw`row max-w-xl my-8`]}>
      <div css={[tw`col-12`]}>
        <h3 css={tw`font-heading font-semibold text-xl mb-2 leading-tight`}>
          {title}
        </h3>
        <label
          css={[
            tw`relative flex cursor-pointer flex-col justify-center items-center w-full h-32 border-gray-300 border-2 rounded-lg`,
            uploadFocus ? [tw`border-blue-500 bg-white`, boxShadow] : '',
          ]}
        >
          <IconUpload css={tw`w-8 h-8 stroke-current`} />
          <span>{translation.upload.upload}</span>
          <input
            type="file"
            multiple
            accept=".docx,.doc,.pdf,.odf,.jpg,.jpeg"
            css={tw`opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer`}
            onChange={onFileChanged}
            max="2147483648"
            onDragEnter={(e) => setUploadFocus(true)}
            onDragLeave={(e) => setUploadFocus(false)}
            onDrop={(e) => setUploadFocus(false)}
            onDragEnd={(e) => setUploadFocus(false)}
            disabled={matches('uploading')}
          />
          {matches('uploading') && (
            <Loader variant="uploading">
              <LoaderLabel label={translation.upload.loading} />
            </Loader>
          )}
        </label>

        {files && files.length > 0 && (
          <ul tw="space-y-1 my-2">
            {files.map((x) => (
              <li
                key={`uf-${x.webconsignmentfilesid}`}
                tw="bg-white rounded border border-gray-200 hover:bg-gray-100 p-2 px-3 flex justify-between items-center"
              >
                {x.data ? (
                  <a
                    href={x.data}
                    target="_blank"
                    rel="noreferrer"
                    download={x.orgfilename}
                  >
                    {x.orgfilename}
                  </a>
                ) : (
                  x.orgfilename
                )}
                <ConfirmDeleteButton
                  onDelete={() => onRemoveFile(x.webconsignmentfilesid)}
                  label=""
                  confirmLabel={translation.remove}
                />
              </li>
            ))}
          </ul>
        )}
        {matches('error') && <p tw={'text-red'}>Error: {error}</p>}
      </div>
    </section>
  );
};
export default UploadSection;
