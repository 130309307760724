/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import OrderConfirmationSummary from './Components/OrderConfirmationSummary';
import { SendPackageContext } from '../../Machines/sendPackageMachine';
import useTranslation from '../../hooks/useTranslation';
import { Button } from '../../Components/Base';
import { useUser } from '../../hooks/useUser';
import OrderConfirmationAnalytics from './Components/OrderConfirmationAnalytics';
import { StateValue } from 'xstate';
import { TCustomsEvents } from '../Customs/CustomsEvents';

interface OrderConfirmationPageProps {
  context: SendPackageContext;
  reset: () => void;
  send: (event: TCustomsEvents) => void;
  matches: (key: StateValue) => boolean;
}
export const OrderConfirmationPage = ({
  context,
  reset,
  send,
  matches,
}: OrderConfirmationPageProps) => {
  const translations = useTranslation();
  const { user } = useUser();

  return (
    <div tw={'container mx-auto px-4 pt-10 pb-10'}>
      <OrderConfirmationSummary state={context} />

      <div id="order-confirmation-email" tw={'hidden'}>
        {user?.email || context.billingEmail}
      </div>

      {context.orderConfirmation?.co2 && (
        <div tw="row">
          <div tw="col-12">
            <p tw="mt-6">
              {translations.carbonDioxideMessage}:{' '}
              <span tw="font-bold">
                {context.orderConfirmation?.co2} kg CO<sub>2</sub>
              </span>
            </p>
          </div>
        </div>
      )}

      {!!context.orderConfirmation && !!process.env.REACT_APP_GTM_ID && (
        <OrderConfirmationAnalytics state={context} />
      )}
      <div tw="row mt-10">
        <div tw="col-12">
          <Button onClick={reset}>{translations.createNewBooking}</Button>
        </div>
      </div>
    </div>
  );
};
