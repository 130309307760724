import { formatWithOptions, format } from 'date-fns/fp';
import { sv, enGB, da, nb, fi } from 'date-fns/locale';
import { addDays } from 'date-fns/fp';
import { formatRelative } from 'date-fns';
import { TLanguageCode } from './../translations/TranslationType';

export interface IDate {
  now: () => Date;
}
interface Locales {
  today: string;
  tomorrow: string;
  locale: TLanguageCode;
}

const assertMissingLocale = (locale: never): never => {
  throw new Error(`Locale import is missing: ${locale}`);
};

export const getLocale = (locale: TLanguageCode): Locale => {
  switch (locale) {
    case 'en-GB':
      return enGB;
    case 'sv-SE':
      return sv;
    case 'da-DK':
      return da;
    case 'nb-NO':
      return nb;
    case 'fi-FI':
      return fi;
    default:
      throw assertMissingLocale(locale);
  }
};

export const formatRelativeDate = (
  language: TLanguageCode,
  date: Date,
  currentDate: Date = new Date()
) =>
  formatRelative(date, currentDate, {
    locale: getLocale(language),
  });

const date: IDate = { now: () => new Date() };

export const getDateSelectOptions = (
  { today, tomorrow, locale }: Locales,
  dategen: IDate = date
) => {
  const now = dategen.now();

  const formatDay = formatWithOptions({ locale: getLocale(locale) }, 'EEEE PP');
  const days = [2, 3, 4, 5, 6, 7, 8].map((d) => ({
    label: formatDay(addDays(d)(now)),
    value: format('yyyy-MM-dd')(addDays(d)(now)),
  }));
  return [
    { label: today, value: format('yyyy-MM-dd')(now) },
    { label: tomorrow, value: format('yyyy-MM-dd')(addDays(1)(now)) },
    ...days,
  ];
};
