import IAddress from '../../../../Models/Address';
import {
  createDataEvent,
  EventActionType,
} from '../../../../helpers/eventCreator';
import { DoneInvokeEvent } from 'xstate';
import { VerifyAddressResponse } from '../../../../Models/jena/response/VerifyAddressResponse';

export const VerifyAddressesEvents = {
  selectShipper: (address?: IAddress) =>
    createDataEvent('SELECT_SHIPPER', address),

  selectConsignee: (address?: IAddress) =>
    createDataEvent('SELECT_CONSIGNEE', address),
};
export type TVerifyAddressesEvents =
  | EventActionType<typeof VerifyAddressesEvents>
  | DoneInvokeEvent<VerifyAddressResponse>;
