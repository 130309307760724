/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { FormInput } from '../FormFields';
import { Button, FormGroup } from '../Base';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import FormMessage from '../Base/FormMessage';
import changePasswordMachine, {
  ChangePasswordGuards,
} from '../../Pages/Login/ChangePasswordMachine';
import { useUser } from '../../hooks/useUser';

const ChangePassword = () => {
  const api = useJenaApi();
  const { user } = useUser();
  const [state, send] = useMachine(changePasswordMachine, {
    services: {
      changePasswordRequest: (context: any, event: any) => {
        return api.changePwd({
          pwd: context.password,
          oldpwd: context.oldPassword,
          userid: user?.id,
        });
      },
    },
  });
  const translation: Translations = useTranslation();

  if (state.matches('successful')) {
    return <div>{translation.passwordChanged}</div>;
  }

  return (
    <form>
      <h4 css={tw`mb-4`}>{translation.changePassword}</h4>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.currentPassword}
          value={state.context.oldPassword}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_OLD_PASSWORD', data: val });
          }}
          autoComplete="current-password"
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.newPassword}
          value={state.context.password}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_PASSWORD', data: val });
          }}
          autoComplete="new-password"
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.newPassword2}
          value={state.context.password2}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_PASSWORD2', data: val });
          }}
          autoComplete="new-password"
        />
      </FormGroup>

      {state.matches('failed') && (
        <FormGroup>
          <FormMessage status="error" label={translation.signInErrorMessage} />
        </FormGroup>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          send({ type: 'SET_PASSWORD' });
        }}
        variant="secondary"
        disabled={
          state.matches('loading') ||
          // @ts-ignore
          !ChangePasswordGuards.validSetPassword(state.context)
        }
        options={tw`ml-auto`}
      >
        {(state.matches('editing') || state.matches('failed')) &&
          translation.save}
        {state.matches('loading') && translation.loading}
        {state.matches('successful') && translation.signedIn}
      </Button>
    </form>
  );
};

export default ChangePassword;
