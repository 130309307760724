/** @jsxImportSource @emotion/react */
// import tw from 'twin.macro';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../../hooks/useJenaApi';
import useTranslation from '../../../hooks/useTranslation';
import { getUserCountryId } from '../../../mappers/jenaMappers';
import RegisterMachine, { IRegisterContext } from '../RegisterMachine';
import GetRegistration from './GetRegistration';
import RegisterForm from './RegisterForm';
// import { ModalSmall } from '../../../Components/Modal/Modal';
// import { RegisterEvents } from '../RegisterEvents';
// import { useBoundEvents } from '../../../hooks/useBoundEvents';

const Register = () => {
  const api = useJenaApi();
  const translation = useTranslation();
  const [state, send] = useMachine(RegisterMachine, {
    services: {
      getRegistration: async (context: IRegisterContext, _: any) => {
        if (!context.partnerInfo) throw new Error('Missing partnerInfo!');
        const ret = await (context.existing
          ? api.getRegistration(
              context.partnerInfo?.partnerCompId,
              undefined,
              context.customerNumber
            )
          : api.getRegistration(
              context.partnerInfo?.partnerCompId,
              context.orgNumber
            ));
        return ret;
      },
      register: (context: IRegisterContext) => {
        return api.saveRegistration(context);
      },
      getCustomerTransportInfo: () =>
        api.getCustomerTransportInfoForCountry(getUserCountryId()),
    },
  });
  // const events = useBoundEvents(RegisterEvents, send);

  return (
    <div>
      {state.matches('step1') && (
        <GetRegistration
          state={state.context}
          matches={state.matches}
          send={send}
        />
      )}
      {state.matches('step2') && (
        <RegisterForm
          state={state.context}
          matches={state.matches}
          send={send}
        />
      )}
      {state.matches('registered') && (
        <div>
          <b>{translation.username}: </b>
          <span>{state.context.newAccount?.userName}</span>
          <br />
          <b>{translation.password}: </b>
          <span>{state.context.newAccount?.password}</span>
          <br />
          <b>{translation.email}: </b>
          <span>{state.context.newAccount?.email}</span>
          <br />
          <b>{translation.registerPage.fields.customerNumber}: </b>
          <span>{state.context.newAccount?.customerNumber}</span>
          <br />
        </div>
      )}
      {/* <ModalSmall
        isOpen={state.matches('step1.failed') || state.matches('step2.failed')}
        onRequestClose={events.errorOk}
        shouldCloseOnOverlayClick={true}
        handleClose={events.errorOk}
      >
        <Fragment>
          <h2
            css={tw`block font-heading font-semibold text-3xl mb-4 leading-tight`}
          >
            {'Fel inträffade.'}
          </h2>
          <p>{state.context.error}</p>
        </Fragment>
      </ModalSmall> */}
    </div>
  );
};

export default Register;
