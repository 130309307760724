import { RefObject, useEffect } from 'react';

// Perhaps use https://github.com/wellyshen/react-cool-onclickoutside ?
const useClickOutsideHandler = (
  ref: RefObject<HTMLElement | undefined>,
  handler: () => void
) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement))
      handler();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
};

export default useClickOutsideHandler;
