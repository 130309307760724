import { assign, ConditionPredicate, createMachine, actions } from 'xstate';
import IAddress from '../../Models/Address';
import { TAddressbookEvents } from '../../Pages/Delivery/Components/AddressbookEvents';
const { send, cancel } = actions;

interface AddressbookContext {
  addresses: IAddress[];
  initialAddresses: IAddress[];
  query: string;
  error?: string;
}

type TAddressbookGuards<G extends string> = Record<
  G,
  ConditionPredicate<AddressbookContext, TAddressbookEvents>
>;

export type TAddressbookGuard = 'invalidQuery';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const AddressbookGuards: TAddressbookGuards<TAddressbookGuard> = {
  invalidQuery: (ctx) => !minLength(ctx.query),
};

const inputStates = (invalidGuard?: TAddressbookGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const AddressbookMachine = createMachine<
  AddressbookContext,
  TAddressbookEvents
>({
  id: 'Addressbook',
  initial: 'editing',
  context: {
    query: '',
    addresses: [],
    initialAddresses: [],
  },
  states: {
    editing: {
      type: 'parallel',
      states: {
        query: inputStates(),
      },
      on: {
        CHANGE_QUERY: [
          {
            actions: [
              assign({
                query: (_, event): string => event.data,
              }),
              cancel('debounced-fetch-timer'),
              send(
                { type: 'FETCH' },
                { delay: 800, id: 'debounced-fetch-timer' }
              ),
            ],
          },
        ],
        FETCH: {
          target: 'loading',
        },
        REMOVE_ADDRESS: {
          actions: assign({
            addresses: (ctx, event) =>
              ctx.addresses.filter((x) => x.id !== event.data.id),
            initialAddresses: (ctx, event) =>
              ctx.initialAddresses.filter((x) => x.id !== event.data.id),
          }),
        },
      },
    },
    loading: {
      invoke: {
        id: 'addressbookRequest',
        src: 'addressbookRequest',
        onDone: {
          target: 'editing',
          actions: assign({
            addresses: (_, event) => event.data,
          }),
        },
        onError: {
          target: 'editing',
          actions: assign({
            error: (_, event) => event.data.error,
          }),
        },
      },
    },
    failed: {},
  },
});

export default AddressbookMachine;
