/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Fragment } from 'react';
import FormMessage from '../Base/FormMessage';

type TextareaProps = {
  name: string;
  value: string | undefined;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (val: string) => void;
};

const FormTextarea = ({
  name,
  value,
  placeholder,
  error,
  errorMessage,
  onChange,
  required,
  disabled,
}: TextareaProps) => {
  return (
    <Fragment>
      <textarea
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        css={[
          tw`border border-gray-500 rounded w-full h-16 p-1`,
          error && tw`border-red`,
          disabled && tw`bg-gray-300 cursor-not-allowed`,
        ]}
      />
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </Fragment>
  );
};

export default FormTextarea;
