import { IPrice } from '../Models/IPrice';
import { InsuranceInfo } from '../Pages/PackageInformation/packageInformationMachine';

export const getInsurancePrice = (
  insuranceValue?: number,
  insuranceInfo?: InsuranceInfo
): IPrice => {
  if (!insuranceInfo || !insuranceValue)
    return { priceexvat: 0, priceincvat: 0 };

  const { minPrice, maxValue, rate, vatRate, vatable } = insuranceInfo;
  const priceexvat =
    insuranceValue === 0
      ? 0
      : insuranceValue > maxValue
      ? Math.round((maxValue * rate) / 100)
      : Math.max(minPrice, Math.round((insuranceValue * rate) / 100));

  const priceincvat =
    priceexvat > 0 && vatable && vatRate > 0
      ? priceexvat * vatRate
      : priceexvat;
  return { priceexvat, priceincvat };
};
