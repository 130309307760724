import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';

export const CustomsEvents = {
  changeCustomsValue: (val: number) =>
    createDataEvent('CHANGE_CUSTOMS_VALUE', val),

  changeCustomsCurrency: (currency: string) =>
    createDataEvent('CHANGE_CUSTOMS_CURRENCY', currency),

  changeCustomsOrigin: (country: string) =>
    createDataEvent('CHANGE_CUSTOMS_ORIGIN', country),

  changeCustomsInvoiceNumber: (invoiceNumber: string) =>
    createDataEvent('CHANGE_CUSTOMS_INVOICE', invoiceNumber),

  changeCustomsCommodityCode: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY', code),
  changeCustomsCommodityCode2: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY2', code),
  changeCustomsCommodityCode3: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY3', code),
  changeCustomsCommodityCode4: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY4', code),
  changeCustomsCommodityCode5: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY5', code),
  changeCustomsCommodityCode6: (code: string) =>
    createDataEvent('CHANGE_CUSTOMS_COMMODITY6', code),

  changeCustomsShipperEori: (val: string) =>
    createDataEvent('CHANGE_CUSTOMS_SHIPPER_EORI', val),

  changeCustomsConsigneeEori: (val: string) =>
    createDataEvent('CHANGE_CUSTOMS_CONSIGNEE_EORI', val),

  doneCustomsPage: () => createEvent('CUSTOMS_DONE'),

  address: () => createEvent('ADDRESS'),

  changeUploadFiles: (files: FileList | null) =>
    createDataEvent('CHANGE_CUSTOMS_FILES', files),

  removeFile: (id: string) => createDataEvent('REMOVE_CUSTOMS_FILE', id),
};

export type TCustomsEvents = EventActionType<typeof CustomsEvents>;
