/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { StateValue } from 'xstate';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import { useCountries } from '../../hooks/useCountries';
import useTranslation from '../../hooks/useTranslation';
import { FormGroup, Button } from '../Base';
import { FormInput, FormSelect, PhoneField } from '../FormFields';
import { SaveAddressEvents, TSaveAddressEvents } from './SaveAddressEvents';
import { ISaveAddressContext, SaveAddressGuards } from './SaveAddressMachine';

interface AddAddressProps {
  state: ISaveAddressContext;
  send: (event: TSaveAddressEvents) => void;
  matches: (key: StateValue) => boolean;
}
export const AddAddressForm = ({ state, send, matches }: AddAddressProps) => {
  const { countries } = useCountries();
  const translation = useTranslation();
  const events = useBoundEvents(SaveAddressEvents, send);

  const [phoneCountry, setPhoneCountry] = useState<undefined | string>(
    countries
      .find((c) => c.countryid === state.countryId)
      ?.isocode.toLocaleLowerCase()
  );
  useEffect(() => {
    if (state.phone && state.phone.length < 5) {
      setPhoneCountry(
        countries
          .find((x) => x.countryid === state.countryId)
          ?.isocode?.toLocaleLowerCase()
      );
    }
  }, [state.countryId, state.phone, countries]);

  return (
    <form css={[tw``]}>
      <FormGroup>
        <div tw={'row'}>
          <div css={[tw`col-12 md:col-6`]}>
            <FormInput
              name="addressName"
              label={`${translation.addressBookModal.addressName}/${translation.company}`}
              value={state.addressName}
              type="Text"
              onChange={events.changeAddressName}
              autoComplete="off"
              error={matches('step1.editing.addressName.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div css={[tw`col-12 md:col-6`]}>
            <FormInput
              name="firstName"
              label={translation.registerPage.fields.firstName}
              value={state.firstName}
              type="Text"
              onChange={events.changeFirstName}
              autoComplete="off"
              error={matches('step1.editing.firstName.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
          <div css={[tw`col-12 md:col-6`]}>
            <FormInput
              name="lastName"
              label={translation.registerPage.fields.lastName}
              value={state.lastName}
              type="Text"
              onChange={events.changeLastName}
              autoComplete="off"
              error={matches('step1.editing.lastName.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div css={[tw`col-7 md:col-6`]}>
            <FormInput
              name="street"
              label={translation.registerPage.fields.street}
              value={state.street}
              type="Text"
              onChange={events.changeStreet}
              autoComplete="street"
              error={matches('step1.editing.street.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
          <div css={[tw`col-5 md:col-6`]}>
            <FormInput
              name="streetNumber"
              label={translation.registerPage.fields.streetNumber}
              value={state.streetNumber}
              type="Text"
              onChange={events.changeStreetNumber}
              autoComplete="streetNumber"
              error={matches('step1.editing.streetNumber.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div tw={'col-12 md:col-6'}>
            <FormInput
              name="postCode"
              label={translation.registerPage.fields.postalCode}
              value={state.postCode}
              type="Text"
              onChange={events.changePostalCode}
              autoComplete="postalCode"
              error={matches('step1.editing.postCode.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
          <div tw={'col-12 md:col-6'}>
            <FormInput
              name="city"
              label={translation.registerPage.fields.city}
              value={state.city}
              type="Text"
              onChange={events.changeCity}
              autoComplete="city"
              error={matches('step1.editing.city.error')}
              errorMessage={translation.requiredField}
              required={true}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div tw={'col-12 md:col-6'}>
            <FormSelect
              label={translation.country}
              value={state.countryId}
              name="country"
              onChange={events.changeCountry}
              error={matches('step1.editing.country.error')}
              errorMessage={translation.requiredField}
              options={countries.map((c) => ({
                value: c.countryid,
                label: c.name,
              }))}
            />
          </div>
          <div tw={'col-12 md:col-6'}>
            <PhoneField
              label={translation.registerPage.fields.phone}
              onlyCountries={countries.map((x) =>
                x.isocode.toLocaleLowerCase()
              )}
              value={state.phone}
              name="phone"
              country={phoneCountry}
              error={matches('step2.editing.phone.error')}
              required={true}
              errorMessage={translation.requiredField}
              onChange={events.changePhone}
              // onChange={(val, countryData) => {
              //   if ('countryCode' in countryData) {
              //     const country = countries.find(
              //       (x) =>
              //         x.isocode.toLocaleLowerCase() ===
              //         countryData.countryCode.toLocaleLowerCase()
              //     );
              //     if (country && state.countryId === '')
              //       events.changeCountry(country.countryid);
              //   }
              //   events.changePhone(val);
              // }}
            />
          </div>
        </div>
        {/* {state.error && <p tw={'text-red my-3'}>{state.error}</p>} */}
      </FormGroup>
      <div css={[tw`flex justify-end`]}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            events.continue();
          }}
          variant="secondary"
          // @ts-ignore
          disabled={!SaveAddressGuards.validAddress(state)}
        >
          {translation.save}
        </Button>
      </div>
    </form>
  );
};
