/** @jsxImportSource @emotion/react */
import { ReactNode, Fragment } from 'react';
import React from 'react';
import tw, { css } from 'twin.macro';
import { IconAddressBook } from '../../../../svg';
import { ModalLarge } from '../../../../Components/Modal/Modal';
import { AddressBook } from '../../../../Components/AddressAutocomplete/AddressBook';
import { Address } from '../../../../Models/Address';

const ButtonOpenAddressBook = ({
  onClick,
  children,
  ...rest
}: {
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      css={[
        css`
          position: absolute;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2rem;
          top: 0;
          left: 0;
          margin-top: 2.25rem;
        `,
        tw`border-r`,
      ]}
      {...rest}
    >
      <IconAddressBook tw="text-darkred fill-current w-4 h-4" />
    </button>
  );
};

interface OpenAddressBookProps {
  label: string;
  onRemove?: (address: Address) => void;
  onAdd?: (address: Address) => void;
  onSelect: (address: Address) => void;
  addresses?: Address[];
  numAddress?: number;
}
export const OpenAddressBook = ({
  label,
  onRemove,
  onAdd,
  onSelect,
  addresses,
  numAddress,
}: OpenAddressBookProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <ButtonOpenAddressBook onClick={() => setOpen(true)} aria-label={label}>
        <IconAddressBook tw="text-current w-4 h-4" />
      </ButtonOpenAddressBook>

      <ModalLarge
        isOpen={isOpen}
        onRequestClose={handleClose}
        handleClose={handleClose}
      >
        <AddressBook
          id={'shipper-addresses'}
          savedAddresses={addresses}
          onRemove={onRemove}
          onSelect={(address) => {
            onSelect(address);
            handleClose();
          }}
          onAdd={onAdd}
          numAddress={numAddress}
        />
      </ModalLarge>
    </Fragment>
  );
};
