import { useEffect } from 'react';

const useFocusHandler = (handler: () => void) => {
  const handleOnFocus = (event: FocusEvent) => {
    handler();
  };

  useEffect(() => {
    window.addEventListener('focus', handleOnFocus);
    return () => window.removeEventListener('focus', handleOnFocus);
  });
};

export default useFocusHandler;
