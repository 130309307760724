export enum PaymentMethodId {
  Other = 1, // ????
  Invoice = 2,
  Card = 3,
}

export type BookDefRequest = BookDefBase &
  BookDefUser &
  BookDefShipper &
  BookDefConsignee &
  BookDefConsignment &
  BookDefAdvise &
  (BookDefCard | BookDefInvoice) &
  ({} | BookDefCustoms);

export interface BookDefBase {
  newwebbooking: 1; // number?
  csm_consignmenttype: 2; // number?

  shi_save_addr: 0 | 1;
  con_save_addr: 0 | 1;
  sendnewsletter: 0;

  partnercompid: 0 | string;
  csm_awbnbr: string;
  pac_contents: string;
  originalprice: '' | string;

  pdfreceiptemail: string;
  csm_paymentreference: string;
  /**
   * value if use insurance = true
   */
  csm_insurancevalue?: number;
  //csm_paymentmethodid: PaymentMethodId; // number? 2: invoice, 3: credit card, 1????!!
}

export interface BookDefUser {
  /**
   * user id
   */
  jui_u: string;
  /**
   * user id
   */
  userid: string;

  /**
   * users partnercompid
   */
  jui_pb: string;

  csm_paymentaccountnbr: string;
  csm_customernbr: string;
  csm_custorgid: string;
  csm_custpersonid: string;
  csm_orderpersonid: string;
}

export interface BookDefConsignment {
  pathfinderresult_pathfinderresultid: string;
  pathfinderresultid: string;
  csm_consignmentid: string;
  is_courier: 1 | 0; // wat? from gettrpalt in current?
  bookdefinitive?: 1 | 0; // set to one when not courier?
}

export interface BookDefShipper {
  csm_pickupcontactfname: string;
  csm_pickupcontactsname: string;
  csm_pickupphone1: string;
  csm_fromcityid: string;
  csm_fromcountryid: string;

  shi_city: string;
  shi_name: string;
  shi_streetaddress: string;
  shi_countryid: string;
  shi_streetnbr: string;
  shi_postcode: string;
  shi_addressid?: string;
  csm_pickupmisc?: string;
}

export interface BookDefConsignee {
  csm_deliverycontactfname: string;
  csm_deliverycontactsname: string;
  csm_deliveryphone1: string;
  csm_tocityid: string;
  csm_tocountryid: string;

  con_city: string;
  con_name: string;
  con_streetaddress: string;
  con_countryid: string;
  con_streetnbr: string;
  con_postcode: string;
  con_addressid?: string;
  csm_deliverymisc?: string;
}

export interface BookDefCustoms {
  // customs, still not documented
  isexport: 1;
  csm_customsvalue: number;
  csm_customsinvoicenbr: string;
  csm_customscommoditycode: string;
  csm_customscontents: string;
  csm_customsvaluecurrency: string; // id?
  csm_customscountryoforiginid: string; // country id?
  csm_eorinbrshipper: string;
  csm_eorinbrconsignee: string;
  csm_hscode1: string;
  csm_hscode2: string;
  csm_hscode3: string;
  csm_hscode4: string;
  csm_hscode5: string;
  csm_hscode6: string;
}

export interface BookDefCard {
  csm_paymentmethodid: PaymentMethodId.Card;
  // cardholderstreet: string;
  // cardholderstreetnbr: string;
  // cardholderpostcode: string;
  // cardholdercity: string;
  // cardholdername: string;

  // // not from documentation
  // card_check_url: string;
  // creditcard_url: string;
  referenceno: string;
  paymentid?: string;
}

export interface BookDefInvoice {
  csm_paymentmethodid: PaymentMethodId.Invoice;
  invoiceaddressid: string;
}

export enum AdviseMethod {
  Email = '1',
  Sms = '4',
}

export enum AdviseType {
  BookingConfirmation = '0',
  DeliveryConfirmation = '1',
}
export interface BookDefAdvise {
  AdviseMethod_0: AdviseMethod;
  AdviseType_0: AdviseType;
  Advise_Adr_0: string; // phone or email
}
