/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import React from 'react';
import tw from 'twin.macro';
import HelpPage from '../../Help/HelpPage';
import { ModalLarge } from '../../../Components/Modal/Modal';
import { Button } from '../../../Components/Base';

const ButtonOpenHelp = ({
  label,
  onClick,
  ...rest
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <Button onClick={onClick} css={[tw`float-right w-auto`]} {...rest}>
      {label}
    </Button>
  );
};

interface OpenHelpProps {
  label: string;
  error?: boolean;
  loading: boolean;
  supportTicketId?: string;
  hasAlternatives: boolean;
  onCreateTicket: () => void;
}
export const OpenHelp = ({
  label,
  error,
  loading,
  hasAlternatives,
  supportTicketId,
  onCreateTicket,
}: OpenHelpProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <ButtonOpenHelp
        label={label}
        onClick={() => setOpen(true)}
        aria-label={label}
      />
      <ModalLarge
        isOpen={isOpen}
        onRequestClose={handleClose}
        handleClose={handleClose}
      >
        <HelpPage
          hasAlternatives={hasAlternatives}
          error={error}
          loading={loading}
          supportTicketId={supportTicketId}
          onCreateTicket={onCreateTicket}
        />
      </ModalLarge>
    </Fragment>
  );
};
