/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { FormInput } from '../FormFields';
import { Button, FormGroup } from '../Base';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import FormMessage from '../Base/FormMessage';
import setPasswordMachine, {
  SetPasswordGuards,
} from '../../Pages/Login/SetPasswordMachine';

interface IProps {
  token: string;
}

const SetNewPassword = ({ token }: IProps) => {
  const api = useJenaApi();
  const [state, send] = useMachine(setPasswordMachine, {
    services: {
      setPasswordRequest: (context: any, event: any) => {
        return api.changePwd({ token, pwd: context.password });
      },
    },
  });
  const translation: Translations = useTranslation();

  if (state.matches('successful')) {
    return <div>Your password has been updated.</div>;
  }

  return (
    <form>
      <h4 css={tw`mb-4`}>{translation.resetPassword}</h4>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.newPassword}
          value={state.context.password}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_PASSWORD', data: val });
          }}
          autoComplete="new-password"
        />
      </FormGroup>
      <FormGroup>
        <FormInput
          name="password"
          label={translation.newPassword2}
          value={state.context.password2}
          type="password"
          onChange={(val) => {
            send({ type: 'CHANGE_PASSWORD2', data: val });
          }}
          autoComplete="new-password"
        />
      </FormGroup>

      {state.matches('failed') && (
        <FormGroup>
          <FormMessage status="error" label={translation.signInErrorMessage} />
        </FormGroup>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          send({ type: 'SET_PASSWORD' });
        }}
        variant="secondary"
        disabled={
          state.matches('loading') ||
          // @ts-ignore
          !SetPasswordGuards.validSetPassword(state.context)
        }
        options={tw`ml-auto`}
      >
        {(state.matches('editing') || state.matches('failed')) &&
          translation.save}
        {state.matches('loading') && translation.loading}
        {state.matches('successful') && translation.signedIn}
      </Button>
    </form>
  );
};

export default SetNewPassword;
