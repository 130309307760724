/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import { IconAddressBook } from '../../../../svg';
import useToggle from '../../../../hooks/useToggle';
import { ModalLarge } from '../../../../Components/Modal/Modal';
import { Address } from '../../../../Models/Address';
import useTranslation from '../../../../hooks/useTranslation';
import { SaveAddress } from '../../../../Components/SaveAddress/SaveAddress';
import tw from 'twin.macro';
import { Button } from '../../../../Components/Base';

interface OpenAddAddressProps {
  address?: Address;
  onAdd?: (address: Address) => void;
}
export const OpenAddAddress = ({ onAdd, address }: OpenAddAddressProps) => {
  const [isOpen, toggleOpen] = useToggle();
  const translations = useTranslation();
  return (
    <Fragment>
      <Button variant="secondary" onClick={toggleOpen} size="base">
        <IconAddressBook css={tw`text-current w-4 h-4 mr-2`} />{' '}
        {translations.addressBookModal.newAddress}
      </Button>

      <ModalLarge
        isOpen={isOpen}
        onRequestClose={toggleOpen}
        handleClose={toggleOpen}
      >
        <SaveAddress onAdd={onAdd} address={address} />
      </ModalLarge>
    </Fragment>
  );
};
