import { DoneInvokeEvent } from 'xstate';
import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';

export const OrderHistoryEvents = {
  showBooking: (awbNbr: string) => createDataEvent('SHOW_BOOKING', awbNbr),
  list: () => createEvent('LIST'),
  changeQuery: (val: string) => createDataEvent('CHANGE_QUERY', val),
  setPage: (val: number) => createDataEvent('CHANGE_PAGE', val),
  setBookingDate: (val: string) => createDataEvent('CHANGE_BOOKINGDATE', val),
  resetQuery: () => createEvent('RESET_QUERY'),
};
export type TOrderHistoryEvents =
  | EventActionType<typeof OrderHistoryEvents>
  | DoneInvokeEvent<string>;
