/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { IconClose } from '../../svg';

interface ButtonClearProps {
  label: string;
  handleClick: () => void;
}

const ButtonClear = ({ label, handleClick }: ButtonClearProps) => {
  return (
    <button
      onClick={handleClick}
      aria-label={label}
      css={[tw`absolute right-0 h-full px-2`]}
    >
      <IconClose
        css={[
          tw`w-full`,
          css`
            width: 1.125rem;
          `,
        ]}
      />
    </button>
  );
};

export default ButtonClear;
