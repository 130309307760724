/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Currency, CurrencyId } from '../../enums/Currency';
import { customsGuards } from './CustomsMachine';
import { CustomsEvents, TCustomsEvents } from './CustomsEvents';

import { TextLink } from '../../Components/Base';
import { FormInput, FormSelect } from '../../Components/FormFields';

import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import StepNavigation, {
  StepNavigationNext,
  StepNavigationRow,
  StepNavigationPrevious,
} from '../../Components/Base/StepNavigation';
import { StateValue } from 'xstate';
import { useCountries } from '../../hooks/useCountries';
import { Fragment } from 'react';
import { SendPackageContext } from '../../Machines/sendPackageMachine';
import UploadSection from '../../Components/UploadSection';

interface CustomsPageProps {
  state: SendPackageContext;
  send: (event: TCustomsEvents) => void;
  matches: (key: StateValue) => boolean;
}

const CustomsPage = ({ state, send, matches }: CustomsPageProps) => {
  const translation: Translations = useTranslation();
  const events = useBoundEvents(CustomsEvents, send);
  const { countries } = useCountries();

  const onFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) =>
    events.changeUploadFiles(event.target.files);
  const onRemoveFile = (id: string) => events.removeFile(id);

  return (
    <Fragment>
      <div css={tw`container mx-auto pt-10 pb-16 px-4`}>
        <div css={tw`row max-w-xl`}>
          <div css={tw`col-12`}>
            <h2
              css={tw`font-heading font-semibold text-4xl mb-4 leading-tight`}
            >
              {translation.customsPage.title}
            </h2>

            <p tw={'mb-2'}>{translation.customsPage.preamble}</p>
            <div tw="mb-6">
              <TextLink
                href={translation.customsPage.eori.linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {translation.customsPage.eori.linkText}
              </TextLink>
            </div>
          </div>
        </div>
        <div tw={'row max-w-xl'}>
          <div tw={'col-12'}>
            <FormSelect
              name="customsCountryOfOrigin"
              label={translation.countryOfOrigin}
              value={state.customs.countryOrigin || ''}
              error={matches('editing.origin.error')}
              errorMessage={translation.requiredField}
              options={countries.map((c) => ({
                label: c.name,
                value: c.isocode,
              }))}
              onChange={events.changeCustomsOrigin}
            />
          </div>
        </div>

        <div css={tw`row max-w-xl mt-8`}>
          <div css={tw`col-12`}>
            <h3 css={tw`font-heading font-semibold text-xl mb-2 leading-tight`}>
              {translation.customsPage.customsValue}
            </h3>
            <p tw={'mb-6'}>
              {translation.customsPage.customsValueDescription}{' '}
              <a
                href={translation.customsPage.customsValueLinkUrl}
                tw={'border-b border-gray-700'}
                target="_blank"
                rel="noreferrer"
              >
                {translation.customsPage.customsValueLinkText}
              </a>{' '}
            </p>
          </div>
        </div>
        <div css={tw`row max-w-xl`}>
          <div tw={'col-8'}>
            <FormInput
              name="customsValue"
              label={translation.customsPage.customsValue}
              value={state.customs.value}
              error={matches('editing.value.error')}
              errorMessage={translation.requiredField}
              type="number"
              onChange={(val) => {
                events.changeCustomsValue(parseFloat(val));
              }}
              min={1}
              required={true}
            />
          </div>
          <div tw={'col-4'}>
            <FormSelect
              name="customsCurrency"
              label={translation.currency}
              value={state.customs.currencyId ?? CurrencyId.AUD.toString()}
              error={matches('editing.currency.error')}
              errorMessage={translation.requiredField}
              options={Object.keys(Currency).map((currency) => ({
                label: currency,
                // @ts-ignore
                value: CurrencyId[currency],
              }))}
              onChange={events.changeCustomsCurrency}
            />
          </div>
        </div>

        <div css={tw`row max-w-xl my-8`}>
          <div css={tw`col-12`}>
            <h3 css={tw`font-heading font-semibold text-xl mb-2 leading-tight`}>
              {translation.customsPage.customsInvoiceNumber}
            </h3>
            <p tw={'mb-4'}>
              {translation.customsPage.customsInvoiceNumberDescription}
            </p>
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsInvoice"
              label={translation.customsPage.customsInvoiceNumber}
              value={state.customs.invoiceNumber}
              error={matches('editing.invoice.error')}
              errorMessage={translation.requiredField}
              onChange={events.changeCustomsInvoiceNumber}
              required={true}
            />
          </div>
        </div>

        <div css={tw`row max-w-xl my-8`}>
          <div css={tw`col-12`}>
            <h3 css={tw`font-heading font-semibold text-xl mb-2 leading-tight`}>
              {translation.customsPage.customsNumber}
            </h3>
            <p tw={'mb-6'}>
              {translation.customsPage.customsNumberDescription1}
            </p>
            <p tw={'mb-3'}>
              {translation.customsPage.customsNumberDescription2}
            </p>
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode"
              label={translation.customsPage.customsNumber}
              value={state.customs.commodityCode}
              error={matches('editing.commodity.error')}
              errorMessage={translation.requiredField}
              onChange={events.changeCustomsCommodityCode}
              required={true}
            />
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode2"
              label={`${translation.customsPage.customsNumber} 2`}
              value={state.customs.commodityCode2}
              error={matches('editing.commodity2.error')}
              errorMessage={translation.customsPage.commodityCodeLength}
              onChange={events.changeCustomsCommodityCode2}
            />
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode3"
              label={`${translation.customsPage.customsNumber} 3`}
              value={state.customs.commodityCode3}
              error={matches('editing.commodity3.error')}
              errorMessage={translation.customsPage.commodityCodeLength}
              onChange={events.changeCustomsCommodityCode3}
            />
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode4"
              label={`${translation.customsPage.customsNumber} 4`}
              value={state.customs.commodityCode4}
              error={matches('editing.commodity4.error')}
              errorMessage={translation.customsPage.commodityCodeLength}
              onChange={events.changeCustomsCommodityCode4}
            />
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode5"
              label={`${translation.customsPage.customsNumber} 5`}
              value={state.customs.commodityCode5}
              error={matches('editing.commodity5.error')}
              errorMessage={translation.customsPage.commodityCodeLength}
              onChange={events.changeCustomsCommodityCode5}
            />
          </div>
          <div tw={'col-12'}>
            <FormInput
              name="customsCommodityCode6"
              label={`${translation.customsPage.customsNumber} 6`}
              value={state.customs.commodityCode6}
              error={matches('editing.commodity6.error')}
              errorMessage={translation.customsPage.commodityCodeLength}
              onChange={events.changeCustomsCommodityCode6}
            />
          </div>
        </div>

        <div css={tw`row max-w-xl my-8`}>
          <div css={tw`col-12`}>
            <h3 css={tw`font-heading font-semibold text-xl mb-2 leading-tight`}>
              {translation.customsPage.eori.title}
            </h3>
          </div>
          <div tw={'col-6'}>
            <FormInput
              name="customsShipperEori"
              label={translation.customsPage.shipperEori}
              value={state.customs.shipperEori}
              error={matches('editing.shipperEori.error')}
              errorMessage={translation.requiredField}
              onChange={events.changeCustomsShipperEori}
            />
          </div>
          <div tw={'col-6'}>
            <FormInput
              name="customsConsigneeEori"
              label={translation.customsPage.consigneeEori}
              value={state.customs.consigneeEori}
              error={matches('editing.consigneeEori.error')}
              errorMessage={translation.requiredField}
              onChange={events.changeCustomsConsigneeEori}
            />
          </div>
        </div>
        <UploadSection
          files={state.uploadedFiles}
          matches={matches}
          onFileChanged={onFileChanged}
          onRemoveFile={onRemoveFile}
          error={state.customs.error}
          title={translation.customsPage.customsDocuments}
        />
      </div>

      <StepNavigation>
        <StepNavigationRow>
          <StepNavigationPrevious
            label={translation.customsPage.previousStep}
            handleClick={events.address}
          />
          <span tw="ml-auto"></span>
          <StepNavigationNext
            label={translation.customsPage.nextStep}
            disabled={
              // @ts-ignore
              !customsGuards.validCustoms(state)
            }
            handleClick={events.doneCustomsPage}
          />
        </StepNavigationRow>
      </StepNavigation>
    </Fragment>
  );
};

export default CustomsPage;
