import { TSendPackageEvents } from '../events/SendPackageEvents';
import deliveryMachine, {
  DeliveryContext,
  deliveryMachineGuards,
} from '../Pages/Delivery/deliveryMachine';
import { assign, createMachine, MachineConfig } from 'xstate';
import {
  SenderAndConsigneeContext,
  senderAndConsigneeGuards,
  senderAndConsigneeMachine,
  SenderConsigneeGuards,
} from '../Pages/SenderAndConsignee/senderAndConsigneeMachine';
import packageInformationMachine, {
  PackageGuards,
  PackageInformationContext,
  packageInformationGuards,
} from '../Pages/PackageInformation/packageInformationMachine';
import CustomsMachine, {
  CustomsContext,
  customsGuards,
} from '../Pages/Customs/CustomsMachine';
import { TPackageInformationEvents } from '../Pages/PackageInformation/PackageInformationEvents';
import { TSenderAndConsigneeEvents } from '../Pages/SenderAndConsignee/SenderAndConsigneeEvents';
import { TCustomsEvents } from '../Pages/Customs/CustomsEvents';
import { Currency } from '../enums/Currency';
import { Pages } from '../Components/TabNavigation';
import { Address } from '../Models/Address';
import {
  PaymentGuards,
  PaymentPageContext,
  paymentPageMachine,
} from '../Pages/Payment/paymentPageMachine';
import { TPaymentEvents } from '../Pages/Payment/PaymentEvents';
import { OrderConfirmationMachine } from '../Pages/OrderConfirmation/OrderConfirmationMachine';
import { TDeliveryEvents } from '../Pages/Delivery/DeliveryEvents';
import { ConditionPredicate } from 'xstate/lib/types';
import { Shop } from '../Models/Shop';
import { defaultAdvises } from '../Models/Advise';
import { Parcel, makeDefaultParcel } from '../Models/Parcel';
import { Packagetype } from '../Models/jena/request/ListPackageTypesResponse';
import { SaveUploadFile } from '../Models/jena/response/SaveUploadFileResponse';
import {
  pickupaddressToAddress,
  deliveryaddressToAddress,
} from '../mappers/jenaMappers';
import { cancel, send } from 'xstate/lib/actions';

export type Context = {
  customs: CustomsContext;
  shop: Shop;
  userId?: string;
  userLoggedIn: boolean;
  packageTypes: Packagetype[];
  defaultParcel?: Parcel;
  files?: FileList | null;
  uploadedFiles: SaveUploadFile[];
  removeFileId?: string;
};

export type SendPackageContext = Context &
  PackageInformationContext &
  SenderAndConsigneeContext &
  PaymentPageContext &
  DeliveryContext & { pages: Record<Pages, boolean> };

export type TEvents =
  | TDeliveryEvents
  | TSendPackageEvents
  | TPackageInformationEvents
  | TSenderAndConsigneeEvents
  | TCustomsEvents
  | TPaymentEvents;

export type TGuards<G extends string> = Record<
  G,
  ConditionPredicate<SendPackageContext, TEvents>
>;

export const defaultContext: SendPackageContext = {
  userLoggedIn: false,
  billingCity: '',
  billingCountry: '',
  billingEmail: '',
  billingPhone: '',
  billingName: '',
  billingPostalCode: '',
  billingStreet: '',
  pages: {
    [Pages.address]: false,
    [Pages.delivery]: false,
    [Pages.customs]: false,
    [Pages.package]: false,
    [Pages.payment]: false,
  },
  acceptedAirlineTerms: false,
  acceptedTerms: false,
  acceptPrivacyPolicy: false,
  addressVerified: false,
  alternatives: [],
  awbNumber: '',
  completed: false,
  consignee: {} as Address,
  customs: {
    countryOrigin: '',
    invoiceNumber: '',
    value: '',
    commodityCode: '',
    commodityCode2: '',
    commodityCode3: '',
    commodityCode4: '',
    commodityCode5: '',
    commodityCode6: '',
    consigneeEori: '',
    shipperEori: '',
  },
  customsRequired: false,
  date: '',
  useInsurance: false,
  packages: [],
  packageTypes: [],
  partnerInfo: {
    partnerCompId: '',
    cardCheckUrl: '',
    timeZone: '',
    webSupportNumber: '',
    useInsurance: false,
    currency: Currency.SEK,
    currencyId: '2',
    reportUrl: '',
  },
  invoiceAllowed: false,
  requestType: 'EarliestPickup',
  shipper: {} as Address,
  time: '',
  paymentType: 'card',
  hasNewAlternatives: false,
  shop: {
    partnerCompId: '',
    shop: '',
    method: 'cc.test',
  },
  advise: defaultAdvises,
  uploadedFiles: [],
};

const sendPackageMachine = createMachine<SendPackageContext, TEvents>(
  {
    id: 'sendPackage',
    initial: 'init',
    context: defaultContext,
    states: {
      init: {
        // entry: 'listPackageTypes',
        // TODO: remove
        // invoke: {
        //   id: 'getShop',
        //   src: 'getShop',
        //   onDone: {
        //     target: 'delivery',
        //     actions: assign({
        //       shop: (_, event) => event.data,
        //     }),
        //   },
        //   onError: 'error',
        // },
        always: 'packageTypes',
      },
      packageTypes: {
        invoke: {
          id: 'listPackageTypes',
          src: 'listPackageTypes',
          onDone: {
            target: '#delivery',
            actions: [
              assign({
                packageTypes: (_, { data }) => data,
                defaultParcel: (_, { data }) => makeDefaultParcel(data[0]),
                packages: (_, { data }) => [makeDefaultParcel(data[0])],
              }),
            ],
          },
          onError: {
            target: '.error',
          },
        },
        initial: 'loading',
        states: {
          loading: {},
          error: {},
        },
      },
      refreshDelivery: {
        entry: [
          cancel('debounced-timer'),
          send({ type: 'REFRESH' }, { delay: 100, id: 'debounced-timer' }),
        ],
        on: {
          REFRESH: { target: 'delivery' },
        },
      },
      delivery: {
        ...(deliveryMachine as MachineConfig<SendPackageContext, any, TEvents>),
      },
      package: {
        // eslint-disable-next-line
        ...(packageInformationMachine as MachineConfig<
          SendPackageContext,
          any,
          TEvents
        >),
      },
      address: {
        // eslint-disable-next-line
        ...(senderAndConsigneeMachine as MachineConfig<
          SendPackageContext,
          any,
          TEvents
        >),
      },
      customs: {
        // eslint-disable-next-line
        ...(CustomsMachine as MachineConfig<SendPackageContext, any, TEvents>),
      },
      error: {},
      payment: {
        // eslint-disable-next-line
        ...(paymentPageMachine as MachineConfig<
          SendPackageContext,
          any,
          TEvents
        >),
      },
      orderConfirmation: {
        ...OrderConfirmationMachine,
      },
      success: {
        type: 'final',
      },
    },
    on: {
      DELIVERY: {
        target: 'delivery',
      },
      PACKAGE: [
        {
          cond: deliveryMachineGuards.deliveryStepComplete,
          target: 'package',
        },
      ],
      ADDRESS: {
        cond: PackageGuards.validPackageInformationPage,
        target: 'address',
      },
      CUSTOMS: {
        cond: SenderConsigneeGuards.validSenderAndConsignee,
        target: 'customs',
      },
      PAYMENT: {
        cond: 'validCustomsAndSender',
        target: 'payment',
      },
      SET_USER_LOGGEDIN: {
        actions: [
          assign({
            userLoggedIn: (_, { data }) => data.isLoggedIn,
            userId: (_, { data }) => data?.id,
            // EJ does not want this.. https://trello.com/c/EZq05wN9/111-inloggad-anv%C3%A4ndare-blir-fel-f%C3%B6rifylld-mailadress
            // billingEmail: (_, { data }) => data.email || '',
            // billingPhone: (_, { data }) => data.phone || '',
            // advise: (_, { data }) =>
            //   data.isLoggedIn
            //     ? [{ ...defaultAdvises[0], value: data.email }]
            //     : defaultAdvises,
          }),
        ],
      },
      SET_PACKAGE_TYPES: {
        actions: assign({
          packageTypes: (_, { data }) => data,
          defaultParcel: (_, { data }) => makeDefaultParcel(data[0]),
          // packages: (_, { data }) => [makeDefaultParcel(data[0])],
        }),
      },
      RESET_FORM: {
        actions: 'reset',
        target: 'init',
      },
      COPY_BOOKING: {
        actions: [
          assign((ctx, e) => ({
            shipper: pickupaddressToAddress(e.data),
            consignee: deliveryaddressToAddress(e.data),
          })),
        ],
        target: 'refreshDelivery',
      },
    },
  },
  {
    guards: {
      ...deliveryMachineGuards,
      ...packageInformationGuards,
      ...senderAndConsigneeGuards,
      ...customsGuards,
      ...PaymentGuards,
      validCustomsAndSender: (context: SendPackageContext, e: any, meta: any) =>
        !context.customsRequired &&
        !!context.deliveryOption &&
        senderAndConsigneeGuards.validSenderAndConsignee(context, e, meta),
    },
  }
);

export default sendPackageMachine;
