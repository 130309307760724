/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Button } from '../../Components/Base';
import useTranslation from '../../hooks/useTranslation';

interface HelpPageProps {
  error?: boolean;
  loading: boolean;
  supportTicketId?: string;
  hasAlternatives: boolean;
  onCreateTicket: () => void;
}

const HelpPage = ({
  error,
  loading,
  supportTicketId,
  hasAlternatives,
  onCreateTicket,
}: HelpPageProps) => {
  const translation = useTranslation();
  return (
    <>
      <h2 css={tw`text-2xl font-semibold mb-4`}>{translation.help}</h2>
      <p>{translation.helpPage.content1}</p>
      <p>{translation.helpPage.content2}</p>
      <p tw="my-4">
        {translation.helpPage.supportNumber}{' '}
        <a href={`tel:${translation.meta.phone.number}`}>
          {translation.meta.phone.label}
        </a>
      </p>
      {hasAlternatives && (
        <div tw="my-4">
          {supportTicketId ? (
            <p>
              {translation.helpPage.yourTicketNumber} <b>{supportTicketId}</b>.
            </p>
          ) : (
            <Button onClick={onCreateTicket} disabled={loading}>
              {translation.helpPage.getSupportTicket}
            </Button>
          )}
          {error && <p>Error!</p>}
        </div>
      )}
    </>
  );
};

export default HelpPage;
