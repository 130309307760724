import React from 'react';

type initialValue = boolean;

export default function useToggle(
  initialValue: initialValue = false
): [boolean, () => void] {
  const [value, setValue] = React.useState(initialValue);
  const toggle = React.useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
}
