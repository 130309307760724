/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

const TextButtonStyle = styled.button(() => []);
export const TextStyle = styled.span(
  ({ children }: { children: ReactNode }) => [
    tw`border-b border-gray-700 hover:(text-darkred border-red)`,
  ]
);
const TextButton = ({
  handleClick,
  children,
}: {
  handleClick?: () => void;
  children: ReactNode;
}) => {
  return (
    <TextButtonStyle onClick={handleClick}>
      <TextStyle>{children}</TextStyle>
    </TextButtonStyle>
  );
};

export default TextButton;
