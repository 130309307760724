import { useCallback, useEffect } from 'react';
import hotkeys, { KeyHandler } from 'hotkeys-js';

interface Options {
  scope: string;
  filter?: (event: KeyboardEvent) => boolean;
}
const useHotkeys = (
  keys: string,
  callback: KeyHandler,
  deps: any[] = [],
  options: Options = { scope: '' }
) => {
  // eslint-disable-next-line
  const memoCallback = useCallback(callback, deps);

  useEffect(() => {
    if (options.filter) {
      hotkeys.filter = options.filter;
    } else {
      hotkeys.filter = () => true;
    }

    hotkeys(keys, options, memoCallback);

    return () => hotkeys.unbind(keys, options.scope, memoCallback);
  }, [memoCallback, options, keys]);
};

export default useHotkeys;
