import { assign } from 'xstate';
import { TCustomsEvents } from './CustomsEvents';
import { MachineConfig } from 'xstate/lib/types';
import { SendPackageContext, TGuards } from '../../Machines/sendPackageMachine';
import { toast } from 'react-toastify';
import { CurrencyId } from '../../enums/Currency';
import virtualPageview from '../../virtualPageview';

export interface CustomsContext {
  countryOrigin: string;
  invoiceNumber: string;
  currencyId?: string;
  value: number | '';
  commodityCode: string;
  commodityCode2: string;
  commodityCode3: string;
  commodityCode4: string;
  commodityCode5: string;
  commodityCode6: string;
  shipperEori: string;
  consigneeEori: string;
  error?: any;
}

export enum CustomsGuards {
  invalidValue = 'invalidValue',
  invalidCurrency = 'invalidCurrency',
  invalidOrigin = 'invalidOrigin',
  invalidInvoice = 'invalidInvoice',
  invalidCommodity = 'invalidCommodity',
  invalidCommodity2 = 'invalidCommodity2',
  invalidCommodity3 = 'invalidCommodity3',
  invalidCommodity4 = 'invalidCommodity4',
  invalidCommodity5 = 'invalidCommodity5',
  invalidCommodity6 = 'invalidCommodity6',
  validCustoms = 'validCustoms',
  invalidEoriShipper = 'invalidEoriShipper',
  invalidEoriConsignee = 'invalidEoriConsignee',
}
const inputStates = (invalidGuard?: CustomsGuards) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const CustomsMachine: MachineConfig<
  SendPackageContext, //customsContext,
  any,
  TCustomsEvents
> = {
  id: 'customs',
  initial: 'editing',
  entry: [
    assign((ctx) => ({
      customs: {
        ...ctx.customs,
        countryOrigin: !!ctx.customs.countryOrigin
          ? ctx.customs.countryOrigin
          : ctx.shipper.countryCode ?? '',
        currencyId:
          ctx.customs.currencyId ??
          // @ts-ignore
          CurrencyId[ctx.deliveryOption.Currency].toString(),
      },
    })),
    () => virtualPageview('/customs', 'Customs'),
  ],
  states: {
    error: {},
    editing: {
      type: 'parallel',
      states: {
        value: inputStates(CustomsGuards.invalidValue),
        currency: inputStates(CustomsGuards.invalidCurrency),
        origin: inputStates(CustomsGuards.invalidOrigin),
        invoice: inputStates(CustomsGuards.invalidInvoice),
        commodity: inputStates(CustomsGuards.invalidCommodity),
        commodity2: inputStates(CustomsGuards.invalidCommodity2),
        commodity3: inputStates(CustomsGuards.invalidCommodity3),
        commodity4: inputStates(CustomsGuards.invalidCommodity4),
        commodity5: inputStates(CustomsGuards.invalidCommodity5),
        commodity6: inputStates(CustomsGuards.invalidCommodity6),
        shipperEori: inputStates(CustomsGuards.invalidEoriShipper),
        consigneeEori: inputStates(CustomsGuards.invalidEoriConsignee),
        customsFiles: inputStates(),
      },
    },
    valid: {},
    savingCustoms: {
      invoke: {
        id: 'saveCustoms',
        src: 'saveCustoms',
        onDone: {
          target: '#payment',
          actions: (_, e) => console.log(e),
        },
        onError: {
          target: 'error',
          actions: [
            assign({
              customs: (ctx, event) => ({
                ...ctx.customs,
                error: event.data.error,
              }),
            }),
            (_, event) =>
              toast((event.data as Error).message, { type: 'error' }),
          ],
        },
      },
    },
    uploading: {
      invoke: {
        id: 'saveUploadFile',
        src: 'saveUploadFile',
        onDone: {
          target: '#customs.editing.customsFiles.edit',
          actions: [
            assign({
              uploadedFiles: (ctx, e) => [...ctx.uploadedFiles, ...e.data],
            }),
          ],
        },
        onError: {
          target: 'error',
          actions: [
            assign({
              customs: (ctx, event) => ({
                ...ctx.customs,
                error: event.data.error,
              }),
            }),
            (_, event) =>
              toast((event.data as Error).message, { type: 'error' }),
          ],
        },
      },
    },
    removing: {
      invoke: {
        id: 'deleteUploadFiles',
        src: 'deleteUploadFiles',
        onDone: {
          target: '#customs.editing.customsFiles.edit',
          actions: [
            assign({
              uploadedFiles: (ctx, e) => e.data,
            }),
          ],
        },
        onError: {
          target: 'error',
          actions: [
            assign({
              customs: (ctx, event) => ({
                ...ctx.customs,
                error: event.data.error,
              }),
            }),
            (_, event) =>
              toast((event.data as Error).message, { type: 'error' }),
          ],
        },
      },
    },
  },
  on: {
    CUSTOMS_DONE: [
      {
        cond: CustomsGuards.validCustoms,
        target: '.savingCustoms',
      },
      {
        target: [
          '#customs.editing.value.edit',
          '#customs.editing.currency.edit',
          '#customs.editing.origin.edit',
          '#customs.editing.invoice.edit',
          '#customs.editing.commodity.edit',
        ],
      },
    ],
    CHANGE_CUSTOMS_VALUE: [
      {
        target: '#customs.editing.value.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            value: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_CURRENCY: [
      {
        target: '#customs.editing.currency.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            currencyId: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_ORIGIN: [
      {
        target: '#customs.editing.origin.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            countryOrigin: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_INVOICE: [
      {
        target: '#customs.editing.invoice.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            invoiceNumber: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY: [
      {
        target: '#customs.editing.commodity.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY2: [
      {
        target: '#customs.editing.commodity2.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode2: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY3: [
      {
        target: '#customs.editing.commodity3.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode3: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY4: [
      {
        target: '#customs.editing.commodity4.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode4: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY5: [
      {
        target: '#customs.editing.commodity5.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode5: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_COMMODITY6: [
      {
        target: '#customs.editing.commodity6.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            commodityCode6: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_SHIPPER_EORI: [
      {
        target: '#customs.editing.shipperEori.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            shipperEori: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_CONSIGNEE_EORI: [
      {
        target: '#customs.editing.consigneeEori.edit',
        actions: assign({
          customs: (ctx, event): CustomsContext => ({
            ...ctx.customs,
            consigneeEori: event.data,
          }),
        }),
      },
    ],
    CHANGE_CUSTOMS_FILES: [
      {
        target: '#customs.uploading',
        actions: assign({
          files: (ctx, event) => event.data,
        }),
      },
    ],
    REMOVE_CUSTOMS_FILE: [
      {
        actions: assign({
          removeFileId: (ctx, event) => event.data,
        }),
        target: '#customs.removing',
      },
    ],
  },
};

export default CustomsMachine;

export const customsGuards: TGuards<CustomsGuards> = {
  invalidValue: (ctx) => typeof ctx.customs.value !== 'number',
  invalidCommodity: (ctx) => ctx.customs.commodityCode.length < 6,
  invalidCommodity2: (ctx) =>
    ctx.customs.commodityCode2.length > 0 &&
    ctx.customs.commodityCode2.length < 6,
  invalidCommodity3: (ctx) =>
    ctx.customs.commodityCode3.length > 0 &&
    ctx.customs.commodityCode3.length < 6,
  invalidCommodity4: (ctx) =>
    ctx.customs.commodityCode4.length > 0 &&
    ctx.customs.commodityCode4.length < 6,
  invalidCommodity5: (ctx) =>
    ctx.customs.commodityCode5.length > 0 &&
    ctx.customs.commodityCode5.length < 6,
  invalidCommodity6: (ctx) =>
    ctx.customs.commodityCode6.length > 0 &&
    ctx.customs.commodityCode6.length < 6,
  invalidOrigin: (ctx) => !ctx.customs.countryOrigin.length,
  invalidCurrency: (ctx) =>
    !ctx.customs.currencyId || !ctx.customs.currencyId?.length,
  invalidEoriShipper: (ctx) =>
    ctx.customs.shipperEori.length > 0 && ctx.customs.shipperEori.length < 8,
  invalidEoriConsignee: (ctx) =>
    ctx.customs.consigneeEori.length > 0 &&
    ctx.customs.consigneeEori.length < 8,
  invalidInvoice: (ctx) => ctx.customs.invoiceNumber.length < 2,
  validCustoms: (context, event, meta) =>
    [
      customsGuards.invalidCommodity,
      customsGuards.invalidCommodity2,
      customsGuards.invalidCommodity3,
      customsGuards.invalidCommodity4,
      customsGuards.invalidCommodity5,
      customsGuards.invalidCommodity6,
      customsGuards.invalidCurrency,
      customsGuards.invalidInvoice,
      customsGuards.invalidOrigin,
      customsGuards.invalidValue,
      customsGuards.invalidEoriConsignee,
      customsGuards.invalidEoriShipper,
    ].reduce((acc: boolean, fn) => acc && !fn(context, event, meta), true),
};
