/** @jsxImportSource @emotion/react */
import tw, { styled, TwStyle } from 'twin.macro';

type Size = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
type Variant = 'primary' | 'secondary' | 'outline' | 'success';

const getSize = (size: Size): TwStyle => {
  switch (size) {
    case 'xs':
      return tw`text-sm px-3 py-2`;
    case 'sm':
      return tw`text-sm px-6 py-3`;
    case 'base':
      return tw`text-base px-4 py-3`;
    case 'lg':
      return tw`text-lg px-4 py-3`;
    case 'xl':
      return tw`px-4 py-4 md:(px-10) xl:(px-16 py-5)`;
  }
};

const getVariant = (variant: Variant): TwStyle | [TwStyle] => {
  switch (variant) {
    case 'primary' || '':
      return tw`border border-gray-900 bg-gray-900 text-white hover:bg-black`;
    case 'secondary':
      return tw`border text-white bg-red hover:bg-darkred`;
    case 'outline':
      return tw`border border-black bg-white text-black hover:bg-black hover:text-white`;
    case 'success':
      return tw`border-green-900 bg-green-800 text-white hover:(bg-green-900)`;
  }
};

type ButtonProps = {
  size?: Size;
  variant?: Variant;
  disabled?: boolean;
  disabledStyle?: boolean;
  options?: TwStyle;
  children: React.ReactNode;
};

const Button = styled.button(
  ({
    size = 'base',
    variant = 'primary',
    disabled,
    disabledStyle,
    options,
  }: ButtonProps) => [
    tw`flex w-full md:w-auto items-center justify-center break-words`,
    getSize(size),
    getVariant(variant),
    (disabled || disabledStyle) &&
      tw`cursor-not-allowed bg-gray-500 opacity-50 hover:bg-gray-500`,
    options && options,
  ]
);

export default Button;
