import { ContactAction } from '../../reducers/contactReducer';
import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';

export const SenderAndConsigneeEvents = {
  updateSender: (action: ContactAction) =>
    createDataEvent('UPDATE_SENDER', action),

  updateConsignee: (action: ContactAction) =>
    createDataEvent('UPDATE_CONSIGNEE', action),

  senderConsigneeDone: () => createEvent('SENDER_CONSIGNEE_DONE'),

  package: () => createEvent('PACKAGE'),
};

export type TSenderAndConsigneeEvents = EventActionType<
  typeof SenderAndConsigneeEvents
>;
