import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';

export const SaveAddressEvents = {
  changeAddressName: (val: string) =>
    createDataEvent('CHANGE_ADDRESS_NAME', val),
  changeFirstName: (val: string) => createDataEvent('CHANGE_FIRST_NAME', val),
  changeLastName: (val: string) => createDataEvent('CHANGE_LAST_NAME', val),
  changeStreet: (val: string) => createDataEvent('CHANGE_STREET', val),
  changeStreetNumber: (val: string) =>
    createDataEvent('CHANGE_STREET_NUMBER', val),
  changeCity: (val: string) => createDataEvent('CHANGE_CITY', val),
  changePostalCode: (val: string) => createDataEvent('CHANGE_POSTAL_CODE', val),
  changeCountry: (val: string) => createDataEvent('CHANGE_COUNTRY', val),
  changePhone: (val: string) => createDataEvent('CHANGE_PHONE', val),
  continue: () => createEvent('CONTINUE'),
  previous: () => createEvent('PREVIOUS'),
  errorOk: () => createEvent('ERROR_OK'),
};

// send(CustomsEvents.changeCustomsValue(parseFloat(val)));

export type TSaveAddressEvents = EventActionType<typeof SaveAddressEvents>;
