import {
  createMachine,
  assign,
  ConditionPredicate,
  DoneInvokeEvent,
} from 'xstate';
import { SaveWebAddressResponse } from '../../Models/jena/response/SaveWebAddressResponse';
//import { LoginResponse } from '../../Models/jena/response/LoginResponse';
import { TSaveAddressEvents } from './SaveAddressEvents';

export interface ISaveAddressContext {
  id?: string;
  addressName: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  city: string;
  postCode: string;
  countryId: string;
  phone: string;
  error?: any;
  newAddress?: {
    addressid: number;
  };
}

type TSaveAddressGuards<G extends string> = Record<
  G,
  ConditionPredicate<ISaveAddressContext, TSaveAddressEvents>
>;

export type TSaveAddressGuard =
  | 'invalidPhone'
  | 'invalidAddressName'
  | 'invalidFirstName'
  | 'invalidLastName'
  | 'invalidStreet'
  | 'invalidStreetNumber'
  | 'invalidCity'
  | 'invalidPostCode'
  | 'invalidCountry'
  | 'validAddress';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const SaveAddressGuards: TSaveAddressGuards<TSaveAddressGuard> = {
  invalidAddressName: (ctx) => !minLength(ctx.addressName),
  invalidFirstName: (ctx) => !minLength(ctx.firstName),
  invalidLastName: (ctx) => !minLength(ctx.lastName),
  invalidStreet: (ctx) => !minLength(ctx.street),
  invalidStreetNumber: (ctx) => !minLength(ctx.streetNumber, 1),
  invalidCity: (ctx) => !minLength(ctx.city),
  invalidPostCode: (ctx) => !minLength(ctx.postCode),
  invalidCountry: (ctx) => !minLength(ctx.countryId, 1),
  invalidPhone: (ctx) => !minLength(ctx.phone, 1),
  validAddress: (ctx, event, meta) =>
    [
      SaveAddressGuards.invalidPhone,
      SaveAddressGuards.invalidAddressName,
      SaveAddressGuards.invalidFirstName,
      SaveAddressGuards.invalidLastName,
      SaveAddressGuards.invalidStreet,
      SaveAddressGuards.invalidStreetNumber,
      SaveAddressGuards.invalidCity,
      SaveAddressGuards.invalidPostCode,
      SaveAddressGuards.invalidCountry,
    ].reduce((acc: boolean, fn) => {
      return acc && !fn(ctx, event, meta);
    }, true),
};

const inputStates = (invalidGuard?: TSaveAddressGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const initContext: ISaveAddressContext = {
  addressName: '',
  firstName: '',
  lastName: '',
  street: '',
  streetNumber: '',
  city: '',
  postCode: '',
  countryId: '',
  phone: '',
};

const addressBookSaveMachine = createMachine<
  ISaveAddressContext,
  TSaveAddressEvents
>(
  {
    id: 'saveAddress',
    initial: 'step1',
    context: initContext,
    states: {
      step1: {
        initial: 'editing',
        states: {
          editing: {
            type: 'parallel',
            states: {
              addressName: inputStates('invalidAddressName'),
              firstName: inputStates('invalidFirstName'),
              lastName: inputStates('invalidLastName'),
              street: inputStates('invalidStreet'),
              streetNumber: inputStates('invalidStreetNumber'),
              city: inputStates('invalidCity'),
              postCode: inputStates('invalidPostCode'),
              countryId: inputStates('invalidCountry'),
              phone: inputStates('invalidPhone'),
            },
            on: {
              CHANGE_ADDRESS_NAME: {
                actions: assign({
                  addressName: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.addressName.edit',
              },
              CHANGE_FIRST_NAME: {
                actions: assign({
                  firstName: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.firstName.edit',
              },
              CHANGE_LAST_NAME: {
                actions: assign({
                  lastName: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.lastName.edit',
              },
              CHANGE_STREET: {
                actions: assign({
                  street: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.street.edit',
              },
              CHANGE_STREET_NUMBER: {
                actions: assign({
                  streetNumber: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.streetNumber.edit',
              },
              CHANGE_CITY: {
                actions: assign({
                  city: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.city.edit',
              },
              CHANGE_POSTAL_CODE: {
                actions: assign({
                  postCode: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.postCode.edit',
              },
              CHANGE_COUNTRY: {
                actions: assign({
                  countryId: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.countryId.edit',
              },
              CHANGE_PHONE: {
                actions: assign({
                  phone: (_, event) => event.data,
                }),
                target: '#saveAddress.step1.editing.phone.edit',
              },
              CONTINUE: '#saveAddress.save',
            },
          },
          failed: {
            on: {
              ERROR_OK: 'editing',
            },
          },
        },
      },

      save: {
        invoke: {
          src: 'save',
          onDone: {
            target: 'saved',
            actions: assign(
              (_, event: DoneInvokeEvent<SaveWebAddressResponse>) => {
                return {
                  ...initContext,
                  newAddress: {
                    addressid: event.data.addressid,
                  },
                };
              }
            ),
          },
          onError: {
            target: '#saveAddress.step1.editing',
            actions: assign((_, event: any) => ({
              error: event.data.message,
            })),
          },
        },
      },
      saved: {
        entry: ['onSaved'],
        on: {
          PREVIOUS: {
            actions: assign({
              error: (_, event) => '',
            }),
            target: '#saveAddress.step1',
          },
        },
      },
      failed: {},
    },
  },
  {
    guards: {
      ...SaveAddressGuards,
    },
  }
);

export default addressBookSaveMachine;
