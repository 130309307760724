import React, { PropsWithChildren, useContext } from 'react';
import { useMachine } from '@xstate/react';
import { UserEvents, userMachine } from '../Pages/User/UserMachine';
import ConfigContext from './ConfigContext';
import api from '../api/jenaApi';
import { customerInfoResponseToUser } from '../mappers/jenaMappers';
import { User } from '../Models/User';
import { useBoundEvents } from '../hooks/useBoundEvents';
import { ListCountry } from '../Models/jena/response/GetCountriesResponse';
import { useCookies } from 'react-cookie/lib';
import { assertDataEvent } from '../helpers/eventCreator';
import { Packagetype } from '../Models/jena/request/ListPackageTypesResponse';

interface Context {
  user?: User;
  sessionToken?: string;
  isLoggedIn: boolean;
  userEvents: typeof UserEvents;
  countries: ListCountry[];
  packageTypes: Packagetype[];
}
export const UserContext = React.createContext<Context>({
  isLoggedIn: false,
  userEvents: UserEvents,
  countries: [],
  packageTypes: [],
});
const cookiename = '_jena_session';
// FIXME: Don't store _jena_userid cookie at all when
// Jena returns userid in getcustomerinfo sometime in the future
const cookienameuserid = '_jena_userid';
export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { apiToken, apiBaseUrl } = useContext(ConfigContext);
  const [cookies, setCookie, removeCookie] = useCookies([
    cookiename,
    cookienameuserid,
  ]);
  const [machine, send] = useMachine(userMachine, {
    context: {
      user: {
        id: cookies[cookienameuserid],
        firstName: '',
        lastName: '',
        hasOrg: false,
        addresses: [],
        username: '',
        numAddress: 0,
        packageTypes: [],
      },
      sessionToken: cookies[cookiename],
    },
    actions: {
      setCookie: (ctx) => [
        setCookie(cookiename, ctx.sessionToken, {
          path: '/',
          maxAge: 3 * 60 * 60, // 3 hrs
        }),
        setCookie(cookienameuserid, ctx.user?.id, {
          path: '/',
          maxAge: 3 * 60 * 60, // 3 hrs
        }),
      ],
      clearCookie: () => {
        removeCookie(cookiename);
        removeCookie(cookienameuserid);
      },
      reset: () => window.location.reload(),
    },
    services: {
      loadUser: (ctx) =>
        api({
          baseUrl: apiBaseUrl,
          token: apiToken,
          sessionToken: ctx.sessionToken || '',
        })
          .getUserInfo()
          .then(customerInfoResponseToUser(ctx.countries)),
      loadCountries: (ctx) =>
        api({
          baseUrl: apiBaseUrl,
          token: apiToken,
          sessionToken: ctx.sessionToken || '',
        }).getCountries(),
      removeAddress: (ctx, event) => {
        return assertDataEvent(UserEvents.removeAddress, event) && event.data.id
          ? api({
              baseUrl: apiBaseUrl,
              token: apiToken,
              sessionToken: ctx.sessionToken || '',
            }).removeUserAddress({ id: event.data.id })
          : Promise.reject();
      },
    },
  });

  const events = useBoundEvents(UserEvents, send);
  return (
    <UserContext.Provider
      value={{
        user: machine.context.user,
        sessionToken: machine.context.sessionToken,
        isLoggedIn: machine.matches('loggedIn'),
        userEvents: events,
        countries: machine.context.countries,
        packageTypes: machine.context.packageTypes,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
