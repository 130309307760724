/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw, { styled } from 'twin.macro';
import { SendPackageContext } from '../../../Machines/sendPackageMachine';
import { useEffect, useCallback } from 'react';

interface OrderConfirmationAnalyticsProps {
  state: SendPackageContext;
}

const OrderConfirmationAnalytics = ({
  state,
}: OrderConfirmationAnalyticsProps) => {
  const sendToAnalytics = useCallback(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: state.orderConfirmation?.awbnbr,
          value: state.orderConfirmation?.priceexvat,
          tax:
            Number(state.orderConfirmation?.priceincvat || 0) -
            Number(state.orderConfirmation?.priceexvat || 0),
          shipping: 0,
          currency: state.deliveryOption?.Currency,
          coupon: state.campaignCode,
          items: [
            {
              item_id: state.deliveryOption?.ConsignmentId,
              item_name: state.orderConfirmation?.product,
              affiliation: 'Jetpak web booking',
              coupon: state.campaignCode,
              discount: 0,
              index: 0,
              item_brand: 'Booking',
              price: state.orderConfirmation?.priceexvat,
              quantity: 1,
            },
          ],
        },
      });

      // mixpanel
      window.dataLayer.push({ bookingConfirmed: null });
      window.dataLayer.push({
        event: 'deliveryBooked',
        bookingConfirmed: {
          revenue: state.deliveryOption?.OriginalPriceExcludingVAT, // decimal separate is a full stop and must always be followed by 2 decimals.
          currency: state.deliveryOption?.Currency,
          vat: state.deliveryOption?.Vat, // decimal separate is a full stop and must always be followed by 2 decimals.
          shipperCountry: state.shipper.country,
          shipperCountryCode: state.shipper.countryCode,
          recipientCountry: state.consignee.country,
          recipientCountryCode: state.consignee.countryCode,
          awbNo: state.awbNumber,
          deliveryQuantity: state.packages.reduce(
            (prev, curr, i, p) => prev + curr.quantity,
            0
          ), // if e.g. “one package” of 10 envelops, then this value is 10
          bookingQuantity: state.packages.length, // if two package options are chosen and each of them has 5 in quantity, then this value is still 2
          paymentMethod: state.paymentType,
          deliveryDetails: state.packages.map((x) => ({
            // identical object per package line added and booked
            packageType: x.parcelType?.name, // e.g. envelope if chosen from dropdown
            packageQuantity: x.quantity, // e.g. envelope if chosen from dropdown
            'packageWeight(kg)': x.weight, // e.g. envelope if chosen from dropdown
            'packageHeight(cm)': x.height, // e.g. envelope if chosen from dropdown
            'packageWidth(cm)': x.width, // e.g. envelope if chosen from dropdown
            'packageLength(cm)': x.length, // e.g. envelope if chosen from dropdown
          })),
        },
      });
    }
  }, [state]);

  useEffect(() => {
    sendToAnalytics();
  }, [sendToAnalytics]);

  return <></>;
};

export default OrderConfirmationAnalytics;
