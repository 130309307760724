/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import DeliveryOption from '../../../../Models/DeliveryOption';
// import useMedia from 'use-media';
import useTranslation from '../../../../hooks/useTranslation';
import { Translations } from '../../../../translations/TranslationType';
import { DeliveryAlternativesDesktop, DeliveryAlternativesMobile } from './';
import { forwardRef, Fragment } from 'react';
import { boxShadow } from '../../../../Components/Base/BoxShadow';
import Loader, { LoaderLabel } from '../../../../Components/Base/Loader';
import { IconSad } from '../../../../svg';

interface DeliveryAlternativesProps {
  alternatives: DeliveryOption[];
  selected: DeliveryOption | undefined;
  handleSelect: (option: DeliveryOption) => void;
  isLoading: boolean;
  noneFound: boolean;
}

const DeliveryAlternatives = forwardRef<
  HTMLDivElement,
  DeliveryAlternativesProps
>(
  (
    { alternatives, selected, handleSelect, isLoading, noneFound },
    bounceRef
  ) => {
    const translations: Translations = useTranslation();
    const matchesDesktop = window.matchMedia('(min-width: 992px)').matches;

    return (
      <Fragment>
        <div css={tw`pb-10`} ref={bounceRef}>
          <div css={tw`container mx-auto px-4`}>
            <div css={tw`row`}>
              <div css={tw`col-12 w-full`}>
                <h2 css={tw`text-2xl font-semibold mb-4`}>
                  {translations.selectDeliveryOptions}
                </h2>
              </div>
            </div>
            <div css={[tw`relative bg-white`, boxShadow]}>
              {isLoading && (
                <div
                  css={[
                    tw`relative w-full h-full flex items-center justify-center border p-6`,
                    css`
                      min-height: 150px;
                    `,
                    isLoading && alternatives.length && tw`absolute p-0`,
                  ]}
                >
                  <Loader variant="loading-delivery-alternatives">
                    <LoaderLabel
                      label={translations.deliveryPage.loadingAlternatives}
                    />
                  </Loader>
                </div>
              )}

              {!alternatives?.length && !isLoading && (
                <div
                  css={[
                    tw`flex flex-wrap flex-col items-center justify-center border p-6`,
                  ]}
                >
                  {!alternatives?.length &&
                    (noneFound ? (
                      <Fragment>
                        <IconSad tw="w-10 h-10 mb-2 items-center text-darkred stroke-current sm:(w-12 h-12)" />
                        <p tw="max-w-sm text-center">
                          {translations.deliveryPage.noAlternativesFound}
                        </p>
                      </Fragment>
                    ) : (
                      <p>
                        {
                          translations.deliveryPage
                            .fillInTheFieldsAboveToSeeDeliveryAlternatives
                        }
                      </p>
                    ))}
                </div>
              )}

              {alternatives?.length ? (
                matchesDesktop ? (
                  <DeliveryAlternativesDesktop
                    alternatives={alternatives}
                    selected={selected}
                    handleSelect={handleSelect}
                  />
                ) : (
                  <DeliveryAlternativesMobile
                    alternatives={alternatives}
                    selected={selected}
                    handleSelect={handleSelect}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
);

export default DeliveryAlternatives;
