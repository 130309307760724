import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';

export const PackageInformationEvents = {
  changeContents: (contents: string) =>
    createDataEvent('CHANGE_CONTENTS', contents),

  changeAcceptedTerms: (accepted: boolean) =>
    createDataEvent('CHANGE_ACCEPTED_TERMS', accepted),

  changeInsuranceValue: (val: string) =>
    createDataEvent('CHANGE_INSURANCE_VALUE', val),

  changeUseInsurance: (val: boolean) =>
    createDataEvent('CHANGE_USE_INSURANCE', val),

  donePackageInfo: () => createEvent('DONE_PACKAGE_INFO'),

  delivery: () => createEvent('DELIVERY'),
};

export type TPackageInformationEvents = EventActionType<
  typeof PackageInformationEvents
>;
