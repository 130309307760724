// TODO: what currencies are supported?
export enum Currency {
  AUD = 'AUD',
  CHF = 'CHF',
  DKK = 'DKK',
  EEK = 'EEK',
  EUR = 'EUR',
  GBP = 'GBP',
  LTL = 'LTL',
  LVL = 'LVL',
  NOK = 'NOK',
  PLN = 'PLN',
  SEK = 'SEK',
  USD = 'USD',
}

export enum CurrencyId {
  EUR = 1,
  SEK = 2,
  USD = 3,
  DKK = 4,
  NOK = 5,
  GBP = 6,
  CHF = 7,
  LVL = 42,
  LTL = 43,
  EEK = 22,
  PLN = 62,
  AUD = 82,
}
