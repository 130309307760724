/** @jsxImportSource @emotion/react */
import { useState, useRef } from 'react';
import tw from 'twin.macro';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import { IconTrash } from '../../svg';

interface ConfirmDeleteButtonProps {
  onDelete: () => void;
  confirmLabel: string;
  label: string;
}

export default function ConfirmDeleteButton({
  onDelete,
  label,
  confirmLabel,
}: ConfirmDeleteButtonProps) {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  useClickOutsideHandler(ref, () => setShow(false));
  return (
    <div ref={ref}>
      {show ? (
        <div css={tw`text-red`}>
          <button onClick={() => onDelete()} css={tw`flex items-center`}>
            <IconTrash tw="w-5 h-5 stroke-current" /> {confirmLabel}
          </button>
        </div>
      ) : (
        <button onClick={() => setShow((v) => !v)} css={tw`flex items-center`}>
          <IconTrash tw="w-5 h-5 stroke-current" /> {label}
        </button>
      )}
    </div>
  );
}
