/** @jsxImportSource @emotion/react */
import tw, { css, theme } from 'twin.macro';
import { keyframes } from '@emotion/react';

const jump = keyframes`
    0 {
      transform: translateY(0) translateX(-50%);
    }

    50% {
      transform: translateY(12px) translateX(-50%);
    }

    100% {
      transform: translateY(0) translateX(-50%);
    }
`;

const bounce = [
  tw`text-xs`,
  css`
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    animation: ${jump} 2s infinite;
    margin: 0 auto;
    width: calc(100vw - 3em);
    max-width: 320px;
    bottom: 1.5rem;

    @media (min-width: ${theme`screens.sm`}) {
      width: auto;
      max-width: none;
    }

    @media (min-width: ${theme`screens.md`}) {
      font-size: ${theme`fontSize.sm`};
      bottom: 120px;
    }
  `,
];

const bounceInside = [
  tw`flex bg-black text-white rounded items-center w-full`,
  css`
    padding: 0.75rem 1.5rem;
    box-shadow: none;
    box-shadow: 0 2px 0 #000;
    text-overflow: ellipsis;
    overflow: hidden;

    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      transform: translateX(-20%);
      left: 20%;
      border-top: solid 12px #333;
      border-left: solid 12px transparent;
      border-right: solid 12px transparent;
    }
  `,
];

interface BounceProps {
  label: string;
}

const Bounce = ({ label }: BounceProps) => (
  <div css={bounce}>
    <div css={bounceInside}>{label}</div>
  </div>
);

export default Bounce;
