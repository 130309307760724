import { assign, createMachine, actions } from 'xstate';
import { ConsignmentResponse } from '../../Models/jena/response/ListConsignmentResponse';
import { ShowBookingInfoResponse } from '../../Models/jena/response/ShowBookingInfoResponse';
import { TOrderHistoryEvents } from './OrderHistoryEvents';
import { Address } from '../../Models/Address';
const { send, cancel } = actions;

export interface OrderHistoryContext {
  error?: any;
  consignments?: ConsignmentResponse[] | null;
  page: number;
  numRows: number;
  awbNbr?: string;
  booking?: ShowBookingInfoResponse;
  shipper: Address;
  consignee: Address;
  verified: boolean;
  query: string;
  bookingDate: string;
}

const orderHistoryMachine = createMachine<
  OrderHistoryContext,
  TOrderHistoryEvents
>({
  id: 'orderHistory',
  initial: 'init',
  context: {
    page: 1,
    numRows: 0,
    verified: false,
    shipper: {} as Address,
    consignee: {} as Address,
    query: '',
    bookingDate: '',
  },
  states: {
    init: {
      invoke: {
        src: 'searchEnhancedConsignment',
        onDone: {
          target: 'list',
          actions: assign((_, event) => ({
            ...event.data,
          })),
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },
    list: {},
    detail: {},
    failed: {},
    loadConsignmentInfo: {
      invoke: {
        src: 'showBookingInfo',
        onDone: {
          actions: [assign({ booking: (ctx, e) => e.data })],
          target: 'detail',
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },
  },
  on: {
    SHOW_BOOKING: {
      actions: [
        assign({
          awbNbr: (ctx, e) => e.data,
        }),
      ],
      target: 'loadConsignmentInfo',
    },
    LIST: {
      target: 'list',
    },
    CHANGE_PAGE: {
      actions: assign({
        page: (_, event): number => parseInt(event.data),
      }),
      target: 'init',
    },
    CHANGE_BOOKINGDATE: {
      actions: assign({
        bookingDate: (_, event): string => event.data,
      }),
      target: 'init',
    },
    RESET_QUERY: {
      actions: [
        assign((_, event) => ({
          query: '',
          page: 1,
        })),
      ],
      target: 'init',
    },
    CHANGE_QUERY: {
      actions: [
        assign({
          query: (_, event): string => event.data,
        }),
        cancel('debounced-fetch-timer'),
        send({ type: 'FETCH' }, { delay: 1200, id: 'debounced-fetch-timer' }),
      ],
    },
    FETCH: 'init',
  },
});

export default orderHistoryMachine;
