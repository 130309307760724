/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { IconCheckmarkWhite } from '../svg';
import ContainerXl from './Base/ContainerXl';

export enum Pages {
  delivery = 'delivery',
  package = 'package',
  address = 'address',
  payment = 'payment',
  customs = 'customs',
}
export interface Tab {
  idx: number;
  label: string;
  value: Pages;
  state: TabState;
}

export type TabState = 'active' | 'inactive' | 'done';

type TabProps = {
  key: string;
  onClick: () => void;
  state: TabState;
} & Tab;

const circleStyles = css`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 1.25rem;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 0.25rem;
`;

const Tab = ({ idx, label, onClick, state }: TabProps) => {
  return (
    <button
      css={[
        tw`flex items-center py-4 px-1 text-sm lg:text-base font-bold text-white bg-darkred`,
        css`
          flex: 1 1 auto;
        `,
      ]}
      onClick={onClick}
    >
      {(state === 'active' || state === 'inactive') && (
        <div css={[circleStyles, state === 'inactive' && tw`opacity-50`]}>
          <span css={[tw`relative text-red text-sm`, `line-height: 1;`]}>
            {idx}
          </span>
        </div>
      )}

      {state === 'done' && (
        <IconCheckmarkWhite tw={'text-white fill-current mr-2 w-5 h-5'} />
      )}
      <span
        css={[
          tw`leading-snug ml-2`,
          state !== 'active' ? tw`sr-only md:not-sr-only` : tw`underline`,
          (state === 'inactive' || state === 'done') && tw`opacity-50`,
        ]}
      >
        {label}
      </span>
    </button>
  );
};

interface TabNavigationProps {
  tabs: Tab[];
  onChange: (val: Pages) => void;
}

const tabNavigationStyle = [
  tw`bg-red border-b-2 border-gray-200`,
  css`
    position: sticky;
    top: 0;
    z-index: 2;
  `,
];
const TabNavigation = ({ tabs, onChange }: TabNavigationProps) => {
  return (
    <nav css={tabNavigationStyle}>
      <ContainerXl>
        <div tw={'relative flex'}>
          {tabs.map((tab, i) => (
            <Tab key={`t-${i}`} onClick={() => onChange(tab.value)} {...tab} />
          ))}
        </div>
      </ContainerXl>
    </nav>
  );
};

export default TabNavigation;
