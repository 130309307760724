/** @jsxImportSource @emotion/react */
import { Address } from '../../Models/Address';
import tw from 'twin.macro';
import { Fragment, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';
import {
  IconTrash,
  IconChevronDown,
  IconChevronUp,
  IconPencilSquare,
} from '../../svg';
import { OpenAddAddress } from '../../Pages/Delivery/Components/ShippingAddresses/OpenAddAddress';
import { ModalLarge } from '../Modal/Modal';
import { SaveAddress } from '../SaveAddress/SaveAddress';
import { FormInput } from '../FormFields';
import AddressbookMachine from './addressbookMachine';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import { AddressbookEvents } from '../../Pages/Delivery/Components/AddressbookEvents';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import { useUser } from '../../hooks/useUser';
import Loader, { LoaderLabel } from '../Base/Loader';

interface AddressBookProps {
  id: string;
  savedAddresses?: Address[];
  onRemove?: (address: Address) => void;
  onAdd?: (address: Address) => void;
  onSelect: (address: Address) => void;
  numAddress?: number;
}
interface AddressColumn {
  key: keyof Address;
  title: string;
}

const compareAddress = (key: keyof Address, asc: boolean) => {
  if (key === 'streetNumber')
    return (a: Address, b: Address) => {
      return asc
        ? Number(a[key]) - Number(b[key])
        : Number(b[key]) - Number(a[key]);
    };

  return (a: Address, b: Address) => {
    return asc
      ? (a[key] || '').localeCompare(b[key] || '')
      : (b[key] || '').localeCompare(a[key] || '');
  };
};

export const AddressBook = ({
  savedAddresses,
  onSelect,
  onAdd,
  onRemove,
  id,
  numAddress,
}: AddressBookProps) => {
  const api = useJenaApi();
  const { user } = useUser();
  const [state, send] = useMachine(AddressbookMachine, {
    context: {
      initialAddresses: savedAddresses,
      addresses: savedAddresses,
    },
    services: {
      addressbookRequest: (context: any) =>
        api.listaddress(context.query, user),
    },
  });
  const events = useBoundEvents(AddressbookEvents, send);
  const translations = useTranslation();
  const [currentKey, setCurrentKey] = useState<keyof Address>('name');
  const [sortAsc, setSortAsc] = useState(true);
  const [editAddress, setEditAddress] = useState<Address | undefined>(
    undefined
  );
  const setSortBy = (key: keyof Address) => {
    setSortAsc((v) => (currentKey === key ? !v : true));
    setCurrentKey(key);
  };

  const columns: AddressColumn[] = [
    { key: 'addressName', title: translations.company },
    { key: 'name', title: translations.name },
    { key: 'street', title: translations.addressBookModal.street },
    { key: 'streetNumber', title: translations.addressBookModal.streetNumber },
    { key: 'postCode', title: translations.addressBookModal.postalCode },
    { key: 'city', title: translations.addressBookModal.city },
    { key: 'country', title: translations.addressBookModal.country },
  ];

  return (
    <Fragment>
      <h2 tw="text-2xl font-heading font-semibold mb-2">
        {translations.addressBookModal.title}
      </h2>
      <Fragment>
        <div tw="flex justify-between items-center mb-3">
          <div tw="flex items-center space-x-2">
            <FormInput
              name="search"
              value={state.context.query}
              onChange={(v) => events.changeQuery(v.toLocaleLowerCase())}
              placeholder="Search"
            />
            {state.matches('loading') && (
              <Loader variant="loading-delivery-alternatives">
                <LoaderLabel label={translations.loading} />
              </Loader>
            )}
          </div>
          <OpenAddAddress onAdd={onAdd} />
        </div>
        <div css={tw`overflow-auto w-full`}>
          <table css={tw`w-full`}>
            <thead>
              <tr>
                {columns.map(({ key, title }) => (
                  <th
                    key={key}
                    tw="text-left px-3 pr-5 py-3 cursor-pointer relative hover:bg-gray-100"
                    onClick={() => setSortBy(key)}
                  >
                    {title}
                    {currentKey === key ? (
                      sortAsc ? (
                        <IconChevronDown tw="block absolute right-1 top-4 w-4 h-4" />
                      ) : (
                        <IconChevronUp tw="block absolute right-1 top-4 w-4 h-4" />
                      )
                    ) : (
                      <IconChevronDown tw="block text-gray-400 absolute right-1 top-4 w-4 h-4" />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.context.addresses
                ?.sort(compareAddress(currentKey, sortAsc))
                .map((address: Address, i: any) => {
                  const onClick = () => onSelect(address);
                  return (
                    <tr
                      key={`address-${i}`}
                      tw="p-3 border-b cursor-pointer text-sm text-left hover:bg-gray-100 relative"
                    >
                      {columns.map(({ key }) => (
                        <td
                          tw="p-3"
                          onClick={onClick}
                          key={`address-${address.id}-${key}`}
                        >
                          {address[key]}
                        </td>
                      ))}
                      <td>
                        <button onClick={(e) => setEditAddress(address)}>
                          <IconPencilSquare tw="w-5 h-5 stroke-2 stroke-current" />
                        </button>
                        {!!onRemove && (
                          <button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  'Do you want to remove this address?'
                                )
                              ) {
                                onRemove(address);
                                events.removeAddress(address);
                              }
                            }}
                          >
                            <IconTrash tw="w-5 h-5 stroke-2 stroke-current" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {state.context.addresses.length === 0 && (
            <p tw="mb-6">{translations.addressBookModal.noSavedAddresses}</p>
          )}
          <p tw="mt-4">
            {translations.addressBookModal.showing(
              state.context.addresses.length,
              numAddress ?? 0
            )}
          </p>
        </div>
      </Fragment>

      {editAddress && (
        <ModalLarge
          isOpen={!!editAddress}
          onRequestClose={() => setEditAddress(undefined)}
          handleClose={() => setEditAddress(undefined)}
        >
          <SaveAddress onAdd={onAdd} address={editAddress} />
        </ModalLarge>
      )}
    </Fragment>
  );
};
