import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import { Advise } from '../../Models/Advise';
import { NetsPaymentCompletedResponse } from './paymentPageMachine';

export const AdviseEvents = {
  add: (advise: Advise) => createDataEvent('ADD', advise),
  remove: (id: string) => createDataEvent('REMOVE', id),
  change: (advise: Partial<Advise> & { id: string }) =>
    createDataEvent('CHANGE', advise),
};
type TAdviseEvent = EventActionType<typeof AdviseEvents>;

export const adviseReducer = (state: Advise[], event: TAdviseEvent) => {
  switch (event.type) {
    case 'ADD':
      return [...state, event.data];
    case 'CHANGE':
      return [
        ...state.map((a) =>
          a.id === event.data.id ? { ...a, ...event.data } : a
        ),
      ];
    case 'REMOVE':
      return [...state.filter((a) => a.id !== event.data)];
  }
};

export const PaymentEvents = {
  changeName: (name: string) => createDataEvent('CHANGE_BILLING_NAME', name),

  changeAddress: (address: string) =>
    createDataEvent('CHANGE_BILLING_ADDRESS', address),

  changePostalCode: (postalCode: string) =>
    createDataEvent('CHANGE_BILLING_POSTALCODE', postalCode),

  changedPostalCode: () => createEvent('CHANGED_BILLING_POSTALCODE'),

  changeCity: (city: string) => createDataEvent('CHANGE_BILLING_CITY', city),

  changeEmail: (email: string) =>
    createDataEvent('CHANGE_BILLING_EMAIL', email),

  changePhone: (val: string) => createDataEvent('CHANGE_BILLING_PHONE', val),

  changeCountry: (country: string) =>
    createDataEvent('CHANGE_BILLING_COUNTRY', country),

  changePaymentType: (type: 'invoice' | 'card') =>
    createDataEvent('CHANGE_PAYMENT_TYPE', type),

  changePaymentReference: (reference: string) =>
    createDataEvent('CHANGE_PAYMENT_REFERENCE', reference),

  verifyCampaignCode: () => createEvent('VERIFY_CAMPAIGN_CODE'),

  changeCampaignCode: (val: string) =>
    createDataEvent('CHANGE_CAMPAIGN_CODE', val),

  changePrivacyPolicy: (val: boolean) =>
    createDataEvent('CHANGE_PRIVACY_POLICY', val),
  changeAcceptedTerms: (val: boolean) =>
    createDataEvent('CHANGE_ACCEPT_TERMS', val),

  changeAdvise: (event: TAdviseEvent) =>
    createDataEvent('CHANGE_ADVISE', event),

  startOrder: () => createEvent('START_ORDER'),
  payInitialized: (data: any) => createDataEvent('NETS_PAY_INITIALIZED', data),
  cardAccepted: (data: NetsPaymentCompletedResponse) =>
    createDataEvent('CARD_ACCEPTED', data),
  cardDeclined: (message?: string) => createDataEvent('CARD_DECLINED', message),
  cardCancelled: () => createEvent('CARD_CANCELLED'),

  orderCreated: () => createEvent('ORDER_CREATED'),

  errorOk: () => createEvent('ERROR_OK'),

  customs: () => createEvent('CUSTOMS'),
  address: () => createEvent('ADDRESS'),

  changeUploadFiles: (files: FileList | null) =>
    createDataEvent('CHANGE_CUSTOMS_FILES', files),

  removeFile: (id: string) => createDataEvent('REMOVE_CUSTOMS_FILE', id),
};

export type TPaymentEvents = EventActionType<typeof PaymentEvents>;
