import { Address } from '../Models/Address';

export const getProperty = <Type, Key extends keyof Type>(
  obj: Type,
  key: Key
) => obj[key];

export const stringifyAddress = (address: Address): string => {
  const {
    street,
    streetNumber,
    city,
    postCode,
  }: Pick<Address, 'street' | 'streetNumber' | 'city' | 'postCode'> = address;
  return JSON.stringify({ street, streetNumber, city, postCode });
};

export const removeDuplicateAdressReducer = (acc: Address[], val: Address) =>
  acc.some((item) => stringifyAddress(item) === stringifyAddress(val))
    ? acc
    : [...acc, val];

export const sortByAddress =
  (prop: keyof Address) => (a: Address, b: Address) => {
    const propA = getProperty(a, prop);
    const propB = getProperty(b, prop);

    if (!(propA && propB)) return 0;

    const pa = propA.toLowerCase();
    const pb = propB.toLowerCase();

    if (pa < pb) {
      return -1;
    }
    if (pa > pb) {
      return 1;
    }
    return 0;
  };
