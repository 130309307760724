import { css, styled } from 'twin.macro';

interface DotsProps {
  selected: boolean;
}
export const Dots = styled.span(({ selected }: DotsProps) => {
  return [
    css`
      position: absolute;
      z-index: 0;
      background: radial-gradient(
        ellipse at center,
        #d3d3d3 0%,
        #d3d3d3 25%,
        transparent 25%
      );
      background-size: 8px 8px;
      background-repeat: repeat-y;
      background-position: center;
      width: 4px;
      height: calc(100% - 5.75rem);
      left: 0.75rem;
      top: 3rem;

      @media (min-width: 576px) {
        left: 1.5rem;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        z-index: 1;
        box-sizing: initial;
        border-width: 1px;
        border-color: #d3d3d3;
      }

      &:before {
        top: -1rem;
        left: -4px;
      }
      &:after {
        bottom: -1rem;
        left: -4px;
      }
    `,
    selected &&
      css`
        &:before {
          background: radial-gradient(
            ellipse at center,
            hsl(106, 59%, 52%) 0%,
            hsl(106, 59%, 52%) 30%,
            transparent 63%
          );
        }
      `,
  ];
});
