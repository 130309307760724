import {
  defaultParcel as _defaultParcel,
  makeDefaultParcel,
  Parcel,
} from '../../Models/Parcel';
import { Packagetype } from '../../Models/jena/request/ListPackageTypesResponse';
import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import { v4 } from 'uuid';

export const PackageActions = {
  add: () => createEvent('ADD'),
  splitUp: (parcel: Partial<Parcel>) => createDataEvent('SPLITUP', parcel),
  change: (parcel: Partial<Parcel> & { id: string }) =>
    createDataEvent('CHANGE', parcel),
  remove: (id: string) => createDataEvent('REMOVE', id),
  clear: () => createEvent('CLEAR'),
};
export type PackageAction = EventActionType<typeof PackageActions>;

const packageReducer = (
  state: Parcel[] = [],
  action: PackageAction,
  packageTypes: Packagetype[]
): Parcel[] => {
  const parcelType = packageTypes.length > 0 ? packageTypes[0] : undefined;
  const defaultParcel = parcelType
    ? makeDefaultParcel(parcelType)
    : { ..._defaultParcel, parcelType };
  switch (action.type) {
    case 'ADD':
      if (state.length > 100) return state;
      return [...state, { ...defaultParcel, id: v4() }];
    case 'SPLITUP':
      const originalWeight = action.data.weight || 1;
      const quantity = action.data.quantity || 1;
      const weight = Math.round((originalWeight / quantity) * 100) / 100;
      const remainingWeight = originalWeight - weight * quantity; // to fix any rounding errors
      const ret = [
        ...state.map((item) =>
          item.id === action.data.id
            ? {
                ...item,
                weight,
                quantity: 1,
              }
            : item
        ),
      ];
      for (let i = 0; i < quantity - 1; i++) {
        if (ret.length > 100) break;
        ret.push({
          ...defaultParcel,
          ...action.data,
          weight:
            i === 0
              ? Math.round((weight + remainingWeight) * 100) / 100
              : weight,
          quantity: 1,
          id: v4(),
        });
      }
      return ret;
    case 'CHANGE':
      return [
        ...state.map((item) =>
          item.id === action.data.id
            ? {
                ...item,
                ...action.data,
              }
            : item
        ),
      ];
    case 'REMOVE':
      return [...state.filter((i) => i.id !== action.data)];
    case 'CLEAR':
      return [{ ...defaultParcel, id: v4() }];
    default:
      throw new Error('Invalid type noob');
  }
};

export default packageReducer;
