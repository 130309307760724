/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import DeliveryOption from '../../../../Models/DeliveryOption';
import useTranslation from '../../../../hooks/useTranslation';
import { Translations } from '../../../../translations/TranslationType';
import { IconCheckmark } from '../../../../svg';
import { Currency } from '../../../../enums/Currency';
import ConfigContext from '../../../../context/ConfigContext';
import { useContext } from 'react';
import { formatRelativeDate } from '../../../../helpers/dateSelectGenerator';

interface DeliveryAlternativeProps {
  key: number;
  deliveryAlternative: DeliveryOption;
  handleSelected: (option: DeliveryOption) => void;
  selectedId: string | undefined;
}

interface DeliveryLabels {
  pickup: string;
  delivery: string;
  pickupEarliest: string;
  deliveryLatest: string;
  price: string;
  currency: Currency;
  withVat: string;
  select?: string;
  selected?: string;
}

const DeliveryAlternativesDetailsDesktop = ({
  deliveryAlternative: {
    AlternativeId: id,
    PickupDateTime: pickupTime,
    DeliveryDateTime: deliveryTime,
    OriginalPriceExcludingVAT: priceExcludingVAT,
    OriginalPriceIncludingVAT: priceIncludingVAT,
    Currency: currency,
  },
  deliveryAlternative: alternative,
  selectedId,
  handleSelected,
}: DeliveryAlternativeProps) => {
  const translation: Translations = useTranslation();
  const { language } = useContext(ConfigContext);

  const selected = id === selectedId;
  const deliveryLabels: DeliveryLabels = {
    pickup: translation.pickUp,
    delivery: translation.delivery,
    pickupEarliest: translation.earliestPickup,
    deliveryLatest: translation.latestDelivery,
    price: translation.price,
    currency: Currency.SEK,
    withVat: translation.withVat,
    select: translation.select,
  };

  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  });

  return (
    <tr
      css={[
        tw`cursor-pointer`,
        css`
          &:nth-of-type(2n + 1) {
            background-color: #fafafa;
          }
        `,
        selected ? tw`bg-green-200!` : null,
      ]}
      onClick={() => {
        handleSelected(alternative);
      }}
    >
      <td tw="px-4 py-2 md:px-8 md:py-4 xl:py-6">
        <div tw="flex flex-col">
          <span tw="mb-1 text-gray-700 text-sm">
            {deliveryLabels.pickupEarliest}
          </span>
          <time tw={'text-lg leading-tight'}>
            {formatRelativeDate(language, pickupTime)}
          </time>
        </div>
      </td>
      <td tw="px-4 py-2 md:px-8 md:py-4 xl:py-6">
        <div tw="flex flex-col">
          <span tw="mb-1 text-gray-700 text-sm">
            {deliveryLabels.deliveryLatest}
          </span>
          <time tw={'text-lg leading-tight'}>
            {formatRelativeDate(language, deliveryTime)}
          </time>
        </div>
      </td>
      <td tw="px-4 py-2 md:px-8 md:py-4 xl:py-6">
        <div tw="flex flex-col">
          <span tw="text-lg leading-tight">
            {numberFormat.format(priceExcludingVAT)}
          </span>
          <span tw="text-gray-700 text-sm">
            {numberFormat.format(priceIncludingVAT)} {deliveryLabels.withVat}
          </span>
        </div>
      </td>
      <td tw="px-4 py-2 md:px-8 md:py-4 xl:py-6 text-center">
        <button
          onClick={() => {
            handleSelected(alternative);
          }}
          css={[
            tw`inline-block border rounded-full px-5 py-1`,
            !selected
              ? tw`bg-white`
              : tw`text-white bg-green-900 p-0 border-green-900`,
          ]}
        >
          {!selected ? (
            deliveryLabels.select
          ) : (
            <IconCheckmark
              css={[tw`fill-current text-green-900 w-8 h-8 leading-loose`]}
            />
          )}
        </button>
      </td>
    </tr>
  );
};

export default DeliveryAlternativesDetailsDesktop;
