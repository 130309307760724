import { StartEmbeddedParams } from '../Models/jena/request/StartEmbeddedRequest';
import { GetTokenResponse } from '../Models/jena/response/GetTokenResponse';
import { StartEmbeddedResponse } from '../Models/jena/response/StartEmbeddedResponse';
import { IRequestOptions, parseJson } from './jenaApi';

interface IApiOptions {
  baseUrl: string;
}

const netsApiEnvelope = (action: string, token?: string, params?: object) => ({
  // request: {
  //   action,
  //   token,
  //   params,
  //   platform: 'webbook',
  // },
  // authorization: {
  //   apptype: '2',
  //   appversion: 11,
  // },
  action,
  token,
  params,
});

interface NetsErrorEnvelope {
  result: 'error';
  error: {
    'error-code': string;
    'error-text': string;
  };
}

const isError = (
  response: NetsErrorEnvelope | any
): response is NetsErrorEnvelope =>
  response.result === 'error' || 'error' in response;

export const netsResponseHandler = async <TReturnData>(response: Response) => {
  const jsonResponse = await parseJson(response);
  const data = jsonResponse as TReturnData | NetsErrorEnvelope;

  if (isError(data)) {
    const error = new Error(data.error['error-text']) as Error & {
      response: Response;
    };
    error.response = response;
    throw error;
  }

  return data;
};

const netsApi = ({ baseUrl }: IApiOptions) => {
  const request = async <TReturnData>(
    method: 'POST',
    endpoint: string,
    data: any,
    options: Partial<IRequestOptions<TReturnData>> = {}
  ): Promise<TReturnData> => {
    const url = `${baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method,
      body: data ? JSON.stringify(data) : null,
    });

    if (response.status >= 200 && response.status < 300) {
      return options.responseHandler
        ? options.responseHandler(response)
        : netsResponseHandler<TReturnData>(response);
    }

    const error = new Error(response.statusText) as Error & {
      response: Response;
    };
    error.response = response;
    throw error;
  };

  const post = async <TReturnData>(
    action: string,
    token?: string,
    params?: object
  ) =>
    request<TReturnData>(
      'POST',
      'book/netsApi.cse',
      netsApiEnvelope(action, token, params)
    );

  return {
    getToken: async () => post<GetTokenResponse>('getToken'),

    startEmbedded: async (token: string, params: StartEmbeddedParams) =>
      post<StartEmbeddedResponse>('startEmbedded', token, params),
  };
};

/**
 * POST
{
    "action":"getToken"
}
  SVAR
  {"token":"xyz"}

 * POST
  {
    "action":"startEmbedded",
    "token":"xyz",
    "platform":"webbook",
    "params":{
        "url":"http://jptest.jena.jetpak.com/book/#content-top",
        "consignmentid":"120769582",
        "ref":"JET-11362112",
        "consumer":{"email":"anders.ohman@softronic.se","phoneNbr":""},
        "orderItem":{"priceExlVat":"1823.29","priceIncVat":"2279.11"}
      }
  }
  SVAR
{
  "PAYMENTID":"00400000636370a6991a22d9713f0f29",
  "CK":"test-checkout-key-67af762691564a75b267e5fe98319e51",
  "result":"ok"
  }
 */

export default netsApi;
