import IAddress from '../../../Models/Address';
import {
  EventActionType,
  createDataEvent,
  createEvent,
} from '../../../helpers/eventCreator';

export const AddressbookEvents = {
  changeQuery: (val: string) => createDataEvent('CHANGE_QUERY', val),
  fetch: () => createEvent('FETCH'),
  removeAddress: (val: IAddress) => createDataEvent('REMOVE_ADDRESS', val),
};

export type TAddressbookEvents = EventActionType<typeof AddressbookEvents>;
