import { useMemo } from 'react';
import { bindEvents, EventActionType } from '../helpers/eventCreator';

/**
 * Binds an event creator object to the supplied xstate send
 * @param eventCreator event creator object
 * @param send xstate send
 */
export const useBoundEvents = <
  TEventMap extends Record<any, any>
  // K extends keyof TEventMap
>(
  eventCreator: TEventMap,
  send: (event: EventActionType<TEventMap>) => void
) => useMemo(() => bindEvents(eventCreator, send), [send, eventCreator]);
