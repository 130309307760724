import usePlacesAutocomplete from 'use-places-autocomplete';

// HACK to allow multiple init map functions for when gmaps has loaded
const callbacks: string[] = [];
const initFunc = () => callbacks.map((c) => (window as any)[c]());
(window as any).initMap = initFunc;

const useAddressAutocomplete = (id: string) => {
  const {
    ready,
    // value,
    suggestions,
    setValue,
    // clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
    },
    callbackName: `initMap${id}`,
    debounce: 300,
  });
  callbacks.push(`initMap${id}`);

  return { ready, suggestions, search: setValue };
};

export default useAddressAutocomplete;
