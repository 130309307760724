import { MachineConfig } from 'xstate';
import { SendPackageContext } from '../../Machines/sendPackageMachine';
import virtualPageview from '../../virtualPageview';

export const OrderConfirmationMachine: MachineConfig<
  SendPackageContext,
  any,
  any
> = {
  id: 'orderConfirmation',
  initial: 'init',
  states: {
    init: {
      entry: [
        () => virtualPageview('/order-confirmation', 'Order confirmation'),
      ],
    },
  },
};
