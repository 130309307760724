/** @jsxImportSource @emotion/react */
import { FocusEventHandler, Fragment } from 'react';
import { css } from 'twin.macro';
import { FormLabel } from '../FormFields';
import FormMessage from '../Base/FormMessage';
import tw from 'twin.macro';
export interface IFormOption {
  label: string;
  value: string;
}

export interface IFormSelectProps {
  name: string;
  label?: string;
  defaultValue?: string;
  value: string;
  onChange: (value: string) => void;
  options?: IFormOption[];
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onBlur?: FocusEventHandler;
  children?: React.ReactNode | React.ReactNode[];
}

const FormSelect = ({
  name,
  label,
  value,
  defaultValue,
  onChange,
  options,
  error,
  errorMessage,
  onBlur,
  required,
  disabled = false,
  autoFocus,
  children,
}: IFormSelectProps) => {
  return (
    <Fragment>
      {label && (
        <FormLabel name={name} required={required}>
          {label}
        </FormLabel>
      )}
      <div tw={'flex flex-col w-full'}>
        <div tw={'relative'}>
          <select
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            id={name}
            aria-invalid={error}
            defaultValue={defaultValue}
            value={value}
            autoFocus={autoFocus}
            disabled={disabled}
            css={[
              tw`block appearance-none w-full border border-gray-400 px-2 pr-8 rounded text-base leading-10 bg-white focus:border-gray-200`,
              error && tw`border-red border`,
              error &&
                css`
                  transition: all 250ms ease 0s;
                  &:focus {
                    border-color: rgb(215 53 44 / 82%);
                    box-shadow: 0 1px 1px rgb(215 53 44 / 14%) inset,
                      0 0 8px rgb(215 53 44 / 39%);
                    outline: 0 none;
                  }
                `,
              disabled && tw`bg-gray-300 cursor-not-allowed`,
            ]}
          >
            {options?.map((option, id) => (
              <option key={id} value={option.value}>
                {option.label}
              </option>
            ))}
            {children}
          </select>
          <div
            tw={
              'pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'
            }
          >
            <svg
              tw={'fill-current h-4 w-4'}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </Fragment>
  );
};

export default FormSelect;
