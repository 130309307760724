/** @jsxImportSource @emotion/react */
import tw, { TwStyle } from 'twin.macro';
import { ClassNames, SerializedStyles } from '@emotion/react';
import {
  TransitionProps,
  TransitionStatus,
} from 'react-transition-group/Transition';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';

type PageTransitionStyle = 'left' | 'right' | 'opacity';
type PageTransitionProps = Omit<TransitionProps, 'timeout'> & {
  transitionStyle?: PageTransitionStyle;
};
type Style = SerializedStyles | TwStyle;
type Styles = Style | Style[];

const opacity: Record<TransitionStatus | 'base', Styles> = {
  base: tw`transition-opacity motion-safe:duration-700 ease-out flex flex-col flex-grow left-0 right-0`,
  entered: tw`opacity-100 `,
  entering: tw`opacity-0 `,
  exited: tw`hidden`,
  exiting: tw`opacity-0 `,
  unmounted: tw`hidden`,
};
const right: Record<TransitionStatus | 'base', Styles> = {
  base: tw`translate-x-0 transform transition-transform motion-safe:duration-700 flex flex-col flex-grow left-0 right-0`,
  entered: tw`translate-x-0 static`,
  entering: tw`-translate-x-full absolute`,
  exited: tw`hidden`,
  exiting: tw`translate-x-full absolute`,
  unmounted: tw`hidden`,
};
const left: Record<TransitionStatus | 'base', Styles> = {
  base: tw`translate-x-0 transform transition-transform motion-safe:duration-700 flex flex-col flex-grow left-0 right-0`,
  entered: tw`translate-x-0 static`,
  entering: tw`translate-x-full absolute`,
  exited: tw`hidden`,
  exiting: tw`-translate-x-full absolute`,
  unmounted: tw`hidden`,
};
const getStyles = (style: PageTransitionStyle) => {
  switch (style) {
    case 'left':
      return left;
    case 'right':
      return right;
    case 'opacity':
      return opacity;
  }
};
const getTimings = (style: PageTransitionStyle) => {
  switch (style) {
    case 'left':
    case 'right':
      return { enter: 0, exit: 500 };
    case 'opacity':
      return 0;
  }
};

export const PageTransition = ({
  transitionStyle = 'opacity',
  children,
  ...rest
}: PageTransitionProps) => {
  const styles = getStyles(transitionStyle);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Transition
      timeout={getTimings(transitionStyle)}
      {...rest}
      mountOnEnter={true}
      nodeRef={ref}
    >
      {(state) => (
        <ClassNames>
          {({ css }) => (
            <div ref={ref} className={css([styles.base, styles[state]])}>
              {children}
            </div>
          )}
        </ClassNames>
      )}
    </Transition>
  );
};
