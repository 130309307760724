import { AdviseMethod, AdviseType } from './jena/request/BookDefRequest';
import { v4 as uuidv4 } from 'uuid';

export interface Advise {
  type: AdviseType;
  method: AdviseMethod;
  value?: string;
  id: string;
  required?: boolean;
}

export const defaultAdvise: Advise = {
  type: AdviseType.BookingConfirmation,
  method: AdviseMethod.Email,
  value: '',
  id: uuidv4(),
};

export const defaultAdvises: Advise[] = [
  {
    type: AdviseType.BookingConfirmation,
    method: AdviseMethod.Email,
    value: '',
    id: uuidv4(),
    required: true,
  },
];
