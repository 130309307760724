/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import tw from 'twin.macro';
import { PackageActions } from '../packageReducer';
import { Parcel } from '../../../Models/Parcel';
import { EventActionType } from '../../../helpers/eventCreator';
import { Button } from '../../../Components/Base';
import useTranslation from '../../../hooks/useTranslation';
import { Translations } from '../../../translations/TranslationType';
import { boxShadow } from '../../../Components/Base/BoxShadow';
import FormMessage from '../../../Components/Base/FormMessage';
import { Package } from './Package';
import { TransitionGroup } from 'react-transition-group';
import { packageValid } from '../deliveryMachine';
import { Packagetype } from '../../../Models/jena/request/ListPackageTypesResponse';
import { IconTrash } from '../../../svg';

interface PackagesProps {
  packages: Parcel[];
  packageTypes: Packagetype[];
  updatePackages: (action: EventActionType<typeof PackageActions>) => void;
  errors?: ('excessiveWeight' | 'excessiveVolume' | 'invalidPackages')[];
}

export const Packages = ({
  packages,
  updatePackages,
  packageTypes,
  errors,
}: PackagesProps) => {
  const translation: Translations = useTranslation();
  const handleChange = (
    p: Partial<Parcel> & Pick<Parcel, 'id'>,
    source: string
  ): void => {
    if (source === 'weight') {
      updatePackages(PackageActions.splitUp(p));
    } else {
      updatePackages(PackageActions.change(p));
    }
  };

  const handleRemove = (id: Parcel['id']): void => {
    updatePackages(PackageActions.remove(id));
  };
  const addPackage = () => {
    updatePackages(PackageActions.add());
  };
  const handleClearPackages = () => {
    updatePackages(PackageActions.clear());
  };

  return (
    <Fragment>
      <hr css={tw`border-t border-gray-200`} />

      <div tw={'py-12'}>
        <div tw={'container mx-auto px-4'}>
          <div tw={'row'}>
            <h2 css={tw`sr-only`}>{translation.addPackage}</h2>
          </div>
          <div css={[tw`overflow-hidden`, boxShadow]}>
            <div tw={'row'}>
              <div tw={'col-12 relative'}>
                <div tw=" flex flex-row justify-between flex-wrap w-full border bg-white py-3 px-4 items-center">
                  <h3 css={[tw`font-heading font-bold text-lg`]}>
                    {translation.yourPackages}
                  </h3>
                  <button
                    className="group"
                    tw={
                      'mt-auto inline-flex items-center px-3 py-3 ml-auto text-sm text-darkred hover:(bg-gray-100) rounded-sm'
                    }
                    onClick={handleClearPackages}
                    type="button"
                  >
                    <IconTrash tw="w-5 h-5 stroke-2 stroke-current mr-1" />
                    {translation.clear}
                  </button>
                </div>
              </div>
              <div tw={'col-12'}>
                <TransitionGroup>
                  {packages.map((parcel: Parcel, i) => (
                    <Package
                      // @ts-ignore
                      key={parcel.id}
                      id={parcel.id}
                      quantity={parcel.quantity}
                      weight={parcel.weight}
                      width={parcel.width}
                      height={parcel.height}
                      length={parcel.length}
                      parcelType={parcel.parcelType}
                      handleChange={handleChange}
                      handleRemove={handleRemove}
                      error={!packageValid(parcel)}
                      packageTypes={packageTypes}
                    />
                  ))}
                </TransitionGroup>
              </div>
            </div>
            {errors &&
              errors.map((error) => (
                <div
                  tw="border border-t-0 bg-white p-4"
                  key={`packages.errors.${error}`}
                >
                  <FormMessage
                    label={translation.deliveryPage[error]}
                    status="error"
                    textStyle="text-base"
                  />
                  <p>
                    {translation.helpPage.supportNumber}{' '}
                    <a href={`tel:${translation.meta.phone.number}`}>
                      {translation.meta.phone.label}
                    </a>
                  </p>
                </div>
              ))}
          </div>
          <div tw={'row'}>
            <div tw={'col-12'}>
              <div css={tw`w-full flex justify-between mt-4`}>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={addPackage}
                  disabled={packages.length >= 100}
                  type="button"
                >
                  <span aria-hidden="true" tw="mr-1">
                    +
                  </span>
                  {translation.addMorePackages}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
