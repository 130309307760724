import {
  createMachine,
  assign,
  ConditionPredicate,
  DoneInvokeEvent,
} from 'xstate';
import { enc, SHA256 } from 'crypto-js';
import { getUserCountryId } from '../../mappers/jenaMappers';
import { GetRegistrationResponse } from '../../Models/jena/response/GetRegistrationResponse';
import { SaveRegistrationResponse } from '../../Models/jena/response/SaveRegistrationResponse';
import { PartnerInformation } from '../../Models/PartnerInformation';
//import { LoginResponse } from '../../Models/jena/response/LoginResponse';
import { TRegisterEvents } from './RegisterEvents';

export interface IRegisterContext {
  orgId?: string;
  customerType?: 'company' | 'private';
  existing?: boolean;
  orgNumber: string;
  orgName: string;
  vatNumber: string;
  customerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  country: string;
  newAccount?: {
    customerNumber: string;
    userName: string;
    password: string;
    email: string;
  };
  error?: any;
  //user?: LoginResponse;
  partnerInfo?: PartnerInformation;
}

type TRegisterGuards<G extends string> = Record<
  G,
  ConditionPredicate<IRegisterContext, TRegisterEvents>
>;

export type TRegisterGuard =
  | 'invalidCustomerType'
  | 'invalidOrgNumber'
  | 'invalidOrgName'
  | 'invalidVatNumber'
  | 'invalidCustomerNumber'
  | 'invalidFirstName'
  | 'invalidLastName'
  | 'invalidEmail'
  | 'invalidphone'
  // | 'invalidPhone2'
  | 'invalidStreet'
  | 'invalidStreetNumber'
  | 'invalidCity'
  | 'invalidPostalCode'
  | 'invalidCountry'
  | 'validRegistration'
  | 'validStep1';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const RegisterGuards: TRegisterGuards<TRegisterGuard> = {
  invalidCustomerType: (ctx) => !minLength(ctx.customerType),
  invalidOrgNumber: (ctx) => !minLength(ctx.orgNumber, 6),
  invalidOrgName: (ctx) => !minLength(ctx.orgName),
  invalidVatNumber: (ctx) => !minLength(ctx.vatNumber, 8),
  invalidCustomerNumber: (ctx) => !/^[\d]{5,12}$/.test(ctx.customerNumber),
  invalidFirstName: (ctx) => !minLength(ctx.firstName),
  invalidLastName: (ctx) => !minLength(ctx.lastName),
  invalidEmail: (ctx) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ctx.email),
  invalidphone: (ctx) => !minLength(ctx.phone, 6),
  invalidStreet: (ctx) => !minLength(ctx.street),
  invalidStreetNumber: (ctx) => !minLength(ctx.streetNumber, 1),
  invalidCity: (ctx) => !minLength(ctx.city),
  invalidPostalCode: (ctx) => !minLength(ctx.postalCode),
  invalidCountry: (ctx) => !minLength(ctx.country, 1),
  validStep1: (ctx, event, meta) =>
    [
      RegisterGuards.invalidCustomerType,
      ...(ctx.customerType === 'company'
        ? ctx.existing === true
          ? [RegisterGuards.invalidCustomerNumber]
          : [RegisterGuards.invalidOrgNumber]
        : []),
    ].reduce((acc: boolean, fn) => acc && !fn(ctx, event, meta), true),
  validRegistration: (ctx, event, meta) =>
    [
      RegisterGuards.invalidFirstName,
      RegisterGuards.invalidLastName,
      RegisterGuards.invalidEmail,
      RegisterGuards.invalidphone,
      ...(ctx.existing === false
        ? [
            RegisterGuards.invalidStreet,
            RegisterGuards.invalidStreetNumber,
            RegisterGuards.invalidCity,
            RegisterGuards.invalidPostalCode,
            RegisterGuards.invalidCountry,
            ...(ctx.customerType === 'company'
              ? [RegisterGuards.invalidOrgName, RegisterGuards.invalidVatNumber]
              : []),
          ]
        : []),
    ].reduce((acc: boolean, fn) => {
      return acc && !fn(ctx, event, meta);
    }, true),
};

const inputStates = (invalidGuard?: TRegisterGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const registerMachine = createMachine<IRegisterContext, TRegisterEvents>(
  {
    id: 'register',
    initial: 'init',
    context: {
      orgNumber: '',
      orgName: '',
      vatNumber: '',
      customerNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phone2: '',
      street: '',
      streetNumber: '',
      city: '',
      postalCode: '',
      country: getUserCountryId().toString(),
      partnerInfo: undefined,
    },
    states: {
      init: {
        invoke: {
          id: 'getCustomerTransportInfo',
          src: 'getCustomerTransportInfo',
          onDone: {
            target: 'step1',
            actions: assign({
              partnerInfo: (_, { data }) => data,
            }),
          },
          onError: 'failed',
        },
      },
      step1: {
        initial: 'editing',
        states: {
          editing: {
            type: 'parallel',
            states: {
              customerType: inputStates(),
              existing: inputStates(),
              orgNumber: inputStates('invalidOrgNumber'),
              customerNumber: inputStates('invalidCustomerNumber'),
              failed: {},
            },
            on: {
              CHANGE_CUSTOMER_TYPE: [
                {
                  actions: assign({
                    customerType: (_, { data }): 'company' | 'private' => data,
                    existing: (_, event) => false,
                  }),
                  target: '#register.step2',
                  cond: (_, event) => event.data === 'private',
                },
                {
                  actions: assign({
                    customerType: (_, { data }): 'company' | 'private' => data,
                  }),
                  target: '#register.step1.editing.customerType.edit',
                },
              ],
              CHANGE_ORG_NUMBER: {
                actions: assign({
                  orgNumber: (_, event): string => event.data,
                }),
                target: '#register.step1.editing.orgNumber.edit',
              },
              CHANGE_CUSTOMER_NUMBER: {
                actions: assign({
                  customerNumber: (_, event) => event.data,
                  error: (_, event) => '',
                }),
                target: '#register.step1.editing.customerNumber.edit',
              },
              CHANGE_IS_EXISTING: {
                actions: assign({
                  existing: (_, event) => event.data,
                  error: (_, event) => '',
                }),
                target: '#register.step1.editing.existing.edit',
              },
            },
          },
          failed: {
            on: {
              ERROR_OK: 'editing',
            },
          },
        },
        on: {
          CONTINUE: [
            {
              cond: (ctx) => ctx.customerType === 'company',
              target: '#register.getRegistration',
            },
            {
              target: '#register.step2',
            },
          ],
        },
      },
      step2: {
        initial: 'editing',
        states: {
          editing: {
            type: 'parallel',
            states: {
              orgName: inputStates('invalidOrgName'),
              orgNumber: inputStates('invalidOrgNumber'),
              vatNumber: inputStates('invalidVatNumber'),
              firstName: inputStates('invalidFirstName'),
              lastName: inputStates('invalidLastName'),
              email: inputStates('invalidEmail'),
              phone: inputStates('invalidphone'),
              phone2: {
                initial: 'valid',
                states: {
                  edit: {},
                  valid: {},
                },
              },
              street: inputStates('invalidStreet'),
              streetNumber: inputStates('invalidStreetNumber'),
              city: inputStates('invalidCity'),
              postalCode: inputStates('invalidPostalCode'),
              country: inputStates('invalidCountry'),
            },
            on: {
              CHANGE_ORG_NUMBER: {
                actions: assign({
                  orgNumber: (_, event): string => event.data,
                }),
                target: '#register.step2.editing.orgNumber.edit',
              },
              CHANGE_ORG_NAME: {
                actions: assign({
                  orgName: (_, event) => event.data,
                }),
                target: '#register.step2.editing.orgName.edit',
              },
              CHANGE_VAT_NUMBER: {
                actions: assign({
                  vatNumber: (_, event) => event.data,
                }),
                target: '#register.step2.editing.vatNumber.edit',
              },
              CHANGE_FIRST_NAME: {
                actions: assign({
                  firstName: (_, event) => event.data,
                }),
                target: '#register.step2.editing.firstName.edit',
              },
              CHANGE_LAST_NAME: {
                actions: assign({
                  lastName: (_, event) => event.data,
                }),
                target: '#register.step2.editing.lastName.edit',
              },
              CHANGE_EMAIL: {
                actions: assign({
                  email: (_, event) => event.data,
                }),
                target: '#register.step2.editing.email.edit',
              },
              CHANGE_PHONE: {
                actions: assign({
                  phone: (_, event) => event.data,
                }),
                target: '#register.step2.editing.phone.edit',
              },
              CHANGE_PHONE2: {
                actions: assign({
                  phone2: (_, event) => event.data,
                }),
                target: '#register.step2.editing.phone2.edit',
              },
              CHANGE_STREET: {
                actions: assign({
                  street: (_, event) => event.data,
                }),
                target: '#register.step2.editing.street.edit',
              },
              CHANGE_STREET_NUMBER: {
                actions: assign({
                  streetNumber: (_, event) => event.data,
                }),
                target: '#register.step2.editing.streetNumber.edit',
              },
              CHANGE_CITY: {
                actions: assign({
                  city: (_, event) => event.data,
                }),
                target: '#register.step2.editing.city.edit',
              },
              CHANGE_POSTAL_CODE: {
                actions: assign({
                  postalCode: (_, event) => event.data,
                }),
                target: '#register.step2.editing.postalCode.edit',
              },
              CHANGE_COUNTRY: {
                actions: assign({
                  country: (_, event) => event.data,
                }),
                target: '#register.step2.editing.country.edit',
              },
              CONTINUE: '#register.register',
              PREVIOUS: {
                actions: assign({
                  error: (_, event) => '',
                }),
                target: '#register.step1',
              },
            },
          },
          failed: {
            on: {
              ERROR_OK: 'editing',
            },
          },
        },
      },
      getRegistration: {
        invoke: {
          src: 'getRegistration',
          onDone: {
            target: 'step2',
            actions: assign(
              (_, event: DoneInvokeEvent<GetRegistrationResponse>) => {
                return {
                  orgId: event.data.customerorganization.custorgid,
                };
              }
            ),
          },
          onError: {
            target: '#register.step1.editing',
            actions: assign((_, event: any) => ({
              error: event.data.message,
            })),
          },
        },
      },
      register: {
        invoke: {
          src: 'register',
          onDone: {
            target: 'registered',
            actions: [
              assign((_, event: DoneInvokeEvent<SaveRegistrationResponse>) => {
                return {
                  newAccount: {
                    customerNumber: event.data.accountnbr,
                    userName: event.data.username,
                    password: event.data.userpwd,
                    email: event.data.email,
                  },
                };
              }),
              (ctx, event: DoneInvokeEvent<SaveRegistrationResponse>) => {
                if (window.dataLayer) {
                  const mixpanelData = {
                    accountType:
                      ctx.customerType === 'company' ? 'business' : 'private', // input business or private dependent on selection
                    email: ctx.email,
                    emailSHA256: SHA256(ctx.email).toString(enc.Hex), // SHA256 hashed value of the email
                    firstName: ctx.firstName,
                    lastName: ctx.lastName,
                    phoneNo1: ctx.phone,
                    phoneNo2: ctx.phone2,
                    vatID: '',
                    internalAccountNo: event.data.accountnbr,
                  };
                  if (
                    ctx.customerType === 'company' &&
                    ctx.existing === false
                  ) {
                    window.dataLayer.push({
                      event: 'accountCreated',
                      ...mixpanelData,
                      country: ctx.country,
                      countryCode: ctx.country,
                      city: ctx.city,
                      zipCode: ctx.postalCode,
                      businessRegistrationNo: ctx.orgNumber, // leave without value if not applicable
                      companyName: ctx.orgName, // leave without value if not applicable
                      internalAccountNo: event.data.accountnbr, // leave without value if not applicable
                    });
                  } else {
                    window.dataLayer.push({
                      event: 'userCreated',
                      ...mixpanelData,
                    });
                  }
                }
              },
            ],
          },
          onError: {
            target: '#register.step2.editing',
            actions: assign((_, event: any) => ({
              error: event.data.message,
            })),
          },
        },
      },
      registered: {
        type: 'final',
      },
      failed: {},
    },
  },
  {
    guards: {
      ...RegisterGuards,
    },
  }
);

export default registerMachine;
