/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { Logo } from '../../svg';
import Register from './Components/Register';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import { Fragment } from 'react';
const RegisterPage = () => {
  const translations: Translations = useTranslation();

  return (
    <Fragment>
      <div css={tw`row`}>
        <div css={tw`col-12`}>
          <Logo
            css={[
              css`
                max-width: 25%;
              `,
              tw`mb-4 mx-auto`,
            ]}
          />
          <h2
            css={tw`font-heading font-semibold text-3xl mb-4 leading-tight text-center`}
          >
            {translations.registerPage.title}
          </h2>
        </div>
      </div>
      <div css={tw`row `}>
        <div tw={'col-12'}>
          <Register />
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterPage;
