/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import DeliveryOption from '../../../../Models/DeliveryOption';
import useTranslation from '../../../../hooks/useTranslation';
import { Translations } from '../../../../translations/TranslationType';
import { IconCheckmark } from '../../../../svg';
import { Dots } from '../../../../Components/Base/Dots';
import { Currency } from '../../../../enums/Currency';
import ConfigContext from '../../../../context/ConfigContext';
import { useContext } from 'react';
import { formatRelativeDate } from '../../../../helpers/dateSelectGenerator';

interface DeliveryAlternativeProps {
  key: number;
  deliveryAlternative: DeliveryOption;
  handleSelected: (option: DeliveryOption) => void;
  selectedId: string | undefined;
}

interface DeliveryLabels {
  pickup: string;
  delivery: string;
  pickupEarliest: string;
  deliveryLatest: string;
  price: string;
  currency: Currency;
  withVat: string;
  select?: string;
  selected?: string;
}

const DeliveryAlternativesDetailsMobile = ({
  deliveryAlternative: {
    AlternativeId: id,
    PickupDateTime: pickupTime,
    DeliveryDateTime: deliveryTime,
    OriginalPriceExcludingVAT: priceExcludingVAT,
    OriginalPriceIncludingVAT: priceIncludingVAT,
    Currency: currency,
  },
  deliveryAlternative: alternative,
  selectedId,
  handleSelected,
}: DeliveryAlternativeProps) => {
  const { language } = useContext(ConfigContext);
  const translation: Translations = useTranslation();
  const selected = id === selectedId;
  const deliveryLabels: DeliveryLabels = {
    pickup: translation.pickUp,
    delivery: translation.delivery,
    pickupEarliest: translation.earliestPickup,
    deliveryLatest: translation.latestDelivery,
    price: translation.price,
    currency: Currency.SEK,
    withVat: translation.withVat,
    selected: translation.selected,
  };

  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  });

  return (
    <div
      css={[
        tw`row border-t hover:bg-green-100 focus:bg-green-100 cursor-pointer`,
        selected ? tw`bg-green-200 hover:bg-green-200` : null,
      ]}
      onClick={() => {
        handleSelected(alternative);
      }}
    >
      <div tw={'col-6 py-4 pl-8 sm:pl-12'}>
        <Dots selected={selected} />
        <div>
          <span tw={'block text-sm text-gray-700'}>
            {deliveryLabels.pickupEarliest}
          </span>
          <time tw={'block sm:text-lg mb-6'}>
            {' '}
            {formatRelativeDate(language, pickupTime)}
          </time>
          <span tw={'block text-sm text-gray-700'}>
            {' '}
            {deliveryLabels.deliveryLatest}
          </span>
          <time tw={'block sm:text-lg'}>
            {formatRelativeDate(language, deliveryTime)}
          </time>
        </div>
      </div>
      <div tw={'flex flex-col items-center justify-center col-6 border-l py-4'}>
        <div tw={'-mt-4 text-center'}>
          <span tw={'block text-lg'}>
            {numberFormat.format(priceExcludingVAT)}
          </span>
          <span tw={'block text-sm text-gray-700'}>
            {numberFormat.format(priceIncludingVAT)} {deliveryLabels.withVat}
          </span>
        </div>
        {selected && (
          <div
            css={[
              tw`absolute flex`,
              css`
                bottom: 1.25rem;
              `,
            ]}
          >
            <IconCheckmark tw={'w-5 h-5 mr-1 text-green-900 fill-current'} />
            <span tw={'text-green-900 leading-tight'}>
              {deliveryLabels.selected}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryAlternativesDetailsMobile;
