import { assign, ConditionPredicate, createMachine } from 'xstate';
import { TResetPasswordEvents } from './ResetPasswordEvents';

interface ResetPasswordContext {
  username: string;
  error?: any;
}

type TResetPasswordGuards<G extends string> = Record<
  G,
  ConditionPredicate<ResetPasswordContext, TResetPasswordEvents>
>;

export type TResetPasswordGuard = 'invalidUsername' | 'validResetPassword';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const ResetPasswordGuards: TResetPasswordGuards<TResetPasswordGuard> = {
  invalidUsername: (ctx) => !minLength(ctx.username),
  validResetPassword: (ctx, event, meta) =>
    [ResetPasswordGuards.invalidUsername].reduce((acc: boolean, fn) => {
      return acc && !fn(ctx, event, meta);
    }, true),
};

const inputStates = (invalidGuard?: TResetPasswordGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const resetPasswordMachine = createMachine<
  ResetPasswordContext,
  TResetPasswordEvents
>({
  id: 'resetPassword',
  initial: 'editing',
  context: {
    username: '',
  },
  states: {
    editing: {
      type: 'parallel',
      states: {
        init: {},
        username: inputStates('invalidUsername'),
      },
      on: {
        CHANGE_USERNAME: {
          actions: assign({
            username: (_, event): string => event.data,
          }),
        },
        RESET_PASSWORD: 'loading',
      },
    },
    loading: {
      invoke: {
        src: 'resetPasswordRequest',
        onDone: {
          target: 'successful',
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },
    successful: {
      on: {
        RESET_PASSWORD: 'loading',
      },
    },
    failed: {
      on: {
        CHANGE_USERNAME: {
          actions: assign({
            username: (context, event): string => event.data,
          }),
        },
        RESET_PASSWORD: 'loading',
      },
    },
  },
});

export default resetPasswordMachine;
