import React from 'react';
import { getGeocode, Suggestion } from 'use-places-autocomplete';
import AddressAutocomplete, {
  AddressAutocompleteProps,
} from '../../../../Components/AddressAutocomplete/AddressAutocomplete';
import IAddress from '../../../../Models/Address';
import { googleGeocodeToAddress } from '../../../../mappers/googleGeocodeToAddress';

type GeocodedAutocompleteProps = Omit<AddressAutocompleteProps, 'onSelect'> & {
  onSelect: (address?: IAddress) => void;
};
const repairAddress = (
  suggestion: Suggestion,
  place: google.maps.GeocoderResult
) => {
  let streetNumberMatches =
    suggestion?.structured_formatting?.main_text?.match(/(\d+)/);
  const street_number = streetNumberMatches
    ? streetNumberMatches[1]
    : undefined;
  if (street_number) {
    place.address_components.splice(0, 0, {
      long_name: street_number,
      short_name: street_number,
      types: ['street_number'],
    });
    const formatted_address_split = place.formatted_address.split(',');
    formatted_address_split[0] = `${formatted_address_split[0]} ${street_number}`;
    place.formatted_address = formatted_address_split.join(',');
  }
};
const GeocodedAutocomplete = ({
  onSelect,
  ...rest
}: GeocodedAutocompleteProps) => {
  const geocode = (suggestion?: Suggestion) => {
    if (suggestion === undefined) onSelect();
    else {
      getGeocode({
        address: suggestion?.description,
      })
        .then((results) => {
          if (
            results[0].address_components.findIndex((x) =>
              x.types.find((y) => y === 'street_number')
            ) === -1
          )
            repairAddress(suggestion, results[0]);

          return googleGeocodeToAddress(results[0]);
        })
        .then((r) => {
          onSelect(r);
        });
    }
  };
  return <AddressAutocomplete onSelect={geocode} {...rest} />;
};

export default GeocodedAutocomplete;
