import {
  createDataEvent,
  EventActionType,
  createEvent,
} from '../../helpers/eventCreator';

export const RegisterEvents = {
  changeCustomerType: (type: 'company' | 'private') =>
    createDataEvent('CHANGE_CUSTOMER_TYPE', type),
  changeIsExsting: (existing: boolean) =>
    createDataEvent('CHANGE_IS_EXISTING', existing),
  changeOrgNumber: (val: string) => createDataEvent('CHANGE_ORG_NUMBER', val),
  changeOrgName: (val: string) => createDataEvent('CHANGE_ORG_NAME', val),
  changeVatNumber: (val: string) => createDataEvent('CHANGE_VAT_NUMBER', val),
  changeCustomerNumber: (val: string) =>
    createDataEvent('CHANGE_CUSTOMER_NUMBER', val),
  changeFirstName: (val: string) => createDataEvent('CHANGE_FIRST_NAME', val),
  changeLastName: (val: string) => createDataEvent('CHANGE_LAST_NAME', val),
  changeEmail: (val: string) => createDataEvent('CHANGE_EMAIL', val),
  changePhone: (val: string) => createDataEvent('CHANGE_PHONE', val),
  changePhone2: (val: string) => createDataEvent('CHANGE_PHONE2', val),
  changeStreet: (val: string) => createDataEvent('CHANGE_STREET', val),
  changeStreetNumber: (val: string) =>
    createDataEvent('CHANGE_STREET_NUMBER', val),
  changeCity: (val: string) => createDataEvent('CHANGE_CITY', val),
  changePostalCode: (val: string) => createDataEvent('CHANGE_POSTAL_CODE', val),
  changeCountry: (val: string) => createDataEvent('CHANGE_COUNTRY', val),
  getRegistration: () => createEvent('GET_REGISTRATION'),
  continue: () => createEvent('CONTINUE'),
  previous: () => createEvent('PREVIOUS'),
  errorOk: () => createEvent('ERROR_OK'),
};

// send(CustomsEvents.changeCustomsValue(parseFloat(val)));

export type TRegisterEvents = EventActionType<typeof RegisterEvents>;
