import { GeocodeResult } from 'use-places-autocomplete';
import IAddress from '../Models/Address';
import { Country } from '../enums/countryCodes';

export const googleGeocodeToAddress = (place: GeocodeResult) => {
  const address: IAddress = place.address_components.reduce(
    (address: any, component: any) => {
      const type = component.types.find((t: string) => t !== 'political');
      switch (type) {
        case 'route':
          address.street = component.long_name;
          break;
        case 'country':
          address.country = component.long_name;
          address.countryCode = component.short_name as keyof typeof Country;
          break;
        case 'street_number':
          address.streetNumber = component.long_name;
          break;
        case 'postal_town':
        case 'sublocality_level_1':
        case 'sublocality_level_2':
        case 'sublocality':
        case 'locality':
        case 'neighborhood':
          address.city = component.long_name;
          break;
      }
      return address;
    },
    {} as IAddress
  );
  // fallback for addresses without a street name!
  if (!address.street) {
    const street = place.address_components.find((x) =>
      x.types.some(
        (y) =>
          y === 'establishment' ||
          y === 'point_of_interest' ||
          y === 'transit_station'
      )
    )?.long_name;
    if (street) address.street = street;
  }
  // fallback for addresses without a city!
  if (!address.city) {
    const city = place.address_components.find((x) =>
      x.types.some(
        (y) => y === 'administrative_area_level_2' || y === 'political'
      )
    )?.long_name;
    if (city) address.city = city;
  }

  address.formattedAddress = place.formatted_address;
  return address;
};
