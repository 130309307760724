import { assign, ConditionPredicate, createMachine } from 'xstate';
import { TSetPasswordEvents } from './SetPasswordEvents';

interface SetPasswordContext {
  password: string;
  password2: string;
  error?: any;
}

type TSetPasswordGuards<G extends string> = Record<
  G,
  ConditionPredicate<SetPasswordContext, TSetPasswordEvents>
>;

export type TSetPasswordGuard =
  | 'invalidPassword'
  | 'invalidPassword2'
  | 'passwordsNotEqual'
  | 'validSetPassword';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const SetPasswordGuards: TSetPasswordGuards<TSetPasswordGuard> = {
  invalidPassword: (ctx) => !minLength(ctx.password),
  invalidPassword2: (ctx) => !minLength(ctx.password2),
  passwordsNotEqual: (ctx) => ctx.password !== ctx.password2,
  validSetPassword: (ctx, event, meta) =>
    [
      SetPasswordGuards.invalidPassword,
      SetPasswordGuards.invalidPassword2,
      SetPasswordGuards.passwordsNotEqual,
    ].reduce((acc: boolean, fn) => {
      return acc && !fn(ctx, event, meta);
    }, true),
};

const inputStates = (invalidGuard?: TSetPasswordGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const setPasswordMachine = createMachine<
  SetPasswordContext,
  TSetPasswordEvents
>({
  id: 'resetPassword',
  initial: 'editing',
  context: {
    password: '',
    password2: '',
  },
  states: {
    editing: {
      type: 'parallel',
      states: {
        password: inputStates('invalidPassword'),
        password2: inputStates('invalidPassword2'),
      },
      on: {
        CHANGE_PASSWORD: {
          actions: assign({
            password: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD2: {
          actions: assign({
            password2: (_, event) => event.data,
          }),
        },
        SET_PASSWORD: 'loading',
      },
    },
    loading: {
      invoke: {
        src: 'setPasswordRequest',
        onDone: {
          target: 'successful',
          actions: ['updateUser'],
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },

    successful: {
      on: {
        SET_PASSWORD: 'loading',
      },
    },
    failed: {
      on: {
        CHANGE_PASSWORD: {
          actions: assign({
            password: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD2: {
          actions: assign({
            password2: (_, event) => event.data,
          }),
        },
        SET_PASSWORD: 'loading',
      },
    },
  },
});

export default setPasswordMachine;
