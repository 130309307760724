/** @jsxImportSource @emotion/react */
import tw, { css, theme, styled } from 'twin.macro';
import FormMessage from '../Base/FormMessage';

const RadioButton = styled.input(() => [
  tw`sr-only`,
  css`
    &:checked + label:before {
      border-color: ${theme`colors.darkred`};
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  `,
]);

const Label = styled.label(({ disabled }: { disabled?: boolean }) => [
  tw`cursor-pointer inline-block relative mb-0 align-bottom leading-5`,
  css`
    min-height: ${theme`space.5`};
    padding: 0 0 0 ${theme`space.6`};
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 250ms ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: ${theme`space.5`};
      height: ${theme`space.5`};
      border-width: 2px;
      border-color: ${theme`colors.black`};
    }
    &:after {
      top: 0.3125rem;
      left: 0.3125rem;
      width: 0.625rem;
      height: 0.625rem;
      transform: scale(0);
      background: ${theme`colors.darkred`};
    }
  `,
  disabled && tw`cursor-not-allowed opacity-30`,
]);

export interface RadioButtonProps {
  id: string;
  name: string;
  text: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
}

const FormRadioButton = ({
  id,
  name,
  text,
  checked,
  onChange,
  error,
  errorMessage,
  required,
  disabled,
}: RadioButtonProps) => {
  return (
    <div>
      <div tw="flex w-full">
        <RadioButton
          id={id}
          name={name}
          type="radio"
          checked={checked}
          required={required}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <Label htmlFor={id} disabled={disabled}>
          <span>{text}</span>
        </Label>
      </div>
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </div>
  );
};

export default FormRadioButton;
