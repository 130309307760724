/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import tw from 'twin.macro';

import { FormGroup } from '../../Components/Base';
import {
  FormLabel,
  FormTextarea,
  PhoneField,
  FormInput,
} from '../../Components/FormFields';
import {
  SenderAndConsigneeContext,
  senderAndConsigneeGuards,
} from './senderAndConsigneeMachine';
import {
  SenderAndConsigneeEvents,
  TSenderAndConsigneeEvents,
} from './SenderAndConsigneeEvents';
import { ContactActions } from '../../reducers/contactReducer';
import { TSendPackageEvents } from '../../events/SendPackageEvents';
import { Translations } from '../../translations/TranslationType';
import useTranslation from '../../hooks/useTranslation';
import { H2 } from '../../Components/Typograhy';
import StepNavigation, {
  StepNavigationNext,
  StepNavigationRow,
  StepNavigationPrevious,
} from '../../Components/Base/StepNavigation';
import { useBoundEvents } from '../../hooks/useBoundEvents';

interface SenderAndConsigneePageProps {
  state: SenderAndConsigneeContext;
  send: (event: TSenderAndConsigneeEvents | TSendPackageEvents) => void;
}
const SenderAndConsigneePage = ({
  send,
  state,
}: SenderAndConsigneePageProps) => {
  const translation: Translations = useTranslation();
  const events = useBoundEvents(SenderAndConsigneeEvents, send);
  return (
    <Fragment>
      <div css={tw`container mx-auto px-4 pt-10 mb-8`}>
        <div tw={'row'}>
          <div tw={'col-12 xl:col-10'}>
            <H2>{translation.senderAndConsigneePage.title}</H2>
            <p tw={'max-w-lg mb-4'}>
              {translation.senderAndConsigneePage.preamble}
            </p>
          </div>
        </div>
      </div>
      <div tw={'container px-4 pb-10 mx-auto'}>
        <div css={tw`row`}>
          <div css={tw`col-12 md:col-6 lg:col-5 mb-8 md:mb-0`}>
            <h3 tw={'font-heading font-bold text-2xl mb-2'}>
              {translation.shipper}
            </h3>

            <FormGroup>
              <FormInput
                label={translation.company}
                value={state.shipper?.addressName || ''}
                name="company"
                autoComplete="organization"
                onChange={(val) => {
                  events.updateSender(ContactActions.changeCompany(val));
                }}
              />
            </FormGroup>

            <div tw="row mb-4">
              <div tw="col-6">
                <FormInput
                  label={translation.registerPage.fields.firstName}
                  value={state.shipper?.firstName || ''}
                  name="senderFirstName"
                  autoComplete="name"
                  error={
                    state.shipper.firstName !== undefined &&
                    state.shipper.firstName.length < 2
                  }
                  errorMessage={translation.requiredField}
                  required={true}
                  onChange={(val) => {
                    events.updateSender(ContactActions.changeFirstName(val));
                  }}
                />
              </div>
              <div tw="col-6">
                <FormInput
                  label={translation.registerPage.fields.lastName}
                  value={state.shipper?.lastName || ''}
                  name="senderLastName"
                  autoComplete="name"
                  error={
                    state.shipper.lastName !== undefined &&
                    state.shipper.lastName.length < 2
                  }
                  errorMessage={translation.requiredField}
                  required={true}
                  onChange={(val) => {
                    events.updateSender(ContactActions.changeLastName(val));
                  }}
                />
              </div>
            </div>

            <FormGroup>
              <PhoneField
                label={translation.phone}
                // type="tel"
                value={state.shipper?.phone || ''}
                name="senderPhoneNumber"
                country={state.shipper.countryCode?.toLowerCase()}
                // autoComplete="tel"
                error={
                  state.shipper.phone !== undefined &&
                  state.shipper.phone.length < 2
                }
                required={true}
                errorMessage={translation.requiredField}
                onChange={(val) => {
                  events.updateSender(ContactActions.changePhone(val));
                }}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel name="senderMessage">
                {translation.messageToDriver}
              </FormLabel>
              <FormTextarea
                name="senderMessage"
                placeholder={translation.messageToDriverExample}
                value={state.shipper?.message || ''}
                onChange={(value) =>
                  events.updateSender(ContactActions.changeMessage(value))
                }
              />
            </FormGroup>
          </div>
          <div css={tw`col-12 md:col-6 lg:col-5`}>
            <h3 tw={'font-heading font-bold text-2xl mb-2'}>
              {translation.consignee}
            </h3>

            <FormGroup>
              <FormInput
                label={translation.company}
                value={state.consignee?.addressName || ''}
                name="consigneeCompany"
                autoComplete="organization"
                onChange={(val) => {
                  events.updateConsignee(ContactActions.changeCompany(val));
                }}
              />
            </FormGroup>

            <div className="row mb-4">
              <div tw="col-6">
                <FormInput
                  label={translation.registerPage.fields.firstName}
                  value={state.consignee?.firstName || ''}
                  name="consigneeFirstName"
                  autoComplete="name"
                  error={
                    state.consignee.firstName !== undefined &&
                    state.consignee.firstName.length < 2
                  }
                  errorMessage={translation.requiredField}
                  required={true}
                  onChange={(val) => {
                    events.updateConsignee(ContactActions.changeFirstName(val));
                  }}
                />
              </div>
              <div tw="col-6">
                <FormInput
                  label={translation.registerPage.fields.lastName}
                  value={state.consignee?.lastName || ''}
                  name="consigneeLastName"
                  autoComplete="name"
                  error={
                    state.consignee.lastName !== undefined &&
                    state.consignee.lastName.length < 2
                  }
                  errorMessage={translation.requiredField}
                  required={true}
                  onChange={(val) => {
                    events.updateConsignee(ContactActions.changeLastName(val));
                  }}
                />
              </div>
            </div>

            <FormGroup>
              <PhoneField
                label={translation.phone}
                // type="tel"
                value={state.consignee?.phone || ''}
                error={
                  state.consignee.phone !== undefined &&
                  state.consignee.phone.length < 2
                }
                errorMessage={translation.requiredField}
                name="consigneePhoneNumber"
                country={state.consignee.countryCode?.toLowerCase()}
                required={true}
                onChange={(val) => {
                  events.updateConsignee(ContactActions.changePhone(val));
                }}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel name="consigneeMessage">
                {translation.messageToDriver}
              </FormLabel>
              <FormTextarea
                name="consigneeMessage"
                // tw="h-20"
                placeholder={translation.messageToDriverExample}
                value={state.consignee?.message || ''}
                onChange={(value) =>
                  events.updateConsignee(ContactActions.changeMessage(value))
                }
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <StepNavigation>
        <StepNavigationRow>
          <StepNavigationPrevious
            label={translation.senderAndConsigneePage.previousStep}
            handleClick={events.package}
          />
          <span tw="ml-auto"></span>
          <StepNavigationNext
            label={
              !state.customsRequired
                ? translation.senderAndConsigneePage.nextStep
                : translation.senderAndConsigneePage.nextStepCustoms
            }
            disabled={
              // @ts-ignore
              !senderAndConsigneeGuards.validSenderAndConsignee(state)
            }
            handleClick={events.senderConsigneeDone}
          />
        </StepNavigationRow>
      </StepNavigation>
    </Fragment>
  );
};

export default SenderAndConsigneePage;
