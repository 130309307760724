/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import tw from 'twin.macro';
import { jsx } from '@emotion/react';

// xs: '.75rem',
// sm: '.875rem',
// base: '1rem',
// lg: '1.125rem',
// xl: '1.25rem',
// '2xl': '1.5rem',
// '3xl': '1.75rem',
// '4xl': '2rem',
// '5xl': '2.5rem',
// '6xl': '3.25rem',

// type size = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
// const base = tw`text-base`;
interface HeadingProps {
  // size?: size;
  children: ReactNode;
}

const h2 = tw`font-heading font-semibold leading-tight text-4xl mb-3`;

export const H2 = ({ children }: HeadingProps) => (
  <h2 css={[h2]}>{children}</h2>
);
