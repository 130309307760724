import sv from '../translations/swedish';
import en from '../translations/english';
import ConfigContext from '../context/ConfigContext';
import { useContext } from 'react';
import { LanguageCode, Translations } from '../translations/TranslationType';
import da from '../translations/danish';
import nb from '../translations/norwegian';
import fi from '../translations/finnish';

const languages: Record<keyof typeof LanguageCode, Translations> = {
  'sv-SE': sv,
  'en-GB': en,
  'da-DK': da,
  'nb-NO': nb,
  'fi-FI': fi,
};
const useTranslation = () => {
  const { language } = useContext(ConfigContext);
  return languages[language];
};

export default useTranslation;
