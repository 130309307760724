/** @jsxImportSource @emotion/react */
import { ReactChildren, ReactNode } from 'react';
import tw, { css, theme } from 'twin.macro';

interface FormLabelProps {
  children?: ReactChildren | ReactNode;
  name: string;
  required?: boolean;
}

const FormLabel = ({ children, name, required }: FormLabelProps) => {
  return (
    <label
      css={[
        tw`inline text-black leading-loose cursor-pointer`,
        css`
          font-size: 0.9375rem;
        `,
        required && [
          css`
            &::after {
              content: '*';
              color: ${theme`colors.darkred`};
              margin-left: ${theme`spacing.1`};
            }
          `,
        ],
      ]}
      htmlFor={name}
    >
      {children}
    </label>
  );
};

export default FormLabel;
