import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import { Suggestion } from 'use-places-autocomplete';
import { TAddressAutocompleteEvents } from './AddressAutocompleteEvents';
import IAddress from '../../Models/Address';

export interface AddressContext {
  value: string;
  selection?: Suggestion;
  suggestions: Suggestion[];
  highlight: number | undefined;
}

export interface GeocodeDoneInvokeEvent extends DoneInvokeEvent<IAddress> {
  type: 'done.invoke.geocode';
}

export const addressAutocompleteMachine = createMachine<
  AddressContext,
  TAddressAutocompleteEvents
>(
  {
    id: 'addressAutocompleteMachine',
    initial: 'initializing',
    states: {
      initializing: {
        on: {
          READY: [
            { cond: (ctx, _) => ctx.value.length > 0, target: 'loadingAuto' },
            { target: 'idle' },
          ],
        },
      },
      idle: {
        on: {
          INPUT: {
            target: 'loading',
            actions: assign({
              value: (ctx, event) => event.data,
            }),
          },
        },
      },
      loading: {
        entry: ['load'],
        on: {
          SUGGESTIONS: [
            {
              target: 'selecting',
              actions: [
                assign({
                  suggestions: (ctx, event) => event.data.data,
                  highlight: (_) => 0,
                }),
                'prefill',
              ],
              cond: (ctx, event) =>
                event.data.status === 'OK' && !event.data.loading,
            },
            {
              target: '',
              cond: (ctx, event) => event.data.loading,
            },
            {
              target: 'error.autocompleteError',
            },
          ],
        },
      },
      loadingAuto: {
        entry: ['load'],
        on: {
          SUGGESTIONS: [
            {
              cond: (ctx, event) => event.data.data.length === 1,
              target: 'done',
              actions: [
                assign({
                  selection: (ctx, event) => event.data.data[0],
                  value: (ctx, event) => event.data.data[0].description || '',
                }),
                'onSelect',
              ],
            },
            {
              target: 'selecting',
              actions: [
                assign({
                  suggestions: (ctx, event) => event.data.data,
                  highlight: (_) => 0,
                }),
                'prefill',
              ],
              cond: (ctx, event) =>
                event.data.status === 'OK' && !event.data.loading,
            },
            {
              target: '',
              cond: (ctx, event) => event.data.loading,
            },
            {
              target: 'error.autocompleteError',
            },
          ],
        },
      },
      selecting: {
        on: {
          MOVE_UP: {
            actions: [
              assign({
                highlight: (ctx) => {
                  let val = ctx.highlight === undefined ? -1 : ctx.highlight;
                  return val <= 0 ? ctx.suggestions.length - 1 : val - 1;
                },
              }),
              'prefill',
            ],
          },
          MOVE_DOWN: {
            actions: [
              assign({
                highlight: (ctx) => {
                  let val = ctx.highlight === undefined ? -1 : ctx.highlight;
                  return val >= ctx.suggestions.length - 1 ? 0 : val + 1;
                },
              }),
              'prefill',
            ],
          },
          SELECT: {
            target: 'done',
            actions: [
              assign({
                selection: (ctx, event) => event.data,
                value: (ctx, event) => event.data?.description || '',
              }),
              'onSelect',
            ],
          },
          CLOSE: {
            target: 'idle',
            // actions: assign({
            //   suggestions: (_) => [],
            // }),
          },
        },
      },

      error: {
        states: {
          autocompleteError: {},
          geocodingError: {},
        },
      },
      done: {},
    },
    on: {
      SET_VALUE: {
        target: 'done',
        actions: assign({
          value: (ctx, event) => event.data,
        }),
      },
      INPUT: [
        {
          cond: 'validInput',
          target: 'loading',
          actions: assign({
            value: (ctx, event) => event.data,
          }),
        },
        {
          actions: [
            assign({
              value: (_) => '',
              selection: (_) => undefined,
            }),
            'onSelect',
          ],
          target: 'idle',
        },
      ],
    },
  },
  {
    guards: {
      validInput: (ctx, event) => !!(event as any).data.length,
    },
  }
);
