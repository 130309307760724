import { assign, ConditionPredicate, createMachine } from 'xstate';
import { TChangePasswordEvents } from './ChangePasswordEvents';

interface ChangePasswordContext {
  oldPassword: string;
  password: string;
  password2: string;
  error?: any;
}

type TChangePasswordGuards<G extends string> = Record<
  G,
  ConditionPredicate<ChangePasswordContext, TChangePasswordEvents>
>;

export type TChangePasswordGuard =
  | 'invalidOldPassword'
  | 'invalidPassword'
  | 'invalidPassword2'
  | 'passwordsNotEqual'
  | 'validSetPassword';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const ChangePasswordGuards: TChangePasswordGuards<TChangePasswordGuard> =
  {
    invalidOldPassword: (ctx) => !minLength(ctx.oldPassword),
    invalidPassword: (ctx) => !minLength(ctx.password),
    invalidPassword2: (ctx) => !minLength(ctx.password2),
    passwordsNotEqual: (ctx) => ctx.password !== ctx.password2,
    validSetPassword: (ctx, event, meta) =>
      [
        ChangePasswordGuards.invalidOldPassword,
        ChangePasswordGuards.invalidPassword,
        ChangePasswordGuards.invalidPassword2,
        ChangePasswordGuards.passwordsNotEqual,
      ].reduce((acc: boolean, fn) => {
        return acc && !fn(ctx, event, meta);
      }, true),
  };

const inputStates = (invalidGuard?: TChangePasswordGuard) => ({
  initial: 'init',
  states: {
    init: {},
    initial: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const changePasswordMachine = createMachine<
  ChangePasswordContext,
  TChangePasswordEvents
>({
  id: 'changePassword',
  initial: 'editing',
  context: {
    oldPassword: '',
    password: '',
    password2: '',
  },
  states: {
    editing: {
      type: 'parallel',
      states: {
        oldPassword: inputStates('invalidOldPassword'),
        password: inputStates('invalidPassword'),
        password2: inputStates('invalidPassword2'),
      },
      on: {
        CHANGE_OLD_PASSWORD: {
          actions: assign({
            oldPassword: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD: {
          actions: assign({
            password: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD2: {
          actions: assign({
            password2: (_, event) => event.data,
          }),
        },
        SET_PASSWORD: 'loading',
      },
    },
    loading: {
      invoke: {
        src: 'changePasswordRequest',
        onDone: {
          target: 'successful',
          actions: ['updateUser'],
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },

    successful: {
      on: {
        SET_PASSWORD: 'loading',
      },
    },
    failed: {
      on: {
        CHANGE_OLD_PASSWORD: {
          actions: assign({
            oldPassword: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD: {
          actions: assign({
            password: (_, event) => event.data,
          }),
        },
        CHANGE_PASSWORD2: {
          actions: assign({
            password2: (_, event) => event.data,
          }),
        },
        SET_PASSWORD: 'loading',
      },
    },
  },
});

export default changePasswordMachine;
