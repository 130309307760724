/** @jsxImportSource @emotion/react */
import { ClassNames } from '@emotion/react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import {
  buttonClass,
  containerStyles,
  dropdownClass,
  flags,
  inputErrorStyles,
  inputStyles,
} from './PhoneField.styles';
import { Fragment } from 'react';
import { FormLabel } from './index';
import FormMessage from '../Base/FormMessage';

interface PhoneFieldProps {
  onChange: (val: string, data: CountryData | {}) => void;
  value: string;
  country?: string;
  error?: boolean;
  placeholder?: string;
  name: string;
  label?: string;
  errorMessage?: string;
  required?: boolean;
  onlyCountries?: string[];
}
export const PhoneField = ({
  onChange,
  value,
  country,
  error,
  name,
  label,
  errorMessage,
  required,
  placeholder,
  onlyCountries,
}: PhoneFieldProps) => {
  return (
    <Fragment>
      {label && (
        <FormLabel name={name} required={required}>
          {label}
        </FormLabel>
      )}
      <ClassNames>
        {({ css }) => (
          <PhoneInput
            defaultMask=".............."
            alwaysDefaultMask={true}
            onChange={onChange}
            onlyCountries={onlyCountries}
            enableTerritories={true}
            value={value}
            country={country}
            inputClass={css(inputStyles)}
            containerClass={css([
              containerStyles,
              flags,
              error && inputErrorStyles,
            ])}
            placeholder={placeholder}
            dropdownClass={css()}
            buttonClass={css([dropdownClass, buttonClass])}
            specialLabel={''}
            inputProps={{ name, required, autoComplete: 'tel' }}
          />
        )}
      </ClassNames>
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </Fragment>
  );
};
