/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw, { styled } from 'twin.macro';
import useTranslation from '../../../hooks/useTranslation';
import { SendPackageContext } from '../../../Machines/sendPackageMachine';
import { IDate } from '../../../helpers/dateSelectGenerator';
import { H2 } from '../../../Components/Typograhy';
import {
  PickupAndDeliverySummary,
  ShippingDetails,
} from '../../../Components/Order/Order';
import { Fragment } from 'react';

interface OrderSummaryProps {
  state: SendPackageContext;
  clock?: IDate;
}

const BookingReferenceBox = styled.div(() => [
  tw`mb-6 max-w-lg font-heading font-bold text-2xl`,
]);

interface BookingReferenceProps {
  label: string;
  awbNumber: string;
}
const BookingReference = ({ label, awbNumber }: BookingReferenceProps) => {
  return (
    <BookingReferenceBox>
      <span tw="inline-block">{label}:</span>{' '}
      <span tw="inline-block text-red">{awbNumber}</span>
    </BookingReferenceBox>
  );
};

const OrderConfirmationSummary = ({ state, clock }: OrderSummaryProps) => {
  const translations = useTranslation();

  if (state.deliveryOption === undefined) return null;
  return (
    <Fragment>
      <div tw={'row'}>
        <div tw={'col-12 xl:col-10'}>
          <H2>{translations.orderConfirmationPage.title}</H2>
          <p tw={'text-lg max-w-lg mb-6'}>
            {translations.orderConfirmationPage.preamble}{' '}
          </p>
          <BookingReference
            label={translations.orderConfirmationPage.yourBookingNumber}
            awbNumber={state.awbNumber}
          ></BookingReference>
        </div>
        <div tw={'col-12 py-4 pl-8 sm:pl-12 mb-8'}>
          <PickupAndDeliverySummary state={state} />
        </div>
      </div>
      <ShippingDetails state={state} />
    </Fragment>
  );
};

export default OrderConfirmationSummary;
