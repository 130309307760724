/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode } from 'react';
import tw, { styled, css } from 'twin.macro';
import FormMessage from '../Base/FormMessage';

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus + label span {
    box-shadow: 0 0 0 2px #c4c4c4;
  }
`;

const StyledCheckbox = styled.span(
  ({ checked, error }: { checked: boolean; error?: boolean }) => [
    tw`inline-block cursor-pointer border-gray-500`,
    css`
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
      border-radius: 1px;
      transition: all 150ms;
      transition: all 300ms;
    `,
    checked && tw`bg-green-900`,
    !checked && tw`border border-solid border-gray-600 `,
    error && tw`border-red border border-2`,
  ]
);

//: { checked: boolean; children: ReactNode }
const Icon = styled.svg(
  ({ checked }: { checked: boolean; children: ReactNode }) => [
    css`
      fill: none;
      stroke: white;
      stroke-width: 2px;
    `,
    !checked && tw`hidden`,
  ]
);

const Label = styled.label([tw`flex items-center cursor-pointer -ml-8`]);

interface CheckboxProps {
  id: string;
  name: string;
  text: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  children?: ReactNode;
}

const FormCheckbox = ({
  id,
  name,
  text,
  checked = false,
  onChange,
  children,
  error,
  errorMessage,
  required,
}: CheckboxProps) => {
  return (
    <Fragment>
      <div tw="flex flex-wrap w-full pl-8">
        <HiddenCheckbox
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          required={required}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />

        <Label htmlFor={id}>
          <StyledCheckbox checked={checked} error={error}>
            <Icon viewBox="0 0 24 24" checked={checked}>
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <span tw={'ml-3 mr-1 leading-tight'}>{text}</span>
        </Label>
        {children && children}
      </div>
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </Fragment>
  );
};

export default FormCheckbox;
