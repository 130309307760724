/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Button } from '../../../Components/Base';
import useTranslation from '../../../hooks/useTranslation';
import { IRegisterContext } from '../RegisterMachine';
import { RegisterEvents, TRegisterEvents } from '../RegisterEvents';
import { useBoundEvents } from '../../../hooks/useBoundEvents';
import { StateValue } from 'xstate';
import { RegisterGuards } from '../RegisterMachine';
import RegisterFormCompany from './RegisterFormCompany';
import RegisterFormPrivate from './RegisterFormPrivate';

interface IRegisterForm {
  state: IRegisterContext;
  send: (event: TRegisterEvents) => void;
  matches: (key: StateValue) => boolean;
}
const RegisterForm = ({ state, send, matches }: IRegisterForm) => {
  const events = useBoundEvents(RegisterEvents, send);
  const translation = useTranslation();
  return (
    <div>
      {state.customerType === 'company' && (
        <RegisterFormCompany send={send} state={state} matches={matches} />
      )}
      {state.customerType === 'private' && (
        <RegisterFormPrivate send={send} state={state} matches={matches} />
      )}
      <div tw={'flex justify-end'}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            events.previous();
          }}
          variant="secondary"
        >
          {translation.registerPage.previousButtonText}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            events.continue();
          }}
          variant="secondary"
          // @ts-ignore
          disabled={!RegisterGuards.validRegistration(state)}
          options={tw`ml-4`}
        >
          {matches('step2.editing') && translation.registerPage.buttonText}
          {matches('registering') && translation.loading}
        </Button>
      </div>
    </div>
  );
};

export default RegisterForm;
