import { assign, ConditionPredicate, createMachine } from 'xstate';
import { LoginResponse } from '../../Models/jena/response/LoginResponse';
import { TLoginEvents } from './LoginEvents';

interface LoginContext {
  name: string;
  username: string;
  password: string;
  error?: any;
  user?: LoginResponse;
}

// interface LoginStates {
//   states: {
//     editing: {};
//     loading: {};
//     successful: {};
//     failed: {};
//   };
// }

type TLoginGuards<G extends string> = Record<
  G,
  ConditionPredicate<LoginContext, TLoginEvents>
>;

export type TLoginGuard = 'invalidUsername' | 'invalidPassword' | 'validLogin';

const minLength = (val?: string, length?: number) =>
  !!val && val.length >= (length || 2);
export const LoginGuards: TLoginGuards<TLoginGuard> = {
  invalidUsername: (ctx) => !minLength(ctx.username),
  invalidPassword: (ctx) => !minLength(ctx.password),
  validLogin: (ctx, event, meta) =>
    [LoginGuards.invalidUsername, LoginGuards.invalidPassword].reduce(
      (acc: boolean, fn) => {
        return acc && !fn(ctx, event, meta);
      },
      true
    ),
};

const inputStates = (invalidGuard?: TLoginGuard) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

const loginMachine = createMachine<LoginContext, TLoginEvents>({
  id: 'login',
  initial: 'editing',
  context: {
    name: '',
    username: '',
    password: '',
  },
  states: {
    editing: {
      type: 'parallel',
      states: {
        username: inputStates('invalidUsername'),
        password: inputStates('invalidPassword'),
      },
      on: {
        CHANGE_USERNAME: {
          actions: assign({
            username: (_, event): string => event.data,
          }),
        },
        CHANGE_PASSWORD: {
          actions: assign({
            password: (_, event) => event.data,
          }),
        },
        FETCH: 'loading',
      },
    },
    loading: {
      invoke: {
        src: 'loginRequest',
        onDone: {
          target: 'successful',
          actions: ['updateUser'],
        },
        onError: {
          target: 'failed',
          actions: assign({
            error: (context, event) => {
              return event.data.error;
            },
          }),
        },
      },
    },

    successful: {
      on: {
        FETCH: 'loading',
        LOGOUT: 'editing',
      },
    },
    failed: {
      on: {
        CHANGE_USERNAME: {
          actions: assign({
            username: (context, event): string => event.data,
          }),
        },
        CHANGE_PASSWORD: {
          actions: assign({
            password: (context, event) => event.data,
          }),
        },
        FETCH: 'loading',
      },
    },
  },
});

export default loginMachine;
