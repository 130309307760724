/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import DeliveryAlternativesDetailsMobile from './DeliveryAlternativesDetailsMobile';
import useTranslation from '../../../../hooks/useTranslation';
import { Translations } from '../../../../translations/TranslationType';
import DeliveryOption from '../../../../Models/DeliveryOption';

interface DeliveryAlternativesProps {
  alternatives: DeliveryOption[];
  selected: DeliveryOption | undefined;
  handleSelect: (option: DeliveryOption) => void;
}

const DeliveryAlternativesMobile = ({
  alternatives,
  selected,
  handleSelect,
}: DeliveryAlternativesProps) => {
  const translation: Translations = useTranslation();

  return (
    <div tw={'row'}>
      <div tw={'col-12'}>
        <div css={[tw`border px-4`]}>
          <div tw={'row'}>
            <div tw={'col-6 font-heading font-bold'}>
              <h3 tw={'py-3'}>{translation.delivery}</h3>
            </div>
            <div tw={'col-6 border-l'}>
              <h3 tw={'py-3 font-heading font-bold'}>{translation.price}</h3>
            </div>
          </div>
          <div>
            {alternatives.map((alternative, i) => (
              <DeliveryAlternativesDetailsMobile
                key={i}
                deliveryAlternative={alternative}
                handleSelected={handleSelect}
                selectedId={selected?.AlternativeId}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAlternativesMobile;
