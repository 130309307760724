/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useContext, useRef, Fragment } from 'react';
import GeocodedAutocomplete from './GeocodedAutocomplete';
import { useMachine } from '@xstate/react/lib';
import { Address } from '../../../../Models/Address';
import useTranslation from '../../../../hooks/useTranslation';
import { FormLabel } from '../../../../Components/FormFields';
import { VerifyAddressesEvents } from './VerifyAddressesEvents';
import FormMessage from '../../../../Components/Base/FormMessage';
import {
  ShippingAddressesContext,
  shippingAddressValidators,
  verifyAddressesMachine,
} from './verifyAddressesMachine';
import { verifyAddressRequest } from '../../../../mappers/jenaMappers';
import useJenaApi from '../../../../hooks/useJenaApi';
import { UserContext } from '../../../../context/UserContext';
import { OpenAddressBook } from './OpenAddressBook';
import { useBoundEvents } from '../../../../hooks/useBoundEvents';
import { Translations } from '../../../../translations/TranslationType';
import { useHistory } from 'react-router-dom';

interface ShippingAddressesProps {
  onVerified: (context: ShippingAddressesContext) => void;
  shipperAddress?: Address;
  consigneeAddress?: Address;
  verified: boolean;
}

interface AddressErrorProps {
  which: 'consignee' | 'shipper';
  matches: (value: string) => boolean;
}

const AddressError = ({ which, matches }: AddressErrorProps) => {
  const translations = useTranslation();
  const errorStates: (keyof Translations['addressValidationErrors'])[] = [
    'missingStreet',
    'missingStreetNumber',
    'verificationError',
  ];
  const errorBase = `editing.${which}.error`;
  if (matches(errorBase)) {
    const translationString =
      errorStates.find((err) => matches(`${errorBase}.${err}`)) || 'error';

    return (
      <FormMessage
        label={translations.addressValidationErrors[translationString]}
        status={'error'}
      />
    );
  }
  return null;
};

export const ShippingAddresses = ({
  shipperAddress,
  consigneeAddress,
  verified,
  onVerified,
}: ShippingAddressesProps) => {
  const translations = useTranslation();
  const history = useHistory();
  const api = useJenaApi();
  const shipperRef = useRef<HTMLInputElement>(null);
  const consigneeRef = useRef<HTMLInputElement>(null);
  const { user, isLoggedIn, userEvents } = useContext(UserContext);
  const [state, send] = useMachine(verifyAddressesMachine, {
    context: {
      shipper: shipperAddress,
      consignee: consigneeAddress,
      verified: verified,
    },
    actions: {
      updateVerified: onVerified,
    },
    services: {
      verify: ({ shipper, consignee }) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (
          urlSearchParams.has('shipper') ||
          urlSearchParams.has('consignee')
        ) {
          history.replace('/');
        }

        return api.verifyAddress(
          verifyAddressRequest(
            shipper || ({} as Address),
            consignee || ({} as Address)
          )
        );
      },
    },
  });
  const events = useBoundEvents(VerifyAddressesEvents, send);
  const { shipper, consignee } = state.context;

  return (
    <Fragment>
      <div css={tw`col-12 md:col-6 xl:col-5 mb-4 md:mb-0`}>
        <div css={tw`w-full relative flex flex-col`}>
          {isLoggedIn && (
            <OpenAddressBook
              label={translations.addressBook}
              onSelect={events.selectShipper}
              addresses={user?.addresses}
              onRemove={userEvents?.removeAddress}
              onAdd={userEvents?.reloadUser}
              numAddress={user?.numAddress}
            />
          )}

          <FormLabel name="shipper">{translations.shipperAddress}</FormLabel>
          <GeocodedAutocomplete
            id={'shipper'}
            placeholder={translations.shipperAddressPlaceholder}
            onSelect={(address) => {
              events.selectShipper(address);

              if (shippingAddressValidators.address(address))
                consigneeRef?.current?.focus();
            }}
            address={shipper}
            ariaLabelClear={translations.clear}
            error={state.matches('editing.shipper.error')}
            inputRef={shipperRef}
          />
          <AddressError which={'shipper'} matches={state.matches} />
        </div>
      </div>
      <div css={tw`col-12 md:col-6 xl:col-5`}>
        <div css={tw`w-full relative flex flex-col`}>
          {isLoggedIn && (
            <OpenAddressBook
              label={translations.addressBook}
              onSelect={events.selectConsignee}
              addresses={user?.addresses}
              onRemove={userEvents?.removeAddress}
              numAddress={user?.numAddress}
            />
          )}
          <FormLabel name="consignee">
            {translations.consigneeAddress}
          </FormLabel>
          <GeocodedAutocomplete
            id={'consignee'}
            placeholder={translations.consigneeAddressPlaceholder}
            onSelect={events.selectConsignee}
            address={consignee}
            ariaLabelClear={translations.clear}
            error={state.matches('editing.consignee.error')}
            inputRef={consigneeRef}
          />
          <AddressError which={'consignee'} matches={state.matches} />
        </div>
      </div>
    </Fragment>
  );
};

export default ShippingAddresses;
