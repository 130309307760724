export interface GetShopResponse {
  shops: ('shop' | shop)[];
  results: string;
}

export const isShop = (shop: 'shop' | shop): shop is shop =>
  typeof shop !== 'string';

interface shop {
  partnercompid: string;
  dibsshop: string;
  method: string;
}
