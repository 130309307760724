/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import useTranslation from '../../../../hooks/useTranslation';
import { Translations } from '../../../../translations/TranslationType';
import { DeliveryAlternativesDetailsDesktop } from './';
import DeliveryOption from '../../../../Models/DeliveryOption';

interface DeliveryAlternativesDesktopProps {
  alternatives: DeliveryOption[];
  selected: DeliveryOption | undefined;
  handleSelect: (option: DeliveryOption) => void;
}

const DeliveryAlternativesDesktop = ({
  alternatives,
  selected,
  handleSelect,
}: DeliveryAlternativesDesktopProps) => {
  const translation: Translations = useTranslation();
  return (
    <div tw={'row'}>
      <div css={tw`col-12`}>
        <table css={[tw`table-fixed w-full border`]}>
          <thead>
            <tr tw="font-heading">
              <th tw="px-4 md:px-8 py-3 text-left">{translation.pickUp}</th>
              <th tw="px-4 md:px-8 py-3 text-left">{translation.delivery}</th>
              <th tw="px-4 md:px-8 py-3 text-left">{translation.price}</th>
              <th tw="px-4 md:px-8 py-3">{translation.select}</th>
            </tr>
          </thead>
          <tbody>
            {alternatives.map((alternative, i) => (
              <DeliveryAlternativesDetailsDesktop
                key={i}
                deliveryAlternative={alternative}
                handleSelected={handleSelect}
                selectedId={selected?.AlternativeId}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeliveryAlternativesDesktop;
