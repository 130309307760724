import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

export const useUser = () => {
  const { user, isLoggedIn, userEvents, packageTypes } =
    useContext(UserContext);
  return {
    user,
    isLoggedIn,
    packageTypes,
    logout: userEvents.logout,
    reload: userEvents.reloadUser,
  };
};
