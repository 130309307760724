/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import {
  FormInput,
  FormSelect,
  PhoneField,
} from '../../../Components/FormFields';
import { FormGroup } from '../../../Components/Base';
import useTranslation from '../../../hooks/useTranslation';
import { IRegisterContext } from '../RegisterMachine';
import { RegisterEvents, TRegisterEvents } from '../RegisterEvents';
import { useBoundEvents } from '../../../hooks/useBoundEvents';
import { StateValue } from 'xstate';
import { useCountries } from '../../../hooks/useCountries';
import { useState, useEffect } from 'react';

interface IRegisterForm {
  state: IRegisterContext;
  send: (event: TRegisterEvents) => void;
  matches: (key: StateValue) => boolean;
}
const RegisterForm = ({ state, send, matches }: IRegisterForm) => {
  const events = useBoundEvents(RegisterEvents, send);
  const translation = useTranslation();
  const { countries, groupedCountries } = useCountries();
  const [phoneCountry, setPhoneCountry] = useState<undefined | string>();
  useEffect(() => {
    if (state.phone.length < 5 && state.phone2.length < 5) {
      setPhoneCountry(
        countries
          .find((x) => x.countryid === state.country)
          ?.isocode?.toLocaleLowerCase()
      );
    }
  }, [state.country, state.phone, state.phone2, countries]);

  return (
    <form css={[tw``]}>
      <FormGroup>
        <FormInput
          name="firstName"
          label={translation.registerPage.fields.firstName}
          value={state.firstName}
          type="Text"
          onChange={events.changeFirstName}
          autoComplete="firstName"
          error={matches('step2.editing.firstName.error')}
          errorMessage={translation.requiredField}
          required={true}
        />
        <FormInput
          name="lastName"
          label={translation.registerPage.fields.lastName}
          value={state.lastName}
          type="Text"
          onChange={events.changeLastName}
          autoComplete="lastName"
          error={matches('step2.editing.lastName.error')}
          errorMessage={translation.requiredField}
          required={true}
        />
        <FormInput
          name="email"
          label={translation.registerPage.fields.email}
          value={state.email}
          type="Text"
          onChange={events.changeEmail}
          autoComplete="email"
          error={matches('step2.editing.email.error')}
          errorMessage={translation.invalidEmailAddress}
          required={true}
          maxLength={99}
        />
        <PhoneField
          label={translation.registerPage.fields.phone}
          onlyCountries={countries.map((x) => x.isocode.toLocaleLowerCase())}
          // type="tel"
          value={state.phone || ''}
          name="phone"
          country={phoneCountry}
          // autoComplete="tel"
          error={matches('step2.editing.phone.error')}
          required={true}
          errorMessage={translation.requiredField}
          onChange={(val, countryData) => {
            if ('countryCode' in countryData) {
              const country = countries.find(
                (x) =>
                  x.isocode.toLocaleLowerCase() ===
                  countryData.countryCode.toLocaleLowerCase()
              );
              if (country) events.changeCountry(country.countryid);
            }
            events.changePhone(val);
          }}
        />
        <PhoneField
          label={translation.registerPage.fields.phone + ' 2'}
          onlyCountries={countries.map((x) => x.isocode.toLocaleLowerCase())}
          // type="tel"
          value={state.phone2 || ''}
          name="phone2"
          country={phoneCountry}
          // autoComplete="tel"
          onChange={events.changePhone2}
        />
      </FormGroup>
      <FormGroup>
        <div tw={'row'}>
          <div tw={'col-6'}>
            <FormInput
              name="street"
              label={translation.registerPage.fields.street}
              value={state.street}
              type="Text"
              onChange={events.changeStreet}
              autoComplete="street"
              error={matches('step2.editing.street.error')}
              errorMessage={translation.requiredField}
              required={true}
              maxLength={199}
            />
          </div>
          <div tw={'col-6'}>
            <FormInput
              name="streetNumber"
              label={translation.registerPage.fields.streetNumber}
              value={state.streetNumber}
              type="Text"
              onChange={events.changeStreetNumber}
              autoComplete="streetNumber"
              error={matches('step2.editing.streetNumber.error')}
              errorMessage={translation.requiredField}
              required={true}
              maxLength={10}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div tw={'col-6'}>
            <FormInput
              name="postalCode"
              label={translation.registerPage.fields.postalCode}
              value={state.postalCode}
              type="Text"
              onChange={events.changePostalCode}
              autoComplete="postalCode"
              error={matches('step2.editing.postalCode.error')}
              errorMessage={translation.requiredField}
              required={true}
              maxLength={19}
            />
          </div>
          <div tw={'col-6'}>
            <FormInput
              name="city"
              label={translation.registerPage.fields.city}
              value={state.city}
              type="Text"
              onChange={events.changeCity}
              autoComplete="city"
              error={matches('step2.editing.city.error')}
              errorMessage={translation.requiredField}
              required={true}
              maxLength={199}
            />
          </div>
        </div>
        <div tw={'row'}>
          <div tw={'col-6'}>
            <FormSelect
              label={translation.country}
              value={state.country}
              name="country"
              onChange={events.changeCountry}
              error={matches('step2.editing.country.error')}
              errorMessage={translation.requiredField}
            >
              <option value="" disabled></option>
              {Object.keys(groupedCountries)
                .filter((x) => x === 'Nordic')
                .map((cg) => (
                  <optgroup label={cg} key={`optgroup-${cg}`}>
                    {groupedCountries[cg].map((x) => (
                      <option
                        value={x.countryid}
                        key={`country-${x.countryid}`}
                      >
                        {x.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              {Object.keys(groupedCountries)
                .filter((x) => x !== 'Nordic' && x !== 'Other')
                .sort((a, b) => a.localeCompare(b))
                .map((cg) => (
                  <optgroup label={cg} key={`optgroup-${cg}`}>
                    {groupedCountries[cg].map((x) => (
                      <option
                        value={x.countryid}
                        key={`country-${x.countryid}`}
                      >
                        {x.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              {Object.keys(groupedCountries)
                .filter((x) => x === 'Other')
                .map((cg) => (
                  <optgroup label={cg} key={`optgroup-${cg}`}>
                    {groupedCountries[cg].map((x) => (
                      <option
                        value={x.countryid}
                        key={`country-${x.countryid}`}
                      >
                        {x.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
            </FormSelect>
          </div>
        </div>
        {state.error && <p tw={'text-red my-3'}>{state.error}</p>}
      </FormGroup>
    </form>
  );
};

export default RegisterForm;
