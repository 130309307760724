/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { IconCheckmark, IconExclamation } from '../../svg';

type MessageProps = {
  label: string;
  status: 'success' | 'error';
  textStyle?: 'text-base' | 'text-sm';
};

const iconStyle = tw`w-4 h-4 mr-1 fill-current flex-none`;

const FormMessage = ({ label, status, textStyle, ...rest }: MessageProps) => {
  let style = tw``;
  switch (status) {
    case 'success':
      style = tw`text-green-900`;
      break;
    case 'error':
      style = tw`text-red`;
      break;
  }

  let textStyled = tw``;
  switch (textStyle) {
    case 'text-base':
      textStyled = tw`text-base`;
      break;
    case 'text-sm':
      textStyled = tw`text-sm`;
      break;
  }

  return (
    <div
      css={[tw`flex text-sm items-center my-2`, style, textStyled]}
      {...rest}
    >
      {status === 'success' ? (
        <IconCheckmark css={iconStyle} />
      ) : (
        <IconExclamation css={iconStyle} />
      )}
      <span css={[tw`leading-tight`]}>{label}</span>
    </div>
  );
};

export default FormMessage;
