/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { StateValue } from 'xstate';
import useTranslation from '../../hooks/useTranslation';
import { TSaveAddressEvents } from './SaveAddressEvents';
import { ISaveAddressContext } from './SaveAddressMachine';

interface AddressSavedProps {
  state: ISaveAddressContext;
  send: (event: TSaveAddressEvents) => void;
  matches: (key: StateValue) => boolean;
}
export const AddressSaved = ({ send }: AddressSavedProps) => {
  const translation = useTranslation();
  return (
    <form css={[tw``]}>
      <p>{translation.addressBookModal.addressSaved}</p>
      {/* <div css={[tw`flex justify-end`]}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            events.previous();
          }}
          variant="secondary"
        >
          {translation.addressBookModal.addAnother}
        </Button>
      </div> */}
    </form>
  );
};
