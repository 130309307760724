import { Fragment } from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Advise, defaultAdvise } from '../../../Models/Advise';
import {
  FormInput,
  FormLabel,
  FormSelect,
  PhoneField,
} from '../../../Components/FormFields';
import {
  AdviseMethod,
  AdviseType,
} from '../../../Models/jena/request/BookDefRequest';
import { AdviseEvents } from '../PaymentEvents';
import { Button } from '../../../Components/Base';
import { v4 as uuidv4 } from 'uuid';
import useTranslation from '../../../hooks/useTranslation';
import { IconTrash } from '../../../svg';
import { validAdvise } from '../paymentPageMachine';
import FormMessage from '../../../Components/Base/FormMessage';

const AdviseEmail = ({
  advise,
  error,
  events,
}: {
  advise: Advise;
  error: boolean;
  events: typeof AdviseEvents;
}) => {
  const translations = useTranslation();

  return (
    <FormInput
      name={`advise-value-${advise.id}`}
      value={advise.value}
      required={true}
      placeholder={translations.advise.email}
      error={error || !validAdvise(advise)}
      onChange={(value) => events.change({ id: advise.id, value })}
      maxLength={99}
    />
  );
};

const AdvisePhone = ({
  advise,
  events,
}: {
  advise: Advise;
  events: typeof AdviseEvents;
}) => {
  return (
    <PhoneField
      value={advise.value || ''}
      name={`advise-value-${advise.id}`}
      country={'se'}
      required={true}
      onChange={(value) => events.change({ id: advise.id, value })}
    />
  );
};

interface AdviseRowProps {
  advise: Advise;
  events: typeof AdviseEvents;
  error: boolean;
}
const AdviseRow = ({ advise, events, error }: AdviseRowProps) => {
  const translations = useTranslation();
  return (
    <div tw="w-full">
      {advise.required ? (
        <Fragment>
          <div tw="flex flex-wrap -mx-2 py-2">
            <div tw="px-2 mb-2 md:(w-6/12 mb-4)">
              <div>
                <FormLabel name={`advise-value-${advise.id}`} required>
                  {translations.advise.bookingConfirmation}{' '}
                  {translations.advise.email.toLocaleLowerCase()}
                </FormLabel>
              </div>
              <AdviseEmail advise={advise} error={error} events={events} />
              {error || !validAdvise(advise) ? (
                <div tw="">
                  <FormMessage
                    status="error"
                    label={translations.invalidEmailAddress}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fragment>
      ) : (
        <div tw="flex flex-wrap -mx-2 py-2 lg:w-2/3">
          <div tw="w-2/3 px-2 mb-4 md:(w-4/12 mb-0)">
            <div tw="sr-only">
              <FormLabel name="advise-type">
                {translations.advise.type}
              </FormLabel>
            </div>
            <FormSelect
              name={`advise-type-${advise.id}`}
              value={advise.type}
              onChange={(val) =>
                events.change({ id: advise.id, type: val as AdviseType })
              }
              options={[
                {
                  value: AdviseType.BookingConfirmation,
                  label: translations.advise.bookingConfirmation,
                },
                {
                  value: AdviseType.DeliveryConfirmation,
                  label: translations.advise.deliveryConfirmation,
                },
              ]}
            />
          </div>
          <div tw="w-1/3 px-2 mb-4 md:(w-2/12 mb-0)">
            <div tw="sr-only">
              <FormLabel name={`advise-method-${advise.id}`}>
                {translations.advise.method}
              </FormLabel>
            </div>
            <FormSelect
              name={`advise-method-${advise.id}`}
              value={advise.method}
              disabled={advise.required}
              onChange={(val) =>
                events.change({ id: advise.id, method: val as AdviseMethod })
              }
              options={[
                {
                  value: AdviseMethod.Email,
                  label: translations.advise.email,
                },
                {
                  value: AdviseMethod.Sms,
                  label: translations.advise.textMessage,
                },
              ]}
            />
          </div>
          <div tw="w-full px-2 mb-2 md:(w-5/12 mb-0)">
            {advise.method === AdviseMethod.Email ? (
              <Fragment>
                <div tw="sr-only">
                  <FormLabel name={`advise-value-${advise.id}`} required>
                    {translations.advise.bookingConfirmation}{' '}
                    {translations.advise.email.toLocaleLowerCase()}
                  </FormLabel>
                </div>
                <AdviseEmail advise={advise} error={error} events={events} />
              </Fragment>
            ) : (
              <Fragment>
                <div tw="sr-only">
                  <FormLabel name={`advise-value-${advise.id}`}>
                    {translations.advise.textMessage}
                  </FormLabel>
                </div>
                <AdvisePhone advise={advise} events={events} />
              </Fragment>
            )}
          </div>
          <div tw="flex w-full md:(w-auto)">
            <div tw="mt-auto ml-auto md:(ml-0)">
              {!advise.required && (
                <button
                  className="group"
                  tw={
                    'items-start mt-auto inline-flex px-3 py-3 ml-auto text-sm text-darkred hover:(bg-gray-100) rounded-sm'
                  }
                  onClick={() => {
                    events.remove(advise.id);
                  }}
                >
                  <span tw="mr-2 md:sr-only">{translations.remove}</span>
                  <IconTrash tw="w-5 h-5 stroke-2 stroke-current" />
                </button>
              )}
            </div>
          </div>
          {advise.method === AdviseMethod.Email && !validAdvise(advise) ? (
            <div tw="px-2">
              <FormMessage
                status="error"
                label={translations.invalidEmailAddress}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
interface AdviseListProps {
  advise: Advise[];
  events: typeof AdviseEvents;
  error: boolean;
}
export const AdviseList = ({ advise, events, error }: AdviseListProps) => {
  const translations = useTranslation();
  return (
    <Fragment>
      {advise.map((a, i) => (
        <AdviseRow key={i} advise={a} events={events} error={error} />
      ))}
      <div tw={'row'}>
        <div tw={'col-12'}>
          <div css={tw`w-full flex flex-wrap mt-4`}>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => events.add({ ...defaultAdvise, id: uuidv4() })}
              css={[tw`w-auto rounded-full py-2 px-10 mr-2`]}
            >
              <span aria-hidden="true" tw="mr-1">
                +
              </span>{' '}
              {translations.advise.addMoreNotifications}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
