/** @jsxImportSource @emotion/react */
import tw, { css, TwStyle } from 'twin.macro';
import { ClassNames, jsx, SerializedStyles } from '@emotion/react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { ReactNode } from 'react';
import { IconClose } from '../../svg';
import useTranslation from '../../hooks/useTranslation';

type ModalStyle = SerializedStyles | TwStyle;
type ModalStyles = ModalStyle | ModalStyle[];
const modalOverlayDefault: ModalStyles = [
  css`
    position: fixed;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;
    margin-top: -3em;
    overflow-y: auto;
    opacity: 0;
    transition: all 300ms ease-in-out;
    background-color: rgb(255 255 255 / 0.95);
    -webkit-overflow-scrolling: touch;

    &::before,
    &::after {
      display: flex;
      content: '';
      min-height: 1.5em;
      height: 1.5em;
    }
  `,
];

//Keep below until testing a payment (dibs iframe)
//height: 0em;
// min-height: 0em;
//flex-grow: 1;

export const modalOverlayLarge: ModalStyles = [
  ...modalOverlayDefault,
  css`
    margin-top: 0;

    &::before,
    &::after {
      flex: 1 1 auto;
    }
  `,
];

export const modalOverlaySmall: ModalStyles = [
  ...modalOverlayDefault,
  css`
    &::before,
    &::after {
      flex-grow: 1;
    }
  `,
];

export const modalContentSmall: ModalStyles = [
  tw`relative bg-white w-full mx-auto my-0 border border-gray-300`,
  css`
    border-radius: 8px;
    max-width: calc(26em);
    border-radius: 0.25em;
    width: calc(100% - 4em);
    box-shadow: 0 5px 30px -15px rgba(0, 0, 0, 0.2);
  `,
];

export const modalContentLarge: ModalStyles = [
  tw`relative bg-white w-full mx-auto my-0 border border-gray-300`,
  css`
    border-radius: 8px;
    border-radius: 0.25em;
    max-width: 1000px;
    flex: 0 0 320px;
    width: calc(100% - 1em);
    box-shadow: 0 5px 30px -15px rgba(0, 0, 0, 0.2);
  `,
];

export const modalContentPayment: ModalStyles = [
  ...modalContentLarge,
  css`
    flex: 1 0 600px;
  `,
];

export const closeButtonStyle: ModalStyles = [
  tw`flex justify-center items-center ml-auto text-white bg-black w-8 h-8 rounded-full border hover:(bg-gray-300 text-black)`,
  css`
    right: 1em;
    top: 1em;
  `,
];

interface ModalProps
  extends Omit<ReactModalProps, 'overlayClassName' | 'className'> {
  overlayClassName?: ModalStyles;
  className?: ModalStyles;
  children: ReactNode;
}

export const Modal = ({
  overlayClassName,
  className,
  children,
  ...rest
}: ModalProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          overlayClassName={css(overlayClassName)}
          className={css(className)}
          {...rest}
        >
          {children}
        </ReactModal>
      )}
    </ClassNames>
  );
};

interface ModalLargeProps
  extends Omit<ReactModalProps, 'overlayClassName' | 'className'> {
  handleClose?: () => void;
  children: ReactNode;
}

export const ModalLarge = ({
  children,
  handleClose,
  ...rest
}: ModalLargeProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          overlayClassName={css(modalOverlayLarge)}
          className={css(modalContentLarge)}
          {...rest}
        >
          <div css={tw`container mx-auto p-4 md:(p-8)`}>
            <div css={tw`absolute top-4 right-4`}>
              {handleClose && <ModalCloseButton handleClose={handleClose} />}
            </div>
            <div css={tw`col-12`}>{children}</div>
          </div>
        </ReactModal>
      )}
    </ClassNames>
  );
};

interface ModalSmallProps
  extends Omit<ReactModalProps, 'overlayClassName' | 'className'> {
  handleClose?: () => void;
  children: ReactNode;
}

export const ModalSmall = ({
  children,
  handleClose,
  ...rest
}: ModalSmallProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          overlayClassName={css(modalOverlaySmall)}
          className={css(modalContentSmall)}
          {...rest}
        >
          <div css={tw`container mx-auto pt-6 pb-12 px-4 md:(px-12)`}>
            <div css={tw`row`}>
              <div css={tw`col-12 mb-2`}>
                {handleClose && <ModalCloseButton handleClose={handleClose} />}
              </div>
              <div css={tw`col-12`}>{children}</div>
            </div>
          </div>
        </ReactModal>
      )}
    </ClassNames>
  );
};

interface ModalCloseButtonProps {
  handleClose: () => void;
}

export const ModalCloseButton = ({ handleClose }: ModalCloseButtonProps) => {
  const translations = useTranslation();
  return (
    <button
      aria-label={translations.close}
      css={closeButtonStyle}
      onClick={() => {
        handleClose();
      }}
    >
      <IconClose tw="w-5 h-5 fill-current" />
    </button>
  );
};

export default Modal;
