/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode } from 'react';
import { SerializedStyles } from '@emotion/react';
import tw, { TwStyle, styled } from 'twin.macro';
import Button from './Button';
import { IconArrowLeft, IconArrowRight } from '../../svg';

type Style = SerializedStyles | TwStyle;
type Styles = Style | Style[];

export const StepNavigationRow = styled.div(
  ({ children }: { children: ReactNode }) => [
    tw`flex items-center flex-col py-8 md:py-0 md:(flex-row)`,
  ]
);

export const StepNavigationInformation = styled.span(
  ({ children }: { children: ReactNode }) => [
    tw`sr-only md:(not-sr-only text-sm px-4)`,
  ]
);

interface StepNavigationPreviousProps {
  label: string;
  handleClick?: () => void;
}

export const StepNavigationPrevious = ({
  label,
  handleClick,
}: StepNavigationPreviousProps) => {
  return (
    <button
      tw="flex items-center mb-6 md:(mb-0) group-hover:(border-b)"
      onClick={handleClick}
    >
      <IconArrowLeft css={[tw`fill-current h-4 w-4`, { flex: '0 0 1rem' }]} />
      <span tw={'leading-tight ml-2'}>{label}</span>
    </button>
  );
};

interface StepNavigationNextProps {
  type?: 'submit';
  label: string;
  labelExtra?: string;
  disabled: boolean;
  handleClick?: () => void;
}
export const StepNavigationNext = ({
  type,
  label,
  labelExtra,
  disabled,
  handleClick,
}: StepNavigationNextProps) => {
  return (
    <Button
      type={type}
      size="xl"
      variant="success"
      aria-invalid={disabled}
      disabledStyle={disabled}
      options={tw`rounded-sm`}
      onClick={() => handleClick && handleClick()}
    >
      <span tw={'leading-tight mr-2'}>
        {label}
        {labelExtra && <span tw={'ml-1 font-bold'}>{labelExtra}</span>}
      </span>
      <IconArrowRight
        css={[tw`fill-current text-current h-4 w-4`, { flex: '0 0 1rem' }]}
      />
    </Button>
  );
};

interface StepNavigationProps {
  children: ReactNode;
}

const StepNavigation = ({ children }: StepNavigationProps) => {
  const fixedToBottom: Styles = [
    tw`bg-white mt-auto px-4 max-w-screen-xl max-w-full w-full`,
    tw`md:(sticky z-30 bottom-0 border-t-2 border-gray-300 p-4)`,
  ];

  return (
    <Fragment>
      <div css={fixedToBottom}>{children}</div>
    </Fragment>
  );
};

export default StepNavigation;
