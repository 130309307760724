import { useEffect, useMemo } from 'react';
import { StateValue } from 'xstate';
import { SendPackageContext } from '../../../Machines/sendPackageMachine';
import { PaymentEvents } from '../PaymentEvents';
import { NetsPaymentCompletedResponse } from '../paymentPageMachine';

interface IProps {
  state: SendPackageContext;
  events: typeof PaymentEvents;
  matches: (key: StateValue) => boolean;
  checkoutKey: string;
  paymentId: string;
  language: string;
}

export const NetsCheckout = ({
  events,
  state,
  matches,
  checkoutKey,
  language,
  paymentId,
}: IProps) => {
  const checkoutOptions = useMemo(() => {
    return {
      checkoutKey,
      paymentId,
      containerId: 'DlgNets',
      language,
      theme: {
        buttonRadius: '5px',
      },
    };
  }, [checkoutKey, paymentId, language]);

  useEffect(() => {
    const checkout = window.Dibs
      ? new window.Dibs.Checkout(checkoutOptions)
      : null;
    if (checkout === null) {
      console.error('Dibs not loaded!');
    } else {
      checkout.on('pay-initialized', function (response: any) {
        events.payInitialized(response);
        checkout.send('payment-order-finalized', true);
      });
      checkout.on(
        'payment-completed',
        function (response: NetsPaymentCompletedResponse) {
          events.cardAccepted(response);
        }
      );
      checkout.on('payment-failed', function (response: any) {
        events.cardDeclined(response);
      });
    }
    return () => {
      console.log('checkout.cleanup');
      checkout.cleanup();
    };
  }, [checkoutOptions, events]);

  const el = document.getElementById('DlgNets');
  if (!el) {
    console.error('#DlgNets not found!');
  }
  if (el && el.children.length > 0) {
    console.error('#DlgNets not empty!');
  }

  return <></>;
};
