import { Translations } from '../translations/TranslationType';
import { TypeOptions } from 'react-toastify/dist/types';

export interface ToastOptions {
  type: TypeOptions;
  id?: string;
}
export const sendToast = <
  K extends keyof Translations,
  SK extends keyof Translations[K],
  TK extends keyof Translations[K][SK]
>(
  { type, id }: ToastOptions,
  key: K,
  secondKey?: SK,
  thirdKey?: TK
) => ({
  type: 'showToast',
  meta: {
    translationKeys: [key, secondKey, thirdKey],
    type,
    toastId: id,
  },
});
