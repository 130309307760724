import { assign, MachineConfig, actions } from 'xstate';
import { Address } from '../../Models/Address';
import DeliveryOption from '../../Models/DeliveryOption';
import { Parcel } from '../../Models/Parcel';
import packageReducer from './packageReducer';
import { TDeliveryEvents } from './DeliveryEvents';
import { isValid, parseISO } from 'date-fns';
import { addMinutes, format } from 'date-fns/fp';
import { PartnerInformation } from '../../Models/PartnerInformation';
import { Context, TGuards } from '../../Machines/sendPackageMachine';
import { sendToast } from '../../Machines/sendToastAction';
import { ConsignmentInfo } from '../../Models/jena/response/GetConsignmentInfoResponse';
import { GenerateSupportTicketResponse } from '../../Models/jena/response/GenerateSupportTicketResponse';
import virtualPageview from '../../virtualPageview';
import RequestTypes from '../../enums/RequestTypes';

const { send, cancel } = actions;

export interface DeliveryContext {
  userLoggedIn: boolean;
  completed: boolean;
  shipper: Address;
  consignee: Address;
  addressVerified: boolean;
  packages: Parcel[];
  alternatives: DeliveryOption[];
  deliveryOption?: DeliveryOption;
  requestType: keyof typeof RequestTypes;
  date?: string;
  time?: string;
  dateStart?: string;
  dateEnd?: string;
  timeStartOpen?: string;
  timeStartClose?: string;
  timeEndOpen?: string;
  timeEndClose?: string;
  customsRequired: boolean;
  partnerInfo: PartnerInformation;
  hasNewAlternatives: boolean;
  invoiceAllowed: boolean;
  supportTicketId?: string;
  requestId?: string;
  paymentType: 'invoice' | 'card';
}

const validators = {
  date: (date?: string) => {
    const parsed = parseISO(date || '');
    return isValid(parsed); //&& isFuture(addDays(1)(parsed)); // TODO future is not pure!
  },
  time: (time: string = '', date: string = '') =>
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time) &&
    new Date(`${date} ${time}`) > new Date(),
  requestType: (type: keyof typeof RequestTypes) =>
    type === 'EarliestPickup' ||
    type === 'LatestDelivery' ||
    type === 'EarliestLatest',
};

const inputStates = (invalidGuard: DeliveryGuards) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

export enum DeliveryGuards {
  deliveryFormValid = 'deliveryFormValid',
  dateInvalid = 'dateInvalid',
  timeInvalid = 'timeInvalid',
  requestTypeInvalid = 'requestTypeInvalid',
  deliveryStepComplete = 'deliveryStepComplete',
  // excessiveWeight = 'excessiveWeight',
  // excessiveVolume = 'excessiveVolume',
  dateStartInvalid = 'dateStartInvalid',
  dateEndInvalid = 'dateEndInvalid',
  packagesInvalid = 'packagesInvalid',
  timeEndOpenInvalid = 'timeEndOpenInvalid',
  timeEndCloseInvalid = 'timeEndCloseInvalid',
  timeStartOpenInvalid = 'timeStartOpenInvalid',
  timeStartCloseInvalid = 'timeStartCloseInvalid',
}

const deliveryMachine: MachineConfig<
  Context & DeliveryContext,
  any,
  TDeliveryEvents
> = {
  id: 'delivery',
  initial: 'init',
  states: {
    init: {
      entry: [
        'scrollToTop',
        send('BLUR_TIME'),
        () => virtualPageview('/', 'Delivery'),
      ],
      always: [{ target: 'editing' }],
      on: {
        // Select option included in order to handle cases when you navigate back to the delivery page
        SELECT_OPTION: {
          actions: [
            assign((ctx, e) => ({
              deliveryOption: e.data,
            })),
          ],
          target: 'consignmentInfo',
        },
      },
    },
    editing: {
      id: 'editing',
      type: 'parallel',
      states: {
        // addresses: formstate,
        date: inputStates(DeliveryGuards.dateInvalid),
        time: inputStates(DeliveryGuards.timeInvalid),
        dateStart: inputStates(DeliveryGuards.dateStartInvalid),
        dateEnd: inputStates(DeliveryGuards.dateEndInvalid),
        timeStartOpen: inputStates(DeliveryGuards.timeStartOpenInvalid),
        timeStartClose: inputStates(DeliveryGuards.timeStartCloseInvalid),
        timeEndOpen: inputStates(DeliveryGuards.timeEndOpenInvalid),
        timeEndClose: inputStates(DeliveryGuards.timeEndCloseInvalid),
        requestType: inputStates(DeliveryGuards.requestTypeInvalid),
        // requestMode: {
        //   initial: 'init',
        //   states: {
        //     init: {},
        //     earliestPickup: {},
        //     latestDelivery: {},
        //     earliestLatest: {
        //       always: [
        //         {
        //           actions: assign((ctx) => {
        //             const now = addMinutes(15)(/*clock?.now() ||*/ new Date());
        //             return {
        //               date: undefined,
        //               time: undefined,
        //               dateStart: format('yyyy-MM-dd')(now),
        //               dateEnd: format('yyyy-MM-dd')(now),
        //               // FIXME
        //             };
        //           }),
        //         },
        //       ],
        //     },
        //   },
        // },
        packages: {
          initial: 'init',
          states: {
            init: {},
            edit: {
              always: [
                {
                  cond: DeliveryGuards.packagesInvalid,
                  target: 'error.invalidPackages',
                },
                // {
                //   cond: DeliveryGuards.excessiveVolume,
                //   target: 'error.excessiveVolume',
                // },
                // {
                //   cond: DeliveryGuards.excessiveWeight,
                //   target: 'error.excessiveWeight',
                // },
                { target: 'valid' },
              ],
            },
            valid: {},
            error: {
              states: {
                // excessiveWeight: {},
                // excessiveVolume: {},
                invalidPackages: {},
              },
            },
          },
        },
      },
      always: [
        {
          cond: DeliveryGuards.deliveryFormValid,
          target: 'debouncedLoading',
          actions: assign({
            deliveryOption: (_) => undefined,
            hasNewAlternatives: (_) => false,

            alternatives: (_) => [],
          }),
        },
        // {
        //   target: '#delivery',
        //   actions: assign({
        //     alternatives: (_) => [],
        //     deliveryOption: (_) => undefined,
        //   }),
        // },
      ],
    },
    debouncedLoading: {
      entry: [
        cancel('debounced-loading-timer'),
        send(
          { type: 'LOAD_ALTERNATIVES' },
          { delay: 1500, id: 'debounced-loading-timer' }
        ),
      ],
      on: {
        LOAD_ALTERNATIVES: { target: 'loading' },
      },
    },
    error: {},
    loading: {
      entry: [
        sendToast(
          { type: 'info', id: 'load-alternatives' },
          'deliveryPage',
          'loadingAlternatives'
        ),
      ],
      invoke: {
        id: 'loadAlternatives',
        src: 'loadAlternatives',
        onError: {
          target: 'error',
          // actions: assign( (ctx,e) => ({errorMessage: e.error}))
          actions: sendToast(
            { type: 'error', id: 'load-alternatives' },
            'deliveryPage',
            'errorLoadingAlternatives'
          ),
        },
        onDone: {
          target: 'picking',
          actions: [
            assign((ctx, e) => ({
              alternatives: e.data.Alternatives,
              requestId: e.data.RequestId,
              customsRequired: (e.data.Customs + '').toLowerCase() === 'true',
              hasNewAlternatives: true,
              supportTicketId: undefined,
              deliveryOption: undefined,
            })),
          ],
        },
      },
    },
    supportTicket: {
      initial: 'creating',
      states: {
        // init: {
        // },
        creating: {
          invoke: {
            src: 'generateSupportTicket',
            id: 'generateSupportTicket',
            onError: {
              target: 'error',
            },
            onDone: {
              target: 'created',
              actions: [
                assign((ctx, e) => ({
                  supportTicketId: (e.data as GenerateSupportTicketResponse)
                    .supportticketid,
                })),
              ],
            },
          },
        },
        created: {
          always: {
            target: '#delivery.picking',
          },
        },
        error: {},
      },
    },
    picking: {
      initial: 'unknown',
      id: 'picking',
      states: {
        unknown: {
          always: [
            {
              target: 'idle',
              cond: (ctx) => ctx.alternatives && ctx.alternatives.length > 0,
              actions: sendToast(
                { type: 'success', id: 'load-alternatives' },
                'deliveryPage',
                'newAlternativesLoaded'
              ),
            },
            {
              target: 'nodata',
              actions: sendToast(
                { type: 'info', id: 'load-alternatives' },
                'deliveryPage',
                'noAlternativesFound'
              ),
            },
          ],
        },
        nodata: {},
        idle: {
          on: {
            SELECT_OPTION: {
              actions: [
                assign((ctx, e) => ({
                  deliveryOption: e.data,
                  hasNewAlternatives: false,
                })),
                // sendToast(
                //   { type: 'success' },
                //   'deliveryPage',
                //   'alternativeSelected'
                // ),
              ],
              target: '#delivery.consignmentInfo',
            },
          },
        },
      },
    },

    consignmentInfo: {
      id: 'consignmentInfo',
      invoke: {
        src: 'getConsignmentInfo',
        id: 'getConsignmentInfo',
        onError: {
          target: 'error',
          // actions: assign( (ctx,e) => ({errorMessage: e.error}))
          actions: sendToast(
            { type: 'error', id: 'load-alternatives' },
            'deliveryPage',
            'errorLoadingAlternatives'
          ),
        },
        onDone: {
          target: 'selected',
          actions: [
            assign((ctx, e) => {
              const { cardcheckurl, partnercompid, reporturl, invoiceallowed } =
                e.data as ConsignmentInfo;
              return {
                partnerInfo: {
                  ...ctx.partnerInfo,
                  cardCheckUrl: cardcheckurl,
                  partnerCompId: partnercompid,
                  reportUrl: reporturl,
                },
                invoiceAllowed: invoiceallowed === '1' ? true : false,
                paymentType: invoiceallowed === '1' ? 'invoice' : 'card',
              };
            }),
            sendToast(
              { type: 'success' },
              'deliveryPage',
              'alternativeSelected'
            ),
            (ctx, e) => {
              if (window.dataLayer) {
                window.dataLayer.push({ bookingAdded: null });
                window.dataLayer.push({
                  event: 'deliveryAlternativeSelected',
                  bookingAdded: {
                    revenue: ctx.deliveryOption?.OriginalPriceExcludingVAT, // decimal separate is a full stop and must always be followed by 2 decimals.
                    currency: ctx.deliveryOption?.Currency,
                    vat: ctx.deliveryOption?.Vat, // decimal separate is a full stop and must always be followed by 2 decimals.
                    shipperCountry: ctx.shipper.country,
                    shipperCountryCode: ctx.shipper.countryCode,
                    recipientCountry: ctx.consignee.country,
                    recipientCountryCode: ctx.consignee.countryCode,
                    awbNo: '',
                    deliveryQuantity: ctx.packages.reduce(
                      (prev, curr, i, p) => prev + curr.quantity,
                      0
                    ), // if e.g. “one package” of 10 envelops, then this value is 10
                    bookingQuantity: ctx.packages.length, // if two package options are chosen and each of them has 5 in quantity, then this value is still 2
                    paymentMethod: e.data.invoiceallowed ? 'invoice' : 'card',
                    deliveryDetails: ctx.packages.map((x) => ({
                      // identical object per package line added and booked
                      packageType: x.parcelType?.name, // e.g. envelope if chosen from dropdown
                      packageQuantity: x.quantity, // e.g. envelope if chosen from dropdown
                      'packageWeight(kg)': x.weight, // e.g. envelope if chosen from dropdown
                      'packageHeight(cm)': x.height, // e.g. envelope if chosen from dropdown
                      'packageWidth(cm)': x.width, // e.g. envelope if chosen from dropdown
                      'packageLength(cm)': x.length, // e.g. envelope if chosen from dropdown
                    })),
                  },
                });
              }
            },
          ],
        },
      },
    },
    selected: {
      id: 'selected',
      on: {
        DELIVERY_DONE: [
          {
            cond: DeliveryGuards.deliveryStepComplete,
            target: '#packageInformation',
          },
          {
            target: [
              '#delivery.editing.date',
              '#delivery.editing.time',
              '#delivery.editing.requestType',
              '#delivery.editing.packages',
            ],
          },
        ],
        SELECT_OPTION: {
          actions: [
            assign((ctx, e) => ({
              deliveryOption: e.data,
              hasNewAlternatives: false,
            })),
          ],
          target: '#delivery.consignmentInfo',
        },
      },
    },
  },
  on: {
    ADDRESS_VERIFIED: {
      target: '#delivery.editing',
      actions: [
        assign({
          shipper: (ctx, { data }) => {
            if (
              ctx.shipper &&
              data.shipper &&
              ctx.shipper.street?.toLocaleLowerCase() ===
                data.shipper.street?.toLocaleLowerCase() &&
              ctx.shipper.streetNumber === data.shipper.streetNumber &&
              ctx.shipper.postCode === data.shipper.postCode
            ) {
              const { firstName, lastName, phone, name, addressName, id } =
                ctx.shipper;
              return {
                firstName,
                lastName,
                phone,
                name,
                id,
                addressName,
                ...data.shipper,
              };
            }
            return {
              ...data.shipper,
            };
          },
          consignee: (ctx, { data }) => {
            if (
              ctx.consignee &&
              data.consignee &&
              ctx.consignee.street?.toLocaleLowerCase() ===
                data.consignee.street?.toLocaleLowerCase() &&
              ctx.consignee.streetNumber === data.consignee.streetNumber &&
              ctx.consignee.postCode === data.consignee.postCode
            ) {
              const { firstName, lastName, phone, name, addressName, id } =
                ctx.consignee;
              return {
                firstName,
                lastName,
                phone,
                name,
                addressName,
                id,
                ...data.consignee,
              };
            }
            return {
              ...data.consignee,
            };
          },
          addressVerified: (ctx, { data }) => data.verified,
        }),
        'addressVerified',
      ],
    },
    SET_PARTNERINFO: {
      actions: assign({
        partnerInfo: (_, { data }) => data,
      }),
    },
    SELECT_DATE: [
      {
        actions: assign({
          date: (_, event) => event.data,
        }),
        target: '#delivery.editing.time.edit',
      },
    ],
    SELECT_TIME: [
      {
        target: '#delivery.editing.time.edit',
        actions: assign({
          time: (_, event) => event.data,
        }),
      },
    ],
    SELECT_DATE_START: [
      {
        target: '#delivery.editing.dateStart.edit',
        actions: assign({
          dateStart: (_, event) => event.data,
        }),
      },
    ],
    SELECT_DATE_END: [
      {
        target: '#delivery.editing.dateEnd.edit',
        actions: assign({
          dateEnd: (_, event) => event.data,
        }),
      },
    ],
    SELECT_TIME_START_OPEN: [
      {
        target: '#delivery.editing.timeStartOpen.edit',
        actions: assign({
          timeStartOpen: (_, event) => event.data,
        }),
      },
    ],
    SELECT_TIME_START_CLOSE: [
      {
        target: '#delivery.editing.timeStartClose.edit',
        actions: assign({
          timeStartClose: (_, event) => event.data,
        }),
      },
    ],
    SELECT_TIME_END_OPEN: [
      {
        target: '#delivery.editing.timeEndOpen.edit',
        actions: assign({
          timeEndOpen: (_, event) => event.data,
        }),
      },
    ],
    SELECT_TIME_END_CLOSE: [
      {
        target: '#delivery.editing.timeEndClose.edit',
        actions: assign({
          timeEndClose: (_, event) => event.data,
        }),
      },
    ],
    BLUR_TIME: [
      {
        cond: (ctx) =>
          new Date(`${ctx.date} ${ctx.time}`) < addMinutes(10)(new Date()),
        target: '#delivery.editing.time.edit',
        actions: assign({
          time: (ctx, event) => format('HH:mm')(addMinutes(15)(new Date())),
        }),
      },
    ],
    SELECT_REQUESTTYPE: [
      // {
      //   cond: (_, event) => event.data === RequestTypes.EarliestLatest,
      //   target: '#delivery.editing.requestMode.earliestLatest',
      //   actions: assign((_, event) => {
      //     const defaultDate = addMinutes(15)(new Date());
      //     return {
      //       requestType: event.data,
      //       time: undefined,
      //       date: undefined,
      //       dateStart: format('yyyy-MM-dd')(defaultDate),
      //       dateEnd: format('yyyy-MM-dd')(defaultDate),
      //       timeEndClose: '16:00',
      //       timeEndOpen: '08:00',
      //       timeStartClose: '16:00',
      //       timeStartOpen: '08:00',
      //     };
      //   }),
      // },
      // {
      //   target: '#delivery.editing.requestType.edit',
      //   actions: assign((_, event) => {
      //     const defaultDate = addMinutes(15)(new Date());
      //     const date = format('yyyy-MM-dd')(defaultDate);
      //     const time = format('HH:mm')(defaultDate);
      //     return {
      //       requestType: event.data,
      //       dateEnd: undefined,
      //       dateStart: undefined,
      //       timeEndClose: undefined,
      //       timeEndOpen: undefined,
      //       timeStartClose: undefined,
      //       timeStartOpen: undefined,
      //       time,
      //       date,
      //     };
      //   }),
      // },
      {
        target: '#delivery.editing.requestType.edit',
        actions: assign((_, event) => {
          const defaultDate = addMinutes(15)(new Date());
          const date = format('yyyy-MM-dd')(defaultDate);
          const time = format('HH:mm')(defaultDate);
          const reset = {
            requestType: event.data,
            time: undefined,
            date: undefined,
            dateEnd: undefined,
            dateStart: undefined,
            timeEndClose: undefined,
            timeEndOpen: undefined,
            timeStartClose: undefined,
            timeStartOpen: undefined,
          };
          switch (event.data) {
            case RequestTypes.EarliestPickup:
            case RequestTypes.LatestDelivery:
              return { ...reset, time, date };
            case RequestTypes.EarliestLatest:
              return {
                ...reset,
                dateStart: format('yyyy-MM-dd')(defaultDate),
                dateEnd: format('yyyy-MM-dd')(defaultDate),
                timeStartOpen: time,
              };
          }
          return reset;
        }),
      },
    ],
    CHANGE_PACKAGES: {
      target: '#delivery.editing.packages.edit',
      actions: assign((ctx, e) => ({
        packages: packageReducer(ctx.packages, e.data, ctx.packageTypes),
      })),
    },
    SET_LOGGED_IN: {
      target: '#delivery',
      actions: assign({
        packages: (_) => [],
      }),
    },
    CREATE_SUPPORT_TICKET: { target: '#delivery.supportTicket' },
  },
};

export const packageValid = (parcel: Parcel) =>
  !!parcel.parcelType &&
  !!parcel.weight &&
  parcel.weight > 0 &&
  parcel.weight <= parcel.parcelType.maxweight &&
  !!parcel.width &&
  parcel.width > 0 &&
  parcel.width <= parcel.parcelType.maxwidth &&
  !!parcel.height &&
  parcel.height > 0 &&
  parcel.height <= parcel.parcelType.maxheight &&
  !!parcel.length &&
  parcel.length > 0 &&
  parcel.length <= parcel.parcelType.maxlength &&
  parcel.quantity > 0;

export const deliveryMachineGuards: TGuards<DeliveryGuards> = {
  packagesInvalid: (ctx) =>
    !ctx.packages.reduce((valid: boolean, p) => valid && packageValid(p), true),
  // excessiveVolume: (ctx) => {
  //   let volume = ctx.packages.reduce(
  //     (vol, p) => vol + p.quantity * p.height * p.length * p.width,
  //     0
  //   );
  //   return volume / 5000 > 50;
  // },
  // excessiveWeight: (ctx) => {
  //   let weight = ctx.packages.reduce((w, p) => w + p.weight * p.quantity, 0);
  //   return weight >= 50;
  // },

  deliveryFormValid: (ctx, e, m) => {
    if (ctx.packages.length < 0 || ctx.packages.length > 100) return false;

    if (!validators.requestType(ctx.requestType)) return false;
    if (
      !ctx.addressVerified ||
      deliveryMachineGuards.packagesInvalid(ctx, e, m)
    )
      return false;

    switch (ctx.requestType) {
      case RequestTypes.EarliestPickup:
      case RequestTypes.LatestDelivery:
        return validators.date(ctx.date) && validators.time(ctx.time, ctx.date);
      case RequestTypes.EarliestLatest:
        return (
          validators.date(ctx.dateStart) &&
          validators.date(ctx.dateEnd) &&
          new Date(ctx.dateStart!) <= new Date(ctx.dateEnd!)
        );
    }
    return true;
  },

  dateEndInvalid: (ctx) => !validators.date(ctx.dateEnd),

  dateStartInvalid: (ctx) => !validators.date(ctx.dateStart),

  dateInvalid: (ctx) => !validators.date(ctx.date),

  timeInvalid: (ctx) =>
    (ctx.requestType === 'LatestDelivery' ||
      ctx.requestType === 'EarliestPickup') &&
    !validators.time(ctx.time, ctx.date),

  timeStartOpenInvalid: (ctx) =>
    ctx.requestType === RequestTypes.EarliestLatest &&
    !!ctx.timeStartOpen &&
    !validators.time(ctx.timeStartOpen, ctx.dateStart),

  timeStartCloseInvalid: (ctx) =>
    ctx.requestType === RequestTypes.EarliestLatest &&
    !!ctx.timeStartClose &&
    !validators.time(ctx.timeStartClose, ctx.dateStart),

  timeEndOpenInvalid: (ctx) =>
    ctx.requestType === RequestTypes.EarliestLatest &&
    !!ctx.timeEndOpen &&
    !validators.time(ctx.timeEndOpen, ctx.dateEnd),

  timeEndCloseInvalid: (ctx) =>
    ctx.requestType === RequestTypes.EarliestLatest &&
    !!ctx.timeEndClose &&
    !validators.time(ctx.timeEndClose, ctx.dateEnd),
  //  ||
  //   new Date(`${ctx.dateEnd} ${ctx.timeEndOpen}`) >
  //     new Date(`${ctx.dateEnd} ${ctx.timeEndClose}`)),

  requestTypeInvalid: (ctx) => !validators.requestType(ctx.requestType),

  deliveryStepComplete: (ctx) =>
    ctx.addressVerified && !!ctx.deliveryOption?.AlternativeId?.length,
};

export default deliveryMachine;
