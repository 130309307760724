import React, { PropsWithChildren, useState } from 'react';
import { TLanguageCode } from '../translations/TranslationType';
import { CookiesProvider } from 'react-cookie/lib';

export type appMode = 'web' | 'standalone';
export interface ConfigProps {
  appMode: appMode;
  apiBaseUrl: string;
  apiToken: string;
  language: TLanguageCode;
  basePath: string;
}

interface IConfigContext extends ConfigProps {
  setLanguage: (language: TLanguageCode) => void;
}

const configDefaults: IConfigContext = {
  appMode: 'standalone',
  apiBaseUrl: 'https://localhost:1234',
  language: 'sv-SE' as TLanguageCode,
  apiToken: '',
  basePath: '/',
  setLanguage: (language: string) => {},
};

const ConfigContext = React.createContext<IConfigContext>(configDefaults);

export const ConfigProvider = ({
  children,
  apiBaseUrl = configDefaults.apiBaseUrl,
  apiToken = configDefaults.apiToken,
  basePath = configDefaults.basePath,
  appMode = configDefaults.appMode,
  language = configDefaults.language,
}: PropsWithChildren<ConfigProps>) => {
  const [lang, setLanguage] = useState<TLanguageCode>(language);
  return (
    <CookiesProvider>
      <ConfigContext.Provider
        value={{
          appMode,
          apiToken,
          language: lang,
          setLanguage,
          apiBaseUrl,
          basePath,
        }}
      >
        {children}
      </ConfigContext.Provider>
    </CookiesProvider>
  );
};

export default ConfigContext;
