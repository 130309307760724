import { EventObject } from 'xstate';

// I have created a monster, based on typings from 'typesafe-actions'
// To be used to make action creators that can be mapped to xstate machine events
// as well as reducers.
// Bit hard to read, ask if anythings unclear /Linus
export type EventTypeConstant = string;

export interface EventAction<
  TType extends EventTypeConstant = EventTypeConstant
> extends EventObject {
  type: TType;
}

export interface EventDataAction<TType extends EventTypeConstant, TData>
  extends EventAction<TType> {
  data: TData;
  meta?: Record<any, any>;
}

export type EventCreator<TType extends EventTypeConstant> = (
  ...args: any[]
) => EventAction<TType>;

/**
 * @desc Type infering Action union type from an action-creator map
 */
export type EventActionType<TActionMap extends any> =
  // TActionMap extends EventCreator<EventTypeConstant>
  //? ReturnType<TActionMap> : // if the type is an event creator, use the return type
  TActionMap extends Record<any, any>
    ? {
        [K in keyof TActionMap]: ReturnType<TActionMap[K]>; // EventActionType<TActionMap[K]>; // type recursion! :O
      }[keyof TActionMap]
    : never;

export const createEvent = <T extends EventTypeConstant>(
  type: T
): EventAction<T> => ({
  type: type,
});

/**
 * Creates a typed action for this event type
 * @param type Event type
 * @param data Event data
 * @param meta optional event metadata, for possible use in guarded transitions and similar
 */
export const createDataEvent = <T extends EventTypeConstant, D>(
  type: T,
  data: D,
  meta?: Record<string, any>
): EventDataAction<T, D> => ({
  type,
  data,
  meta,
});

export const assertDataEvent = <T extends EventCreator<EventTypeConstant>>(
  eventCreator: T,
  event: EventAction
): event is ReturnType<T> => {
  const expectedType = eventCreator(null as unknown).type;
  if (event.type === expectedType) {
    return true;
  }
  throw new Error(
    `Unexpected event in assertion ${event.type} expected ${expectedType}`
  );
};

const bindEvent = <T extends EventCreator<EventTypeConstant>>(
  eventCreator: T,
  send: (event: EventActionType<any>) => void
) =>
  function (this: any, ...args: any[]) {
    return send(eventCreator.apply(this, args));
  };

export const bindEvents = <
  TEventMap extends Record<any, any>,
  K extends keyof TEventMap
>(
  events: TEventMap,
  send: (event: EventActionType<TEventMap>) => void
) =>
  Object.keys(events).reduce((acc, key) => {
    acc[key as K] = bindEvent(events[key], send) as TEventMap[K];
    return acc;
  }, {} as TEventMap);
