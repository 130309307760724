/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import useTranslation from '../../../hooks/useTranslation';
import { SendPackageContext } from '../../../Machines/sendPackageMachine';
import { IDate } from '../../../helpers/dateSelectGenerator';
import { H2 } from '../../../Components/Typograhy';
import {
  PickupAndDeliverySummary,
  ShippingDetails,
} from '../../../Components/Order/Order';

interface OrderSummaryProps {
  state: SendPackageContext;
  clock?: IDate;
}

const OrderSummary = ({ state, clock }: OrderSummaryProps) => {
  const translations = useTranslation();

  if (state.deliveryOption === undefined) return null;
  return (
    <div tw={'container mx-auto px-4 pt-10 pb-10'}>
      <div tw={'row'}>
        <div tw={'col-12 xl:col-10'}>
          <H2>{translations.orderSummary.title}</H2>
          <p tw={'max-w-lg mb-4'}>{translations.orderSummary.preamble} </p>
        </div>
        <div tw={'col-12 py-4 pl-8 sm:pl-12 mb-8'}>
          <PickupAndDeliverySummary state={state} />
        </div>
      </div>
      <ShippingDetails state={state} />
    </div>
  );
};

export default OrderSummary;
