const virtualPageview = (newPageURL: string, pageTitle: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'virtualPageview',
      newPageURL,
      pageTitle,
    });
  }
};
export default virtualPageview;
