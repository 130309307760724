/** @jsxImportSource @emotion/react */
import { Fragment, useContext } from 'react';
import ConfigContext from '../../context/ConfigContext';
import { getInsurancePrice } from '../../helpers/getInsurancePrice';
import { Dots } from '../../Components/Base/Dots';
import tw from 'twin.macro';
import { boxShadow } from '../../Components/Base/BoxShadow';
import { formatAddress } from '../../helpers/addressFormatter';
import { formatRelativeDate } from '../../helpers/dateSelectGenerator';
import { SendPackageContext } from '../../Machines/sendPackageMachine';
import useTranslation from '../../hooks/useTranslation';
import { Address } from '../../Models/Address';
import { IPrice } from '../../Models/IPrice';

const ContactInfoDisplay = ({ contact }: { contact: Address }) => (
  <ul>
    {contact.addressName ? <li>{contact.addressName}</li> : null}
    <li>{`${contact.firstName} ${contact.lastName}`}</li>
    <li>{contact.phone}</li>
    <li>{contact.message}</li>
  </ul>
);

interface PickupAndDeliverySummaryProps {
  state: SendPackageContext;
}
export const PickupAndDeliverySummary = ({
  state,
}: PickupAndDeliverySummaryProps) => {
  const translations = useTranslation();
  const { language } = useContext(ConfigContext);
  if (state.deliveryOption === undefined) return null;
  return (
    <Fragment>
      <Dots selected={true} />
      <div>
        <span tw={'block text-xl font-heading font-bold'}>
          {translations.pickUp}{' '}
          {formatRelativeDate(language, state.deliveryOption.PickupDateTime)}
        </span>
        <p tw={'mb-8'}>{formatAddress(state.shipper)}</p>
        <span tw={'block text-xl font-heading font-bold'}>
          {translations.delivery}{' '}
          {formatRelativeDate(language, state.deliveryOption.DeliveryDateTime)}
        </span>
        <p tw={'mb-0'}>{formatAddress(state.consignee)}</p>
      </div>
    </Fragment>
  );
};

interface ShippingDetailsProps {
  state: SendPackageContext;
}

export const ShippingDetails = ({ state }: ShippingDetailsProps) => {
  const { language } = useContext(ConfigContext);
  const translations = useTranslation();
  const insurancePrice = getInsurancePrice(
    state.insuranceValue,
    state.insurance
  );
  if (state.deliveryOption === undefined) return null;

  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: state.deliveryOption.Currency,
  });
  return (
    <div tw={'row'}>
      <div tw={'col-12 lg:col-8'}>
        <div css={[tw`border px-4 bg-white`, boxShadow]}>
          <div tw={'row'}>
            <div tw={'col-12 py-3 font-heading font-bold text-lg border-b'}>
              <h3 tw={'mb-0 text-xl'}>{translations.shippingDetails}</h3>
            </div>
            <div tw={'col-12 py-6'}>
              {state.packages.map((parcel) => (
                <div tw={'row pb-3 last:pb-0'} key={parcel.id}>
                  <span tw={'col-12 md:col-5 font-heading font-bold'}>
                    {parcel.parcelType?.translations?.find(
                      (x) => x.language === language
                    )?.translation ??
                      parcel.parcelType?.name ??
                      translations.package}
                  </span>
                  <span tw={'col-3 md:col-2 md:text-right'}>
                    {parcel.quantity} {translations.pcs}
                  </span>
                  <span tw={'col-3 md:col-2 md:text-right'}>
                    {parcel.weight} {translations.unitTypes.kg}
                  </span>
                  <span tw={'col-6 md:col-3 md:text-right'}>
                    {`${parcel.length} x ${parcel.width} x ${parcel.height} ${translations.unitTypes.cm}`}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div tw={'row border-t py-6'}>
            <div tw={'col-12 sm:col-6 mb-6 sm:mb-0'}>
              <h3 tw={'font-heading font-bold text-xl mb-3'}>
                {translations.shipper}
              </h3>
              {state.shipper && <ContactInfoDisplay contact={state.shipper} />}
            </div>
            <div tw={'col-12 sm:col-6'}>
              <h3 tw={'font-heading font-bold text-xl mb-3'}>
                {translations.consignee}
              </h3>
              {state.consignee && (
                <ContactInfoDisplay contact={state.consignee} />
              )}
            </div>
          </div>
          <div tw={'row border-t py-6'}>
            <div tw={'col-12'}>
              <h3 tw={'font-heading font-bold text-xl mb-3'}>
                {translations.total}
              </h3>
              <ul tw={'table table-auto w-full'}>
                {state.useInsurance && (
                  <Price
                    numberFormat={numberFormat}
                    price={insurancePrice}
                    titleNoVat={translations.insurance.title}
                    titleExVat={`${translations.insurance.title} ${translations.withoutVat}`}
                    titleIncVat={`${translations.insurance.title} ${translations.withVat}`}
                  />
                )}
                <li tw={'flex'}>
                  <span tw={'py-1 pr-6'}>
                    {`${translations.total} ${translations.withoutVat}`}
                  </span>
                  <span tw={'py-1 ml-auto'}>
                    {numberFormat.format(state.price?.priceexvat || 0)}
                  </span>
                </li>
                <li tw={'flex'}>
                  <span tw={'py-1 pr-6'}>
                    {`${translations.total} ${translations.withVat}`}
                  </span>
                  <span tw={'py-1 ml-auto font-bold'}>
                    {numberFormat.format(state.price?.priceincvat || 0)}
                  </span>
                </li>
                <li tw={'py-1 pr-6 text-gray-500 text-sm'}>
                  {translations.currency}: {state.deliveryOption.Currency}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PriceProps {
  titleNoVat: string;
  titleIncVat: string;
  titleExVat: string;
  price: IPrice;
  numberFormat: Intl.NumberFormat;
}
function Price({
  titleNoVat,
  titleIncVat,
  titleExVat,
  price,
  numberFormat,
}: PriceProps) {
  const { priceincvat, priceexvat } = price;
  if (priceexvat !== priceincvat) {
    return (
      <>
        <li tw={'flex'}>
          <span tw={'py-1 pr-6'}>{titleExVat}</span>
          <span tw={'py-1 ml-auto'}>{numberFormat.format(priceexvat)}</span>
        </li>
        <li tw={'flex'}>
          <span tw={'py-1 pr-6'}>{titleIncVat}</span>
          <span tw={'py-1 ml-auto'}>{numberFormat.format(priceincvat)}</span>
        </li>
      </>
    );
  }
  return (
    <li tw={'flex'}>
      <span tw={'py-1 pr-6'}>{titleNoVat}</span>
      <span tw={'py-1 ml-auto'}>{numberFormat.format(priceincvat)}</span>
    </li>
  );
}
