/** @jsxImportSource @emotion/react */
import { FormInput, PhoneField } from '../../../Components/FormFields';
import { Fragment } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import { PaymentPageContext } from '../paymentPageMachine';
import { PaymentEvents } from '../PaymentEvents';
import { FormGroup } from '../../../Components/Base';
import useTranslation from '../../../hooks/useTranslation';
import { StateValue } from 'xstate';
import { useCountries } from '../../../hooks/useCountries';

interface BillingInformationProps {
  state: PaymentPageContext;
  events: typeof PaymentEvents;
  matches: (key: StateValue) => boolean;
}
export const BillingInformation = ({
  state,
  events,
  matches,
}: BillingInformationProps) => {
  const translations = useTranslation();
  const { countries } = useCountries();
  return (
    <Fragment>
      <div tw={'row'}>
        <div tw={'col-12 md:col-8 lg:col-6'}>
          <FormGroup>
            <FormInput
              label={translations.email}
              value={state.billingEmail}
              name="cc-email"
              autoComplete="email"
              onChange={events.changeEmail}
              error={matches('editing.email.error')}
              errorMessage={translations.invalidEmailAddress}
              required={true}
              maxLength={99}
            />
          </FormGroup>
        </div>
      </div>
      <div tw={'row'}>
        <div tw={'col-12 md:col-8 lg:col-6'}>
          <FormGroup>
            <PhoneField
              country={state.shipper?.countryCode?.toLocaleLowerCase() ?? 'se'}
              onlyCountries={countries.map((x) =>
                x.isocode.toLocaleLowerCase()
              )}
              label={translations.phone}
              value={state.billingPhone}
              name="billingPhone"
              onChange={events.changePhone}
              error={matches('editing.billingPhone.error')}
              errorMessage={translations.invalidEmailAddress}
              required={true}
            />
          </FormGroup>
        </div>
      </div>
    </Fragment>
  );
};
