/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import tw from 'twin.macro';
import { FormInput, FormRadioButton } from '../../../Components/FormFields';
import { Button, FormGroup } from '../../../Components/Base';
import useTranslation from '../../../hooks/useTranslation';
import { IRegisterContext } from '../RegisterMachine';
import { RegisterEvents, TRegisterEvents } from '../RegisterEvents';
import { useBoundEvents } from '../../../hooks/useBoundEvents';
import { StateValue } from 'xstate';
import { RegisterGuards } from '../RegisterMachine';

interface IGetRegistrationProps {
  state: IRegisterContext;
  send: (event: TRegisterEvents) => void;
  matches: (key: StateValue) => boolean;
}
const GetRegistration = ({ state, send, matches }: IGetRegistrationProps) => {
  const events = useBoundEvents(RegisterEvents, send);
  const translation = useTranslation();

  const getError = (error: any) => {
    if (error === 'This account nbr is not valid in this partnercompany')
      return translation.registerPage.invalidNbr;
    else if (error === 'This account nbr does not exist in Jena')
      return translation.registerPage.nonExistantNbr;
    else return error;
  };
  return (
    <Fragment>
      <FormGroup>
        <FormRadioButton
          id={'company'}
          name="customerType"
          text={translation.registerPage.fields.customerTypeCompany}
          checked={state.customerType === 'company'}
          onChange={(checked: boolean) =>
            events.changeCustomerType(checked ? 'company' : 'private')
          }
        />
      </FormGroup>
      <FormGroup>
        <FormRadioButton
          id={'private'}
          name="customerType"
          text={translation.registerPage.fields.customerTypePrivate}
          checked={state.customerType === 'private'}
          onChange={(checked: boolean) =>
            events.changeCustomerType(checked ? 'private' : 'company')
          }
        />
      </FormGroup>

      {state.customerType === 'company' && (
        <div tw={'mt-8'}>
          <p tw={'mb-4'}>{translation.registerPage.existingCustomerHeader}</p>
          <FormGroup>
            <FormRadioButton
              id={'existingYes'}
              name="exisiting"
              text={translation.registerPage.fields.existingYes}
              checked={state.existing === true}
              onChange={(checked: boolean) => events.changeIsExsting(checked)}
            />
          </FormGroup>
          <FormGroup>
            <FormRadioButton
              id={'existingNo'}
              name="exisiting"
              text={translation.registerPage.fields.existingNo}
              checked={state.existing === false}
              onChange={(checked: boolean) => events.changeIsExsting(!checked)}
            />
          </FormGroup>
        </div>
      )}
      <FormGroup>
        {state.customerType === 'company' && state.existing === false && (
          <FormInput
            name="orgNumber"
            label={translation.registerPage.fields.orgNumber}
            value={state.orgNumber}
            type="Text"
            onChange={events.changeOrgNumber}
            autoComplete="orgNumber"
            error={matches('step1.editing.orgNumber.error')}
            errorMessage={translation.requiredField}
          />
        )}
        {state.customerType && state.existing === true && (
          <FormInput
            name="customerNumber"
            label={translation.registerPage.fields.customerNumber}
            value={state.customerNumber}
            type="Text"
            onChange={events.changeCustomerNumber}
            autoComplete="customerNumber"
            error={matches('step1.editing.customerNumber.error')}
            errorMessage={translation.requiredField}
          />
        )}
      </FormGroup>
      {state.error && <p tw={'text-red'}>{getError(state.error)}</p>}
      <Button
        onClick={(e) => {
          e.preventDefault();
          events.continue();
        }}
        variant="secondary"
        // @ts-ignore
        disabled={!RegisterGuards.validStep1(state)}
        options={tw`ml-auto`}
      >
        {(matches('step1.editing') || matches('step1.failed')) &&
          translation.registerPage.continueButtonText}
        {matches('getRegistration') && translation.loading}
      </Button>
    </Fragment>
  );
};

export default GetRegistration;
