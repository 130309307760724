/** @jsxImportSource @emotion/react */
import { IconCheckmark, IconClose } from '../../../svg';
import tw from 'twin.macro';

export interface HowToWriteProps {
  labels: {
    howToWrite: string;
    howNotToWrite: string;
  };
  descriptions: Description[];
}
export interface Description {
  correct: string;
  incorrect: string;
}

export const HowToWrite = ({ labels, descriptions }: HowToWriteProps) => {
  return (
    <table tw="table w-full bg-white">
      <thead>
        <tr tw="font-heading text-left">
          <th tw="p-3 sm:px-4 border ">{labels.howNotToWrite}</th>
          <th tw="p-3 sm:px-4 border">{labels.howToWrite}</th>
        </tr>
      </thead>
      <tbody>
        {descriptions.map((description, id) => (
          <tr key={id}>
            <td tw="p-3 sm:px-4  border">
              <span tw={'flex flex-row items-center leading-tight'}>
                <IconClose
                  css={[
                    tw`text-red fill-current w-3 h-3 mr-2`,
                    { flex: '0 0 .75rem' },
                  ]}
                />{' '}
                {description.incorrect}
              </span>
            </td>
            <td tw="p-3 sm:px-4 border">
              <span tw={'flex flex-row items-center leading-tight'}>
                <IconCheckmark
                  css={[
                    tw`text-green-900 fill-current w-3 h-3 mr-2`,
                    { flex: '0 0 .75rem' },
                  ]}
                />{' '}
                {description.correct}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
