import { v4 as uuidv4 } from 'uuid';
import { Packagetype } from './jena/request/ListPackageTypesResponse';

export interface ParcelDimensions {
  weight?: number;
  width?: number;
  height?: number;
  length?: number;
}

export interface Parcel extends ParcelDimensions {
  id: string;
  quantity: number;
  parcelType?: Packagetype;
}

export const defaultParcel: Parcel = {
  id: uuidv4(),
  quantity: 1,
  // parcelType: ParcelTypes.envelope,
  weight: 1,
  height: 1,
  length: 1,
  width: 1,
};

export const makeDefaultParcel = (parcelType: Packagetype): Parcel => ({
  id: uuidv4(),
  parcelType,
  height: parcelType.defaultheight,
  length: parcelType.defaultlength,
  quantity: 1,
  weight: parcelType.defaultweight,
  width: parcelType.defaultwidth,
});
