import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../helpers/eventCreator';
import { Packagetype } from '../Models/jena/request/ListPackageTypesResponse';
import { DeliveryContext } from '../Pages/Delivery/deliveryMachine';

interface SetUserLoggedInData {
  isLoggedIn: boolean;
  email?: string;
  phone?: string;
  id?: string;
}

export const SendPackageEvents = {
  updateDelivery: (action: Partial<DeliveryContext>) =>
    createDataEvent('UPDATE_DELIVERY', action),

  doneDelivery: (action: DeliveryContext) =>
    createDataEvent('DONE_DELIVERY', action),

  updatePartnerComp: (partnerCompId: string) =>
    createDataEvent('UPDATE_PARTNERCOMP', partnerCompId),

  delivery: () => createEvent('DELIVERY'),
  package: () => createEvent('PACKAGE'),
  payment: () => createEvent('PAYMENT'),
  address: () => createEvent('ADDRESS'),
  customs: () => createEvent('CUSTOMS'),

  setUserLoggedIn: (data: SetUserLoggedInData) =>
    createDataEvent('SET_USER_LOGGEDIN', data),

  reset: () => createEvent('RESET_FORM'),

  setPackageTypes: (packageTypes: Packagetype[]) =>
    createDataEvent('SET_PACKAGE_TYPES', packageTypes),

  changeUploadFiles: (files: FileList | null) =>
    createDataEvent('CHANGE_CUSTOMS_FILES', files),

  removeFile: (id: string) => createDataEvent('REMOVE_CUSTOMS_FILE', id),
  refresh: () => createEvent('REFRESH'),
};

export type TSendPackageEvents = EventActionType<typeof SendPackageEvents>;
