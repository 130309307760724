/** @jsxImportSource @emotion/react */
import { Parcel } from '../../../Models/Parcel';
import { Translations } from '../../../translations/TranslationType';
import useTranslation from '../../../hooks/useTranslation';
import tw, { css } from 'twin.macro';
import {
  FormLabel,
  FormSelect,
  StatefulFormInput,
} from '../../../Components/FormFields';
import { IconCheckmark, IconTrash } from '../../../svg';
import { Transition } from 'react-transition-group';
import { ENTERED, EXITING } from 'react-transition-group/Transition';
import { useContext, useEffect, useRef, useState } from 'react';
import { Packagetype } from '../../../Models/jena/request/ListPackageTypesResponse';
import ConfigContext from '../../../context/ConfigContext';
import getParcelDefaultDimensions from '../../../helpers/getParcelDefaultDimensions';

interface IPackageInputProps extends Parcel {
  handleChange: (
    value: Partial<Parcel> & { id: string },
    source: keyof Parcel
  ) => any;
  handleRemove: (id: string) => any;
  autofocus: boolean;
  in?: boolean;
  error?: boolean;
  packageTypes: Packagetype[];
}

export const Package = ({
  id,
  error,
  quantity,
  weight,
  width,
  height,
  length,
  handleChange,
  handleRemove,
  parcelType,
  autofocus,
  in: inProp,
  packageTypes,
}: IPackageInputProps) => {
  const translation: Translations = useTranslation();
  const { language } = useContext(ConfigContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autofocus)
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [autofocus]);

  const [defaultParcelDimensions, setDefaultParcelDimensions] = useState({
    length,
    width,
    height,
    weight,
  });

  return (
    <Transition
      in={inProp}
      timeout={{ enter: 0, exit: 300 }}
      unmountOnExit={true}
      nodeRef={ref}
    >
      {(state) => (
        <div
          ref={ref}
          key={id}
          css={[
            tw`w-full border border-t-0 bg-white px-6 pt-6 pb-8 sm:(px-8) md:(pl-6 pr-12 pb-10) transform opacity-50`,
            css`
              transition: transform 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76),
                opacity 0.5s ease-in-out;
            `,
            state === EXITING &&
              css`
                transform: translateX(1000px);
                opacity: 0.1;
              `,
            state === ENTERED && tw`opacity-100`,
          ]}
        >
          <div tw={'flex flex-row flex-wrap -mx-2'}>
            <div
              tw={
                'w-full sm:w-6/12 lg:w-2/12 lg:flex-auto lg:mr-auto px-1 my-2 lg:my-0'
              }
            >
              <FormLabel name={`${id}-package`}>
                {translation.whatIsTheSizeOfYourPackage}
              </FormLabel>
              <FormSelect
                name={`${id}-package`}
                value={parcelType?.packagetypeid ?? ''}
                // defaultValue={parcelType}
                autoFocus={autofocus}
                options={packageTypes.map((pt) => {
                  return {
                    value: pt.packagetypeid,
                    label:
                      pt.translations?.find((x) => x.language === language)
                        ?.translation ?? pt.name,
                  };
                })}
                onChange={(value) => {
                  const packageType = packageTypes.find(
                    (x) => x.packagetypeid === value
                  );
                  if (!packageType) {
                    console.error('Invalid package type', value);
                    return;
                  }
                  handleChange(
                    {
                      id: id || '',
                      parcelType: packageType,
                      ...getParcelDefaultDimensions(packageType),
                    },
                    'parcelType'
                  );
                  setDefaultParcelDimensions(
                    getParcelDefaultDimensions(packageType)
                  );
                }}
              />
            </div>
            <div tw="lg:hidden w-full"></div>
            <div tw={'w-6/12  sm:w-3/12 lg:w-2/12 px-1 my-2 lg:my-0'}>
              <StatefulFormInput
                name={`${id}-quantity`}
                value={quantity}
                defaultValue={quantity}
                fixedValue={1}
                onBlur={(e) => {
                  handleChange(
                    {
                      id: id || '',
                      quantity: parseInt(e.target.value, 10),
                    },
                    'quantity'
                  );
                }}
                suffix={translation.pcs}
                label={translation.quantity}
                suffixDescription={translation.quantityPcs}
                type="number"
                // min={1}
                error={!quantity || quantity < 1}
              />
            </div>
            <div tw={'w-6/12  sm:w-3/12 lg:w-2/12 px-1 my-2 lg:my-0'}>
              <StatefulFormInput
                name={`${id}-weight`}
                value={weight || ''}
                onBlur={(e) => {
                  handleChange(
                    {
                      id: id || '',
                      weight: parseFloat(e.target.value.replace(',', '.')),
                      quantity,
                      width,
                      height,
                      length,
                      parcelType,
                    },
                    'weight'
                  );
                }}
                defaultValue={weight || ''}
                suffix={translation.unitTypes.kg}
                label={translation.weight}
                suffixDescription={translation.inKilogram}
                type="decimal"
                error={
                  !weight ||
                  weight <= 0 ||
                  !parcelType?.maxweight ||
                  weight > parcelType.maxweight
                }
              />
            </div>
            <div tw={'flex w-full sm:w-6/12 lg:w-4/12 px-1 my-2 lg:my-0'}>
              <div css={tw`flex-grow`}>
                <StatefulFormInput
                  value={length || ''}
                  defaultValue={defaultParcelDimensions.length || ''}
                  name={`${id}-length`}
                  onChange={(value) => {
                    handleChange(
                      { id: id || '', length: parseInt(value, 10) },
                      'length'
                    );
                  }}
                  suffixDescription={translation.inCm}
                  label={translation.length}
                  inputGroup="first"
                  type="number"
                  // min={1}
                  error={
                    !length ||
                    length < 1 ||
                    (!!parcelType?.maxlength && length > parcelType.maxlength)
                  }
                />
              </div>
              <div css={tw`flex-grow`}>
                <StatefulFormInput
                  value={width || ''}
                  defaultValue={defaultParcelDimensions.width || ''}
                  name={`${id}-width`}
                  onChange={(value) => {
                    handleChange(
                      { id: id || '', width: parseInt(value, 10) },
                      'width'
                    );
                  }}
                  label={translation.width}
                  suffixDescription={translation.inCm}
                  inputGroup="middle"
                  type="number"
                  // min={1}
                  error={
                    !width ||
                    width < 1 ||
                    (!!parcelType?.maxwidth && width > parcelType.maxwidth)
                  }
                />
              </div>
              <div
                css={[
                  tw`flex-grow`,
                  css`
                    min-width: 8rem;
                  `,
                ]}
              >
                <StatefulFormInput
                  value={height || ''}
                  defaultValue={defaultParcelDimensions.height || ''}
                  name={`${id}-height`}
                  onChange={(value) => {
                    handleChange(
                      { id: id || '', height: parseInt(value, 10) },
                      'height'
                    );
                  }}
                  label={translation.height}
                  suffix={translation.unitTypes.cm}
                  suffixDescription={translation.inCm}
                  inputGroup="last"
                  type="number"
                  // min={1}
                  error={
                    !height ||
                    height < 1 ||
                    (!!parcelType?.maxheight && height > parcelType.maxheight)
                  }
                />
              </div>
            </div>
            <div tw={'hidden lg:flex flex-none px-1 mt-2 lg:order-first'}>
              <div
                tw={
                  'mt-auto inline-flex items-center px-3 py-3 ml-auto rounded-sm'
                }
              >
                <IconCheckmark
                  css={[
                    tw`w-5 h-5 text-green-800 fill-current`,
                    error && tw`text-gray-300`,
                  ]}
                />
              </div>
            </div>

            <div tw={'flex flex-none px-1 mt-2 ml-auto lg:ml-1'}>
              <button
                title={translation.removePackage}
                className="group"
                tw={
                  'mt-auto inline-flex items-center px-3 py-3 ml-auto text-sm text-darkred hover:(bg-gray-100) rounded-sm'
                }
                onClick={() => {
                  handleRemove(id || '');
                }}
              >
                <span tw="mr-2 md:sr-only">{translation.remove}</span>
                <IconTrash tw="w-5 h-5 stroke-2 stroke-current" />
              </button>
            </div>
            {/* {error && (
              <FormMessage
                label="[ADD TRANSLATION] invalid package"
                // status="error"
                // tw="pl-16 mt-1 text-base block flex-grow w-full"
              />
            )} */}
          </div>
        </div>
      )}
    </Transition>
  );
};
