import { createDataEvent, EventActionType } from '../helpers/eventCreator';
import { Address } from '../Models/Address';

export const ContactActions = {
  changeFirstName: (name: string) => createDataEvent('CHANGE_FIRST_NAME', name),
  changeLastName: (name: string) => createDataEvent('CHANGE_LAST_NAME', name),
  changeCompany: (company: string) =>
    createDataEvent('CHANGE_COMPANY', company),
  changePhone: (phone: string) => createDataEvent('CHANGE_PHONE', phone),
  changeMessage: (message: string) =>
    createDataEvent('CHANGE_MESSAGE', message),
};
export type ContactAction = EventActionType<typeof ContactActions>;

const contactReducer = (state: Address, action: ContactAction): Address => {
  switch (action.type) {
    case 'CHANGE_COMPANY':
      return { ...state, addressName: action.data };
    case 'CHANGE_PHONE':
      return { ...state, phone: action.data };
    case 'CHANGE_MESSAGE':
      return { ...state, message: action.data };
    case 'CHANGE_FIRST_NAME':
      return { ...state, firstName: action.data };
    case 'CHANGE_LAST_NAME': {
      return { ...state, lastName: action.data };
    }
  }
  return state;
};

export default contactReducer;
