import { useContext, useMemo } from 'react';
import ConfigContext from '../context/ConfigContext';
import netsApi from '../api/netsApi';

const useNetsApi = () => {
  const { apiBaseUrl } = useContext(ConfigContext);
  const api = useMemo(
    () =>
      netsApi({
        baseUrl: apiBaseUrl,
      }),
    [apiBaseUrl]
  );
  return {
    getToken: api.getToken,
    startEmbedded: api.startEmbedded,
  };
};

export default useNetsApi;
