/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import FormLabel from './Label';
import FormMessage from '../Base/FormMessage';

interface ISimpleInputProps {
  name: string;
  value: string | number | undefined;
  /**
   * Default value
   */
  defaultValue?: string | number | undefined;
  /**
   * Revert to this value onBlur
   */
  fixedValue?: string | number | undefined;
  label?: string;
  type?: 'Text' | 'decimal' | 'number' | 'tel' | 'password' | 'email';
  placeholder?: string;
  onChange?: (value: string) => void;
  suffix?: string;
  suffixDescription?: string;
  inputGroup?: 'first' | 'middle' | 'last';
  error?: boolean;
  errorMessage?: string;
  success?: boolean;
  min?: number;
  max?: number;
  step?: string;
  autoComplete?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
}

const FormInput = ({
  name,
  label,
  value,
  defaultValue,
  fixedValue,
  type,
  placeholder,
  onChange,
  suffix,
  suffixDescription,
  inputGroup,
  error,
  success,
  errorMessage,
  autoComplete,
  required,
  disabled,
  onBlur,
  onFocus,
  maxLength,
  ...rest
}: ISimpleInputProps) => {
  let group = tw``;
  switch (inputGroup) {
    case 'first':
      group = tw`border-r-0 rounded-r-none`;
      break;
    case 'middle':
      group = tw`border-r-0 rounded-none`;
      break;
    case 'last':
      group = tw`rounded-l-none`;
      break;
  }

  const [_value, _setValue] = useState(value?.toString() || '');

  useEffect(() => {
    if (defaultValue !== undefined) _setValue(defaultValue?.toString() || '');
  }, [defaultValue]);

  return (
    <Fragment>
      {label && (
        <FormLabel name={name} required={required}>
          {label}
          {suffixDescription && (
            <span css={tw`sr-only`}>{suffixDescription}</span>
          )}
        </FormLabel>
      )}
      <div css={[tw`flex border-gray-500 border-0 rounded-none`, group]}>
        <div tw={'w-full'}>
          <input
            type={type === 'decimal' || type === 'number' ? 'text' : type}
            id={name}
            value={_value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={(e) => {
              if (
                (type === 'decimal' &&
                  !e.target.value.match(/^\d*[,\\.]?\d{0,2}$/)) ||
                (type === 'number' && !e.target.value.match(/^\d*$/))
              )
                return;

              _setValue(e.target.value);
              onChange && onChange(e.target.value);
            }}
            aria-invalid={error}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={(e) => {
              onBlur && onBlur(e);
              if (fixedValue) _setValue(fixedValue.toString());
            }}
            css={[
              css`
                -webkit-appearance: none;
              `,
              suffix || inputGroup ? tw`text-center` : tw`text-left`,
              tw`max-w-full w-full px-2 rounded border border-gray-400 bg-white text-base leading-10`,
              inputGroup === 'first' && tw`rounded-r-none `,
              inputGroup === 'middle' && tw`rounded-none border-l-0 border-r-0`,
              inputGroup === 'last' && tw`rounded-none border-r-0`,
              suffix && tw`rounded-r-none`,
              success && tw`border-green-500! border!`,
              error && tw`border-red! border!`,
              error &&
                css`
                  transition: all 250ms ease 0s;
                  &:focus {
                    border-color: rgb(215 53 44 / 82%) !important;
                    box-shadow: 0 1px 1px rgb(215 53 44 / 14%) inset,
                      0 0 8px rgb(215 53 44 / 39%) !important;
                    outline: 0 none !important;
                  }
                `,
              type === 'number' && tw`pr-0`,
              disabled && tw`bg-gray-300 cursor-not-allowed`,
            ]}
            required={required}
            maxLength={maxLength}
            {...rest}
          />
        </div>
        {suffix && (
          <span
            aria-hidden="true"
            title={suffixDescription}
            css={[
              tw`border border-gray-400 rounded-r bg-gray-200 align-middle px-5 py-1 text-base leading-loose select-none`,
              !inputGroup &&
                tw`rounded-r border-t border-r border-b border-l-0`,
            ]}
          >
            {suffix}
          </span>
        )}
      </div>
      {error && errorMessage && (
        <FormMessage label={errorMessage} status={'error'} />
      )}
    </Fragment>
  );
};

export default FormInput;
