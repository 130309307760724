/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import SetNewPassword from '../../Components/ResetPassword/SetNewPassword';
import StartResetPassword from '../../Components/ResetPassword/StartResetPassword';

interface IParams {
  token?: string;
}

const ResetPasswordPage = () => {
  const { token } = useParams<IParams>();
  return (
    <Fragment>
      {token ? <SetNewPassword token={token} /> : <StartResetPassword />}
    </Fragment>
  );
};

export default ResetPasswordPage;
