/** @jsxImportSource @emotion/react */
import { Address } from '../../Models/Address';
import tw from 'twin.macro';
import { Fragment } from 'react';
import useTranslation from '../../hooks/useTranslation';
import addressBookSaveMachine, {
  ISaveAddressContext,
} from './SaveAddressMachine';
import { useMachine } from '@xstate/react';
import useJenaApi from '../../hooks/useJenaApi';
import { AddAddressForm } from './AddAddressForm';
import { addressContextToSaveAddressRequest } from '../../mappers/jenaMappers';
import { useUser } from '../../hooks/useUser';
import { AddressSaved } from './AddressSaved';

interface SaveAddressProps {
  onAdd?: (address: Address) => void;
  address?: Address;
}
export const SaveAddress = ({ onAdd, address }: SaveAddressProps) => {
  const translation = useTranslation();
  const { user, reload } = useUser();
  const api = useJenaApi();

  const [state, send] = useMachine(addressBookSaveMachine, {
    // default to user's country when adding a new address
    context: address ? { ...address } : { countryId: user?.countryId },
    services: {
      save: (context: ISaveAddressContext) =>
        api.saveAddress(
          addressContextToSaveAddressRequest(context, user?.customerNumber)
        ),
    },
    actions: {
      onSaved: reload,
    },
  });

  return (
    <Fragment>
      <h2 css={[tw`text-2xl font-heading font-semibold mb-2`]}>
        {address?.id
          ? translation.addressBookModal.editAddress
          : translation.addressBookModal.newAddress}
      </h2>
      {state.matches('step1') && (
        <AddAddressForm
          state={state.context}
          send={send}
          matches={state.matches}
        />
      )}
      {state.matches('saved') && (
        <AddressSaved
          state={state.context}
          send={send}
          matches={state.matches}
        />
      )}
    </Fragment>
  );
};
